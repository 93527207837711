<section style="position: absolute;width: 100%;z-index: 1;" class="image-container line-spacing"
  style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
  <div class="container" style="padding: 0;">
    <div class="board">
      <button type="button" style="z-index:100;float: right;" class="btn btn-dark" (click)="hide();"
        [attr.aria-label]="'texto_735' | translate">X</button>

      <!--Breadcrumbs-->
      <div class="breadcrum-div">
        <nav aria-label="breadcrumb" class="nav">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/inicio" [innerHTML]="'texto_1' | translate | safe:'html'"></a></li>
            <li class="breadcrumb-item active" aria-current="page" [innerHTML]="'texto_717' | translate | safe:'html'">
            </li>
          </ol>
        </nav>
      </div>
      <!--End Breadcrumbs-->
      <!-- Navigation Area (Circular Tabs) -->
      <msw-navbar></msw-navbar>
      <!-- End Navigation Area (Circular Tabs) -->

      <!-- Content Area -->
      <div class="tab-content">
        <form #archivoForm="ngForm" class="editForm" novalidate>
          <div class="tab-pane fade in active">
            <h2 class="head text-center">{{ title | translate }}</h2>
            <div class='row'>
              <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                <!-- Content to Add files -->
                <div class="row" style="margin-top: -30px;">
                  <div class="col-md-12">
                    <form [formGroup]="myForm" style="width:100%;display:contents;color:white">
                      <!--upload file-->
                      <div class="uploadfilecontainer" tabindex="0" role="button" aria-label="Subir archivo"
                        (click)="fileInput.click()" (keydown.enter)="fileInput.click()"
                        (keydown.space)="fileInput.click()" appDragDrop (onFileDropped)="dragFile($event)">
                        <input formControlName="file"
                          accept=".eml,.msg,.zip,.pptx,.pptx,.pptm,.potx,.potm,.ppam,.ppsx,.ppsm,.xlsx,.xlsm,.xltx,.xltm,.xlam,image/gif,image/jpeg,image/jpg,image/png,video/*,audio/*,.pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          id="file" hidden type="file" #fileInput (change)="uploadFile($event)">
                      </div>
                      <label style="color:black; font-size: 0.75rem" *ngIf="files.length > 0"> {{
                        'texto_720' | translate }}:
                        {{ files[files.length - 1] }} </label>
                      <!--End upload file-->
                    </form>
                  </div>
                  <div class='col-xs-12 col-sm-12'>
                    <!--Textarea type description -->
                    <label class="control-label" for="descripcion">{{ 'texto_95' | translate}}</label>
                    <textarea maxlength="4000" class="form-control input-md" style="border-radius: 3px !important;"
                      [formControl]="descripcion" id="descripcion" name="descripcion" type="text"
                      placeholder="{{ 'texto_95' | translate }}" rows="4" (keyup)="onKey($event)"></textarea>
                    <span style="float: left; font-weight: bold; font-size: 0.75rem;">
                      {{ charCount }} {{ 'texto_69' | translate }} 4000
                    </span>
                    <!--End textarea type description -->
                    <div class="form-group text-center" style="margin-top: 10px; margin-bottom: 20px;">
                      <!--Button to submit a file and description-->
                      <button (click)="submit()" [disabled]="files.length == 0" mdbBtn rounded="true" class="btn-back"
                        mdbWavesEffect>{{ 'texto_719' |
                        translate }}
                      </button>
                    </div>
                  </div>
                  <!--Table to display selected files-->
                  <div class="col-md-12" *ngIf="archivosList.length > 0">
                    <label>{{ 'texto_721' | translate }}:</label>
                    <div class="table-responsive table-files">
                      <table class="table">
                        <thead class="thead">
                          <th>{{ 'texto_90' | translate }}</th>
                          <th>{{ 'texto_457' | translate }}</th>
                          <th>{{ 'texto_482' | translate }}</th>
                          <th></th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let archivo of archivosList; let i = index">
                            <td>{{ archivo.nombreArchivo.name }}</td>
                            <td>{{ archivo.descripcionArchivo }}</td>
                            <td>{{ (archivo.nombreArchivo.size / 1000000).toFixed(2) }}MB
                            </td>
                            <td class="td-actions" style="width:40px;">
                              <button class="delete-file" aria-label="Eliminar archivo"
                                (click)="deleteItemArchivosList(i)">
                                <i class="fa fa-trash pull-right text-danger"></i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td class="totalSize">{{ 'texto_570' | translate }}:{{
                              sizeListMb.toFixed(2) }}MB</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!--End table to display selected files-->
                </div>
                <!--Text 733-->
                <div style="font-size: 0.75rem; line-height: 1.5;border: 0px solid black;text-align: justify;">
                  <p [innerHTML]="'texto_733' | translate | safe:'html'">
                </div>
                <!--End Text 733-->
                <!--Buttons goToPrevious and goToNext-->
                <div class="form-group text-center" style="margin-top: 30px;">
                  <div
                    style="font-size: 0.75rem;line-height: 1.5;border: 0px solid black;padding-top: 10px; padding-top: 10px; text-align: justify;">
                    <p [innerHTML]="'texto_759' | translate | safe:'html'"></p>
                  </div>
                  <button (click)="goToPrevious(archivoForm)" mdbBtn rounded="true" class="btn-back" mdbWavesEffect>
                    <span style="margin-right:10px;" class="glyphicon glyphicon-arrow-left">
                    </span>{{ 'texto_142' | translate }}
                  </button>

                  <button [disabled]="!archivoForm.valid" (click)="goToNext(archivoForm)" mdbBtn rounded="true"
                    class="btn-next" mdbWavesEffect>
                    {{ 'texto_141' | translate }} <span style="margin-left:10px;"
                      class="glyphicon glyphicon-arrow-right">
                    </span>
                  </button>
                </div>
                <!--End buttons goToPrevious and goToNext-->
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</section>