<button style="background-image: url(/../../assets/img/botones/Boton_Subir\ Archivo.png);"
  class="btn btn-finish btn-wd btn-cabecera" matTooltip="{{'Subir fichero' | translate }}" type="button" mdbBtn
  rounded="true" data-toggle="modal" data-target="#basicExample" (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Content-->
    <div class="modal-content">

      <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
        <div class="card-header card-header-info">
          <h5 style="margin-bottom: 0px;" class="card-title">{{ 'Añadir documento' | translate }}</h5>
        </div>
        <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark"
          data-dismiss="modal" (click)="frame.hide()" aria-label="Cerrar modal">X</button>

        <div class="md-form">
          <!-- <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon> -->
          <label class="control-label" for="textoMCU">{{ 'Puedes añadir más comentarios al informe subido' |
            translate }}</label><br>
          <ngx-wig id="addDocument" [formControl]="myForm.get('contactFormModalMessage')"
            [formControl]="contactFormModalMessage" [buttons]="'bold, italic, link, underline'"
            (ngModelChange)="onEditorChange($event)"
            [attr.aria-invalid]="myForm.get('contactFormModalMessage').invalid ? 'true' : null"></ngx-wig>
          <span style="float: left;font-weight: bold;font-size: 0.75rem;">{{ charCount }} {{ 'texto_69' |
            translate }}
            {{ maxCharCount }}</span>
        </div>
        <br>
        <div class="md-form mb-3">
          <form [formGroup]="myForm" style="width:100%;display:contens;color:whitewhite;margin-top: 26px;">
            <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="dragFile($event)">
              <input [(ngModel)]="archivos" formControlName="file"
                accept="image/gif,image/jpeg,image/jpg,image/png,video/*,audio/*,.pdf,.zip, .eml, .msg" id="file" hidden
                type="file" #fileInput (change)="uploadFile($event)">
            </div>
            <div class="files-list" *ngFor="let file of files;let i= index">
              <p> {{ file }} </p>
              <button class="delete-file" (click)="deleteAttachment(i)">
                <img src="../../assets/img/borrar-fichero.png">
              </button>
            </div>
          </form>



          <!--<form [formGroup]="myForm" style="width:100%;display:contens;color:white">
                        <div class="form-group">
                            <label for="file" class="mt-1 waves-light btn-warning btn-rounded btn" style="width: 100%;" >{{ 'Seleccionar fichero' | translate}}</label>
                            <input
                                formControlName="file"
                                id="file"
                                type="file"
                                accept="image/gif,image/jpeg,image/jpg,image/png,.pdf"
                                class="form-control"
                                (change)="onFileChange($event)">
                        </div>
                    </form>-->
        </div>
      </div>
      <div class="text-center mt-3">
        <button (click)="submit()" style="color:#fff" mdbBtn rounded="true" class="btn-primary mt-1 waves-light"
          mdbWavesEffect>{{ 'Añadir' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
