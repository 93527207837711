<button
    style="background-image: url(/../../assets/img/botones/Boton_Desestimar\ Denuncia.png);"
    class="btn btn-rounded btn-cabecera"
    matTooltip="{{ 'texto_836' | translate}}"
    [attr.aria-label]="'texto_836' | translate"
    type="button"
    mdbBtn
    rounded="true"
    data-toggle="modal"
    data-target="#reject-appeals"
    (click)="frame.show()"
    mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="reject-appeals" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document" style="max-width: 100% !important;">
        <div class="modal-content">
            <div class="modal-body mx-3" style="padding: 1rem;">
                <div class="card-header card-header-info">
                    <h5 style="margin-bottom: 0px;" class="card-title">
                      {{ 'texto_836' | translate }}
                    </h5>
                </div>
                <button
                  type="button"
                  style="z-index:100;float: right;margin-top: -2.8rem;"
                  class="btn btn-dark"
                  data-dismiss="modal"
                  (click)="frame.hide()"
                  [attr.aria-label]="'texto_735' | translate"
                >
                  X
                </button>

                <div style="margin-top: 1rem;">
                  <mat-checkbox [(ngModel)]="noReabrir" [attr.aria-label]="'texto_837' | translate">
                    {{ 'texto_837' | translate }}
                  </mat-checkbox>
                </div>
            </div>
            <div class="text-center mt-3">
                <button
                  (click)="confirmReject()"
                  style="color:#fff"
                  mdbBtn rounded="true"
                  class="btn-primary mt-1 waves-light"
                  mdbWavesEffect
                  [attr.aria-label]="'texto_41' | translate"
                >
                  {{ 'texto_41' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
