<!-- Saltar al contenido principal -->
<p>
  <a href="#mainContent" id="skip-link" class='admin-bar skip-link' href="javascript:void(0);"
    (click)="navigateToButton()">{{ 'texto_743' | translate }}</a>
</p>
<!-- End Saltar al contenido principal -->

<app-header></app-header>

<section id="mainContent" role="main" tabindex="-1">
  <div class="container">
    <div class="close">
      <a href="/inicio" class="close-btn" [attr.aria-label]="'texto_735' | translate">
        <i class="bi bi-x-square-fill"></i></a>
    </div>
    <!-- Breadcums login -->
    <div class="breadcrum-div" *ngIf="!showRecoveryFields">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/inicio" [innerHTML]="'texto_1' | translate | safe:'html'"></a></li>
          <li class="breadcrumb-item active" aria-current="page" [innerHTML]="'texto_252' | translate | safe:'html'"></li>
        </ol>
      </nav>
    </div>
    <!-- End Breadcums login -->

    <!-- Breadcums recuperar contraseña -->
    <div class="breadcrum-div" *ngIf="showRecoveryFields">
      <nav aria-label="breadcrumb" class="nav">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/inicio" [innerHTML]="'texto_1' | translate | safe:'html'"></a></li>
          <li class="breadcrumb-item"><a (click)="showRecoveryFields = false" [innerHTML]="'texto_252' | translate | safe:'html'"></a></li>
          <li class="breadcrumb-item active" aria-current="page" [innerHTML]="'texto_767' | translate | safe:'html'"></li>
        </ol>
      </nav>
    </div>
    <!-- End Breadcums recuperar contraseña -->
    <div class="left-side">
      <div class="col-md-10 col-sm-12 px-0 mx-0">
        <!-- welcome message -->
        <div class="welcome-message">
          <h1>
            <div>
              <span style="color:var(--color-txt-titulo);font-weight: bold; font-size: 2rem;" [innerHTML]="'texto_5' | translate | safe:'html'"></span>
            </div>
            <div>
              <span style="color:var(--color-txt-titulo);font-weight: bold; font-size: 4rem;" [innerHTML]="'texto_6' | translate | safe:'html'"></span>
            </div>
          </h1>
        </div>
        <!-- End welcome message -->
      </div>
    </div>
    <div class="right-side">
      <div class="form-container">
        <img class="logo" src="assets/img/logos/logo.png"
          alt="{{ 'texto_294' | translate }} {{ 'texto_6' | translate }}">
        <!-- Campo de Recuperación de Contraseña -->
        <div *ngIf="showRecoveryFields">
          <p [hidden]="!HabilitarSSO" class="description-form recordatorio" [innerHTML]="'texto_780' | translate | safe:'html'"></p>
          <div class="md-form mb-4">
            <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
            <label class="label-form" for="recovery-email" [innerHTML]="'texto_82' | translate | safe:'html'"></label>
            <input type="email" autocomplete="email" id="recovery-email" [formControl]="recoveryEmailFormControl"
              class="form-control" mdbInput mdbValidate aria-describedby="recovery-email-error"
              [attr.aria-invalid]="recoveryEmailFormControl.invalid && (recoveryEmailFormControl.dirty || recoveryEmailFormControl.touched) ? 'true' : 'false'">
            <div [hidden]="!loginOkBlock" class="alert alert-danger" role="alert"
              style="text-align: center !important;">
              <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_523' | translate }}</span>
            </div>
            <div [hidden]="!loginNoUser" class="alert alert-danger" role="alert" style="text-align: center !important;">
              <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_774' | translate }}</span>
            </div>
          </div>

          <div class="d-flex justify-content-center btn-padding">
            <button (click)="verifyEmail()" [disabled]="recoveryEmailFormControl.invalid" class="btn" mdbWavesEffect [innerHTML]="'texto_778' | translate | safe:'html'"></button>
          </div>
        </div>

        <!-- Campos de Inicio de Sesión -->
        <div *ngIf="!showRecoveryFields">
          <div class="md-form mb-4">
            <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
            <label class="label-form" for="defaultForm-email" [innerHTML]="'texto_38' | translate | safe:'html'"></label>
            <input type="email" autocomplete="email" id="defaultForm-email" [formControl]="loginFormModalEmail"
              class="form-control" mdbInput mdbValidate aria-describedby="email-error"
              [attr.aria-invalid]="loginFormModalEmail.invalid && (loginFormModalEmail.dirty || loginFormModalEmail.touched) ? 'true' : 'false'">
            <mdb-error id="email-error" role="alert"
              *ngIf="loginFormModalEmail.invalid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
              {{ 'texto_61' | translate }}
            </mdb-error>
            <mdb-success
              *ngIf="loginFormModalEmail.valid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
              {{ 'texto_62' | translate }}
            </mdb-success>
          </div>

          <div class="md-form mb-3">
            <mdb-icon fas icon="lock" class="prefix grey-text" style="display: contents;"></mdb-icon>
            <label class="label-form" for="defaultForm-pass" [innerHTML]="'texto_387' | translate | safe:'html'"></label>
            <input type="password" autocomplete="new-password" id="defaultForm-pass"
              [formControl]="loginFormModalPassword" class="form-control" mdbInput mdbValidate
              aria-describedby="password-error"
              [attr.aria-invalid]="loginFormModalPassword.invalid && (loginFormModalPassword.dirty || loginFormModalPassword.touched) ? 'true' : 'false'">

            <mdb-error id="password-error" role="alert"
              *ngIf="loginFormModalPassword.invalid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
              {{ 'texto_61' | translate }}
            </mdb-error>
            <mdb-success
              *ngIf="loginFormModalPassword.valid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
              {{ 'texto_62' | translate }}
            </mdb-success>
            <div [hidden]="!loginOk" class="alert alert-danger" role="alert" style="text-align: center !important;">
              <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_522' | translate }}</span>
            </div>
            <div [hidden]="!loginOkBlock" class="alert alert-danger" role="alert"
              style="text-align: center !important;">
              <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_523' | translate }}</span>
            </div>
            <div [hidden]="!loginNoUser" class="alert alert-danger" role="alert" style="text-align: center !important;">
              <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_524' | translate }}</span>
            </div>
            <div [hidden]="!incorrectPassword" class="alert alert-danger" role="alert"
              style="text-align: center !important;">
              <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_750' | translate }}</span>
            </div>
          </div>

          <!-- Botón de Inicio de Sesión -->
          <div class="d-flex justify-content-center btn-padding">
            <button (click)="login()" [disabled]="validatingForm.invalid" class="btn" mdbWavesEffect [innerHTML]="'texto_252' | translate | safe:'html'"></button>
          </div>

          <div [hidden]="!HabilitarSSO">
            <div class="d-flex justify-content-center btn-padding">
              <button (click)="login2()" class="btn" mdbWavesEffect [innerHTML]="'texto_535' | translate | safe:'html'"></button>
            </div>
          </div>
          <!-- Enlace de Recuperar Contraseña -->
          <div class="d-flex justify-content-center btn-padding" style="margin-top: 1rem;">
            <a (click)="showRecovery()" class="recuperar-contraseña" [innerHTML]="'texto_767' | translate | safe:'html'">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer [isFixed]="true"></app-footer>