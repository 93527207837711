import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReabrirDenunciaService } from '../../services/reabrir-denuncia.service';
import { ApiService } from '../../../shared/services/api.service';
import { AuthService } from '../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reabrir-denuncia',
  templateUrl: './reabrir-denuncia.component.html',
  styleUrls: ['./reabrir-denuncia.component.css']
})
export class ReabrirDenunciaComponent implements OnInit {
  @Input() denuncia: { id_denuncia: string; identificador_denuncia?: string;[key: string]: any };

  reopenComplaintForm: FormGroup;
  charCount: number = 0;
  maxChars: number = 4000;
  isActiveIdPersonalizable: boolean = false;

  constructor(
    private fb: FormBuilder,
    private rdSrv: ReabrirDenunciaService,
    private apiService: ApiService,
    private translate: TranslateService,
    private auth: AuthService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.reopenComplaintForm = this.fb.group({
      reason: ['', [Validators.required, Validators.maxLength(this.maxChars)]]
    });

    this.reopenComplaintForm.get('reason')?.valueChanges.subscribe((value: string) => {
      this.charCount = value ? value.length : 0;
    });

    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.isActiveIdPersonalizable = this.auth.getIdPersonalizable() === '1';
  }

  reopenComplaint(): void {
    if (this.reopenComplaintForm.valid) {
      const motivo_apelacion = this.reopenComplaintForm.value.reason;
      const idDenuncia = this.denuncia?.id_denuncia;

      if (idDenuncia) {
        this.rdSrv.reopenComplaint(Number(idDenuncia), motivo_apelacion)
          .subscribe({
            next: (response) => {
              this.toastr.success(this.translate.instant('texto_831'), this.translate.instant('texto_830'));

              if (response.recipient) {
                const recipientEmail = response.recipient;
                this.sendEmailToDecisor(recipientEmail, motivo_apelacion);
              }
            },
            error: (err) => {
              this.toastr.error(this.translate.instant('texto_832'), this.translate.instant('texto_813'));
            }
          });

        let tabs = window.location.href.split("/", 6);

        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['ver-denuncia/', tabs[4]]);
        });
      }
    }
  }

  sendEmailToDecisor(email: string, motivo: string): void {
    let $clau = false;
    let clausulas_correo = localStorage.getItem("clausulas_correo");
    if (clausulas_correo === '1') {
      $clau = true;
    }

    let idDenunciaMostrado;
    if (this.isActiveIdPersonalizable) {
      idDenunciaMostrado = this.denuncia.identificador_denuncia
        ? this.denuncia.identificador_denuncia
        : this.denuncia.id_denuncia;
    } else {
      idDenunciaMostrado = this.denuncia.id_denuncia;
    }

    const message = `${this.translate.instant('texto_833')} ${this.translate.instant('texto_834')}: ${idDenunciaMostrado}. ${this.translate.instant('texto_835')}. <br>${this.translate.instant('texto_95')}: ${motivo}`;

    this.apiService.sendEmail(
      email,
      this.translate.instant('texto_829'),
      message,
      '',
      $clau,
      this.translate.instant('texto_829')
    ).subscribe({
      next: (res) => {

      },
      error: (err) => {

      }
    });
  }
}
