import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';

@Component({
  selector: 'app-anadir-accion',
  templateUrl: './anadir-accion.component.html',
  styleUrls: ['./anadir-accion.component.css']
})
export class AnadirAccionComponent implements OnInit {

  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  @Input() denuncia: DatosDenuncia;
  contador = 0;
  isActiveIdPersonalizable: boolean = false; //Is Active Id Custom
  charCount: number = 0;
  maxCharCount: number = 4000;
  private lastValidHTML: string = '';
  

  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      nombreAccion: new FormControl('', [Validators.required]),
      nombreContacto: new FormControl('', [Validators.required]),
      responsable: new FormControl('', [Validators.required, Validators.email]),
      textoAccion: new FormControl('', [Validators.required, Validators.maxLength(4000)])
    });

    //check if idPersonalizado is active
    if (this.auth.getIdPersonalizable() == '1') {
      this.isActiveIdPersonalizable = true;
    }

    this.updateCharCount();
  }

    // Subscribe to value changes to always keep the character count updated.
    updateCharCount() {
      this.validatingForm.get('textoDesestimacion')?.valueChanges.subscribe((value: string) => {
        this.charCount = value ? value.length : 0;
      });
    }



  get nombreAccion() {
    return this.validatingForm.get('nombreAccion');
  }

  get nombreContacto() {
    return this.validatingForm.get('nombreContacto');
  }

  get responsable() {
    return this.validatingForm.get('responsable');
  }

  get textoAccion() {
    return this.validatingForm.get('textoAccion');
  }

// Validates HTML input, updates character count, and reverts to last valid value if limit is exceeded.
onEditorChange(value: string) {
  const plainText = value
    ? value.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
    : '';
    
  if (plainText.length <= this.maxCharCount) {
    this.lastValidHTML = value;
    this.charCount = plainText.length;
  } else {
    this.validatingForm
      .get('textoAccion')
      ?.setValue(this.lastValidHTML, { emitEvent: false });

    const lastPlainText = this.lastValidHTML
      ? this.lastValidHTML.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
      : '';
    this.charCount = lastPlainText.length;
  }
}

  asignarAccion(){
    if(this.contador>4000){
      this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
    }else{

      let $clau = false;
      let clausulas_correo = localStorage.getItem("clausulas_correo");
      if(clausulas_correo == '1'){
        $clau = true;
      }

      this.apiService.createActionComplaint(this.auth.getSociedad(), this.denuncia[0].id_denuncia, this.nombreAccion.value, this.responsable.value, this.nombreContacto.value, this.textoAccion.value).subscribe(
        res => {
          if(res == 200){
            this.toastr.success(this.translate.instant('texto_299'), this.translate.instant('texto_300'));
            if(this.responsable.value != ""){
              let idDenunciaMostrado; // Variable to store the complaint ID to be displayed

              // Check if isActiveIdPersonalizable is true
              if (this.isActiveIdPersonalizable) {
                // If true, use identificador_denuncia if it exists, otherwise use id_denuncia
                idDenunciaMostrado = this.denuncia[0].identificador_denuncia 
                  ? this.denuncia[0].identificador_denuncia 
                  : this.denuncia[0].id_denuncia;
              } else {
                // If isActiveIdPersonalizable is false, only use id_denuncia
                idDenunciaMostrado = this.denuncia[0].id_denuncia;
              }
              let message = this.translate.instant('texto_576') + " "  +  this.nombreContacto.value + this.translate.instant('texto_583') + " "  + idDenunciaMostrado + " "  + this.translate.instant('texto_584') + " "  + this.nombreAccion.value + this.translate.instant('texto_585') + " "  + this.textoAccion.value;
              this.apiService.sendEmail(this.responsable.value,this.translate.instant('texto_581') + this.denuncia[0].id_denuncia, message,'',$clau, this.translate.instant('texto_582')).subscribe(
                res => {
                  if(res == 200){
                  //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                  }else{
                    this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                  }
              });
            }
          }else{
            this.toastr.error(this.translate.instant('texto_301'), this.translate.instant('texto_300'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
          });
      });
  }
  }
}
