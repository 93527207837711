import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { environment } from "../../environments/environment"
import { Usuarios } from '../dashboard/models/usuarios';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-validar-denuncia',
  templateUrl: './validar-denuncia.component.html',
  styleUrls: ['./validar-denuncia.component.css']
})
export class ValidarDenunciaComponent implements OnInit {

  validatingForm: FormGroup;
  IsHiddenForm: boolean = false;
  compliance: Usuarios[];
  nameComplianceDefault: string = ''; //Id User Compliance alternative
  nameComplianceRandom: string = ''; // ID user Compliance Initial
  nameComplianceFinal: string = ''; // ID user Compliance final
  isComplianceSelected: boolean = false; // Flag to prevent further changes
  flag1: boolean = false; // flag to choose the rejected receiver
  gestionar_conflicto: string = '';
  inicial: boolean = false; // flag to mark that a compliance was chosen using the select
  isActiveIdPersonalizable: boolean = false; //Is Active Id Custom
  @Input() denuncia: DatosDenuncia;
  @ViewChild('complianceSelect') complianceSelect!: MatSelect;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      validarDenuncia: new FormControl('', []),
      userCompliance: new FormControl('', [Validators.required])
    });

    // Check if idPersonalizado is active
    if (this.auth.getIdPersonalizable() == '1') {
      this.isActiveIdPersonalizable = true;
    }

    this.gestionar_conflicto = localStorage.getItem('gestionar_conflicto');
    if (!this.isComplianceSelected) {
      this.selectedComplianceUser();
    }
  }

  get validarDenuncia() {
    return this.validatingForm.get('validarDenuncia');
  }

  // Get value userCompliance
  get userCompliance() {
    return this.validatingForm.get('userCompliance');
  }

  // Method that verifies the change of compliance user
  onChangeUserCompliance(event) {
    this.nameComplianceDefault = event.value;
    this.nameComplianceFinal = this.nameComplianceDefault;
  }

  // Method for the API call compliance users
  fetchComplianceUsers(idUserCompliance: string) {
    return this.apiService.searchUserByIdSocietyRole(this.auth.getSociedad(), idUserCompliance, false, "nulo");
  }

  // Method that obtains all users with compliance role
  selectedComplianceUser() {
    const idUserCompliance = '5'; // ID for the Compliance user

    this.fetchComplianceUsers(idUserCompliance).subscribe(
      (usuarios: Usuarios[]) => {
        if (usuarios.length > 0) {
          this.compliance = usuarios;
          // Select a random user
          const randomIndex = Math.floor(Math.random() * usuarios.length);
          this.nameComplianceRandom = usuarios[randomIndex].id_user;
          this.isComplianceSelected = true; // Mark as selected
          this.nameComplianceFinal = this.nameComplianceRandom;
        } else {
          this.toastr.error(this.translate.instant('texto_859'), this.translate.instant('texto_813'));
        }
      }, (error) => {
        console.log(error);
        this.toastr.error(this.translate.instant('texto_859'), this.translate.instant('texto_813'));
      });
  }

  // Method to show compliance users using select, excluding those that are shown randomly
  chooseComplianceBySelect() {
    this.inicial = true;  // flag to mark that a compliance was chosen using the select
    this.flag1 = true; // flag to choose the rejected receiver
    let idUserCompliance = '4'; // receptor rol id
    this.nameComplianceFinal = this.nameComplianceDefault;
    this.fetchComplianceUsers(idUserCompliance).subscribe(
      (usuarios: Usuarios[]) => {
        if (usuarios.length > 0) {
          // Filter users to hide those related to `nameComplianceRandom`
          this.compliance = usuarios.filter(user => user.id_user !== this.nameComplianceRandom);
        } else {
          this.toastr.error(this.translate.instant('texto_859'), this.translate.instant('texto_813'));
        }
      }, (error) => {
        console.log(error);
        this.toastr.error(this.translate.instant('texto_859'), this.translate.instant('texto_813'));
      });
  }

  // Method that saves the value of the chosen random compliance, verifying whether it comes from select or random compliance
  chooseComplianceRandom() {
    this.inicial = false;
    this.flag1 = false;

    this.nameComplianceDefault = ''; // clear complianceDefault value

    // Clear the selected value from mat-select
    if (this.complianceSelect) {
      this.complianceSelect.value = null;
    }

    // Clear the options array
    this.compliance = [];
    this.nameComplianceFinal = this.nameComplianceRandom;
  }

  validar() {
    let nameComplianceDecline = this.nameComplianceDefault;
    if (this.flag1) {
      nameComplianceDecline = this.nameComplianceRandom;
    }


    let $clau = false;
    let clausulas_correo = localStorage.getItem("clausulas_correo");
    if (clausulas_correo == '1') {
      $clau = true;
    }
    let url = environment.base + "/ver-denuncia/consultar-denuncia";
    let idDenunciaMostrado; // Variable to store the complaint ID to be displayed
    // Check if isActiveIdPersonalizable is true
    if (this.isActiveIdPersonalizable) {
      // If true, use identificador_denuncia if it exists, otherwise use id_denuncia
      idDenunciaMostrado = this.denuncia[0].identificador_denuncia
        ? this.denuncia[0].identificador_denuncia
        : this.denuncia[0].id_denuncia;
    } else {
      // If isActiveIdPersonalizable is false, only use id_denuncia
      idDenunciaMostrado = this.denuncia[0].id_denuncia;
    }

    if (this.validarDenuncia.value == true && this.nameComplianceFinal) {
      this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "1", this.auth.getSociedad(), "", "").subscribe(
        res => {
          if (res == 200) {
            // service for user complaint insert
            this.apiService.insertUserComplianceByComplaint(this.denuncia[0].id_denuncia, this.auth.getSociedad(), this.nameComplianceFinal, nameComplianceDecline).subscribe(
              (res: any) => {
                if (res.code == 200) {
                  // email sent to the compliance role to inform them that they have been assigned to the complaint
                  let message = this.translate.instant('texto_576') + " " + this.nameComplianceFinal + " " + this.translate.instant('texto_862') + " " + idDenunciaMostrado;
                  this.apiService.sendEmail(this.nameComplianceFinal, this.translate.instant('texto_621') + this.denuncia[0].id_denuncia, message, url, $clau, this.translate.instant('texto_621')).subscribe(
                    res => {
                      if (res == 200) {
                        //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                      } else {
                        this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                      }
                    });
                  this.toastr.success(this.translate.instant('texto_191'), this.translate.instant('texto_192'));
                  return true;
                } else {
                  this.toastr.error(this.translate.instant('texto_861'), this.translate.instant('texto_813'));
                }
              });
            if (this.denuncia[0].denunciante_correo != "") {

              let message = this.translate.instant('texto_576') + " " + this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_604') + " " + idDenunciaMostrado + " " + this.translate.instant('texto_650');
              this.apiService.sendEmail(this.denuncia[0].denunciante_correo, this.translate.instant('texto_651') + this.denuncia[0].id_denuncia, message, url, $clau, this.translate.instant('texto_651')).subscribe(
                res => {
                  if (res == 200) {
                    //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                  } else {
                    this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                  }
                });
            }
          } else {
            this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
          });
        });
    }

  }
}
