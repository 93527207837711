import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { environment } from "../../environments/environment";
import { Archivos } from '../data/formData.model';
import { FinalizarDenunciaService } from '../enviar-denuncia/services/finalizar-denuncia.service';

@Component({
  selector: 'app-archivar',
  templateUrl: './archivar.component.html',
  styleUrls: ['./archivar.component.css']
})
export class ArchivarComponent implements OnInit {
  contador = 0
  validatingForm: FormGroup;
  files: any = [];
  archivos: Archivos;
  IsHiddenForm: boolean = false;
  isActiveIdPersonalizable: boolean = false; //Is Active Id Custom
  @Input() denuncia: DatosDenuncia;

  // List of allowed extensions
  allowedExtensions = ['.eml', '.msg', '.pptx', '.pptm', '.potx', '.potm', '.ppam', '.ppsx', '.ppsm', '.xlsx', '.xlsm', '.xltx', '.xltm', '.xlam', '.gif', '.jpeg', '.jpg', '.png', '.pdf', '.txt', '.doc', '.docx', '.zip', '.mp4', '.mp3', '.aac'];

  constructor(private finDenService: FinalizarDenunciaService, private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }
  charCount: number = 0;
  maxCharCount: number = 4000;
  private lastValidHTML: string = '';

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      textoNormativa: new FormControl('', [Validators.required]),
      file: new FormControl('', []),
      fileSource: new FormControl('', []),
    });

    //check if idPersonalizado is active
    if (this.auth.getIdPersonalizable() == '1') {
      this.isActiveIdPersonalizable = true;
    }
    this.updateCharCount();
  }

  // Subscribe to value changes to always keep the character count updated.
  updateCharCount() {
    this.validatingForm.get('textoNormativa')?.valueChanges.subscribe((value: string) => {
      this.charCount = value ? value.length : 0;
    });
  }

  // Validates HTML input, updates character count, and reverts to last valid value if limit is exceeded.
  onEditorChange(value: string) {
    const plainText = value
      ? value.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
      : '';

    if (plainText.length <= this.maxCharCount) {
      this.lastValidHTML = value;
      this.charCount = plainText.length;
    } else {
      this.validatingForm
        .get('textoNormativa')
        ?.setValue(this.lastValidHTML, { emitEvent: false });

      const lastPlainText = this.lastValidHTML
        ? this.lastValidHTML.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
        : '';
      this.charCount = lastPlainText.length;
    }
  }

  get textoNormativa() {
    return this.validatingForm.get('textoNormativa');
  }

  archivar() {
    if (this.contador > 4000) {
      this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
    } else {
      this.apiService.countActionsByIdComplaint(this.auth.getSociedad(), this.denuncia[0].id_denuncia).subscribe(
        res => {
          if (res == 200) {
            // upload file if exists
            if (this.validatingForm.get('fileSource').value) {
              this.uploadFileByComplaint();
            } else {
              // if the file does not exist
              this.sendMethodReportByComplaint();
            }
          } else {
            this.toastr.error(this.translate.instant(this.translate.instant('texto_591')));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
          });
        });
    }
  }

  /**
   * carries out the process of storing compliance observations and updates the complaint phase
   */
  sendMethodReportByComplaint() {
    // Send report wiht status COMPLIANCE
    this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia, this.textoNormativa.value, '4', this.auth.getIdUser()).subscribe(
      res => {
        if (res == 200) {
          this.apiService.updateParamComplaintById(this.denuncia[0].id_denuncia).subscribe(
            res => {
              if (res == 200) {
                // send email to complaint if exist email with the corresponding information
                if (this.denuncia[0].denunciante_correo != "") {
                  this.sendEmailToComplainant();
                }
                // updates the complaint phase to 6 in the qsc_denuncias table
                this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "6", this.auth.getSociedad(), "", "").subscribe(
                  res => {
                    if (res == 200) {
                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                        this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
                      });
                    } else {
                      this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
                    }
                  });
              } else {
                this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
              }
            });
        }
        else {
          this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
        }
      });
  }

  /**
   * saves the files uploaded by compliance
   */
  uploadFileByComplaint() {
    // Create FormData for files
    const formData = new FormData();
    if (this.validatingForm.get('fileSource').value) {
      formData.append('file', this.validatingForm.get('fileSource').value);
    }
    formData.append('id_sociedad', this.denuncia[0].id_sociedad);
    formData.append('id_denuncia', this.denuncia[0].id_denuncia);
    formData.append('comentario', this.textoNormativa.value);
    formData.append('id_usuario', "setUser");
    this.finDenService.uploadFile(formData).subscribe(
      res => {
        if (res == 200) {
          this.toastr.success(this.translate.instant('texto_202'), this.translate.instant('texto_203'));
          this.sendMethodReportByComplaint();
        } else {
          this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
        }
      },
      error => {
        this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
      }
    );
  }

  /**
   * Send email to the complainant if there is previously registered email
   */
  sendEmailToComplainant() {
    let $clau = false;
    let clausulas_correo = localStorage.getItem("clausulas_correo");
    if (clausulas_correo == '1') {
      $clau = true;
    }
    let idDenunciaMostrado; // Variable to store the complaint ID to be displayed
    // Check if isActiveIdPersonalizable is true
    if (this.isActiveIdPersonalizable) {
      // If true, use identificador_denuncia if it exists, otherwise use id_denuncia
      idDenunciaMostrado = this.denuncia[0].identificador_denuncia
        ? this.denuncia[0].identificador_denuncia
        : this.denuncia[0].id_denuncia;
    } else {
      // If isActiveIdPersonalizable is false, only use id_denuncia
      idDenunciaMostrado = this.denuncia[0].id_denuncia;
    }
    let url = environment.base + "/ver-denuncia/consultar-denuncia";
    let message = this.translate.instant('texto_576') + " " + this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_586') + " " + idDenunciaMostrado + this.translate.instant('texto_587');
    this.apiService.sendEmail(this.denuncia[0].denunciante_correo, this.translate.instant('texto_588') + this.denuncia[0].id_denuncia + this.translate.instant('texto_590'), message, url, $clau, this.translate.instant('texto_589')).subscribe(
      res => {
        if (res == 200) {
          this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
        } else {
          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
        }
      });
  }

  onKey(event) {
    var datos = document.getElementById("textoNormativa");
    for (var i = 0; i < 1; i++) {
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
  }

  /**
 * Function to handle file change
 */
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.validatingForm.patchValue({
        fileSource: file
      });
    }
  }

  /**
   * Similar function for drag and drop
   */
  onFileChangeDrag(files: FileList) {
    if (files.length > 0) {
      const file = files[0];
      this.validatingForm.patchValue({
        fileSource: file
      });
    }
  }

  /**
 * Function to upload files via input
 */
  uploadFile(event) {
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      const fileExtension = '.' + element.name.split('.').pop().toLowerCase();

      if (!this.allowedExtensions.includes(fileExtension)) {
        this.toastr.error(this.translate.instant('texto_181'));
        continue;
      }

      if (this.files.length > 0) {
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
        break;
      }

      if (element.size / 1000000 > 20) {
        this.toastr.error(this.translate.instant('texto_333'));
        continue;
      }

      this.onFileChange(event);
      this.files.push(element.name);
    }
  }

  /**
   * Function to upload files via drag and drop
   */
  dragFile(files: FileList) {
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      const fileExtension = '.' + element.name.split('.').pop().toLowerCase();

      if (!this.allowedExtensions.includes(fileExtension)) {
        this.toastr.error(this.translate.instant('texto_181'));
        continue;
      }

      if (this.files.length > 0) {
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
        break;
      }

      if (element.size / 1000000 > 20) {
        this.toastr.error(this.translate.instant('texto_333'));
        continue;
      }

      this.onFileChangeDrag(files);
      this.files.push(element.name);
    }
  }

  /**
 * Function to delete a selected file
 */
  deleteAttachment(index) {
    this.files.splice(index, 1);
    this.validatingForm.patchValue({
      fileSource: null
    });
  }
}
