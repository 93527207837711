import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// Import the interfaces
import { Fase, Usuario } from '../../models/fase';

// Required services and models
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AppealsService } from '../../services/appeals.service';
import { DatosDenuncia } from '../../../shared/models/datosDenuncia';
import { DatosApelacion } from '../../../model/datosApelacion';

@Component({
  selector: 'app-accept-appeals',
  templateUrl: './accept-appeals.component.html',
  styleUrls: ['./accept-appeals.component.css']
})
export class AcceptAppealsComponent implements OnInit {

  @Input() denuncia: DatosDenuncia;
  @Input() apelacionActual: DatosApelacion;

  validatingForm: FormGroup;
  phases: Fase[] = []; // List of phases
  users: Usuario[] = []; // Users filtered by the selected phase
  show: boolean = false;
  state = true; // Controls the [disabled] state of the button
  selectedPhase: Fase | null = null;
  selectedUser: Usuario | null = null;

  constructor(
    private appealsService: AppealsService,
    private toastr: ToastrService,
    private router: Router,
    private translate: TranslateService,
    private apiService: ApiService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    // Create the FormGroup with 'phase' and 'user'
    this.validatingForm = new FormGroup({
      phase: new FormControl('', [Validators.required]),
      user: new FormControl({ value: '', disabled: true }, [Validators.required]),
      // comment: new FormControl('', [Validators.required]) // if you need it
    });

    // Call the service to get the phases with their users
    const society = this.auth.getSociedad(); // Get the id_sociedad from AuthService or wherever it's stored
    const language = this.auth.getLanguage();

    this.appealsService.getPhasesWithUsers(society, language).subscribe({
      next: (listPhases: Fase[]) => {
        this.phases = listPhases;
      },
      error: (err) => {
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
        });
      }
    });

    // Listen for changes in the phase selection to enable and filter users
    this.validatingForm.get('phase')?.valueChanges.subscribe(phaseId => {
      if (phaseId !== null && phaseId !== undefined) {
        this.selectedPhase = this.phases.find(f => f.id_fase === phaseId) || null;
        this.users = this.selectedPhase ? this.selectedPhase.usuarios : [];
        this.validatingForm.get('user')?.enable();
        this.validatingForm.get('user')?.reset();
        this.state = true;
      } else {
        this.selectedPhase = null;
        this.users = [];
        this.validatingForm.get('user')?.disable();
      }
    });

    // Listen for changes in the user selection to enable the button
    this.validatingForm.get('user')?.valueChanges.subscribe(userId => {
      if (userId) {
        this.selectedUser = this.users.find(u => u.id_user === userId) || null;
        this.state = false;
      } else {
        this.selectedUser = null;
        this.state = true;
      }
    });
  }

  // Accessors to facilitate access to the form controls
  get phase() {
    return this.validatingForm.get('phase');
  }

  get user() {
    return this.validatingForm.get('user');
  }

  // Method to confirm acceptance
  confirmAccept() {
    const idPhase = this.phase?.value;
    const idUser = this.user?.value;

    this.appealsService.acceptAppeal(
      this.denuncia.id_denuncia,
      this.apelacionActual.id_apelacion,
      idPhase,
      idUser
    ).subscribe({
      next: (resp) => {
        this.toastr.success(this.translate.instant('texto_847'), this.translate.instant('texto_846'));
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
        });
      },
      error: (err) => {
        this.toastr.error(this.translate.instant('texto_847'), this.translate.instant('texto_848'));
      },
    });
  }

}
