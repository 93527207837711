import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { Historico } from '../model/historico';
import { environment } from "../../environments/environment";
import { DatePipe } from '@angular/common';
import { Receptor } from '../model/receptor';
import { Rol } from '../model/rol';

@Component({
  selector: 'app-enviar-receptor',
  templateUrl: './enviar-receptor.component.html',
  styleUrls: ['./enviar-receptor.component.css'],
  providers: [DatePipe]
})
export class EnviarDenunciaReceptorComponent implements OnInit {
  historico: Historico[];
  validatingForm: FormGroup;
  IsHiddenForm: boolean = false;
  plazos: PlazosDenuncia[];
  receptor: Receptor[];
  receptor2: Receptor[];
  roles: Rol[];
  isActiveIdPersonalizable: boolean = false; //Is Active Id Custom
  @Input() denuncia: DatosDenuncia;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService, private datepipe: DatePipe) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      enviarDenunciaReceptor: new FormControl('', [])
    });

    //check if idPersonalizado is active
    if (this.auth.getIdPersonalizable() == '1') {
      this.isActiveIdPersonalizable = true;
    }
  }

  get enviarDenunciaReceptor() {
    return this.validatingForm.get('enviarDenunciaReceptor');
  }

  validar() {
    if (this.enviarDenunciaReceptor.value == true) {
      this.apiService.searchReceptorByIdDenuncia(this.denuncia[0].id_denuncia, '0')
        .subscribe((receptor: Receptor[]) => {
          this.receptor = receptor;
          if (this.receptor.length > 0) {
            let url = environment.base + "/editar-denuncia/" + this.denuncia[0].id_denuncia;
            this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '11')
              .subscribe((receptor2: Receptor[]) => {
                this.receptor2 = receptor2;
                if (this.receptor2.length > 0) {
                  this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "0", this.auth.getSociedad(), "", "")
                    .subscribe((res) => {
                      if (res == 200) {
                        let idDenunciaMostrado;
                        if (this.isActiveIdPersonalizable) {
                          idDenunciaMostrado = this.denuncia[0].identificador_denuncia
                            ? this.denuncia[0].identificador_denuncia
                            : this.denuncia[0].id_denuncia;
                        } else {
                          idDenunciaMostrado = this.denuncia[0].id_denuncia;
                        }

                        let messageReceptor =
                          this.translate.instant('texto_576') + " " +
                          this.receptor2[0].id_user +
                          this.translate.instant('texto_592') + " " +
                          idDenunciaMostrado +
                          this.translate.instant('texto_619');

                        this.apiService.sendEmail(
                          this.receptor2[0].id_user,
                          this.translate.instant('texto_618'),
                          messageReceptor,
                          url,
                          false,
                          this.translate.instant('texto_618')
                        ).subscribe(res => {
                          if (res == 200) {
                            this.toastr.success(
                              this.translate.instant('texto_532'),
                              this.translate.instant('texto_533')
                            );

                            let role = "";
                            this.apiService.searchRoleByUserSociety('setUser', this.auth.getSociedad(), '1')
                              .subscribe((roles: Rol[]) => {
                                this.roles = roles;
                                for (let i = 0; i < this.roles.length; i++) {
                                  role += "," + this.roles[i].id_rol;
                                }

                                if (role.includes("1") && this.receptor2[0].id_user == this.auth.getIdUser()) {
                                  console.log(this.receptor2[0].id_usuario);
                                  console.log(this.auth.getIdUser());
                                  this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true })
                                    .then(() => {
                                      this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
                                    });
                                } else {
                                  this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true })
                                    .then(() => {
                                      this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
                                    });
                                }
                              });
                          } else {
                            this.toastr.error(
                              this.translate.instant('texto_193'),
                              this.translate.instant('texto_195')
                            );
                          }
                        });
                      }
                    });
                } else {
                  this.toastr.error(this.translate.instant('texto_531'), this.translate.instant('texto_533'));
                  this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true })
                    .then(() => {
                      this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
                    });
                }
              });
          }
        });
    }
  }

};
