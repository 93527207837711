<button style="background-image: url(/../../assets/img/botones/Boton_Validar\ Denuncia.png);"
  class="btn btn-rounded btn-cabecera" matTooltip="{{'texto_112' | translate }}" type="button" mdbBtn rounded="true"
  data-toggle="modal" data-target="#basicExample" (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Content-->
    <div class="modal-content">

      <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
        <div class="card-header card-header-info">
          <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_112' | translate }}</h5>
        </div>
        <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark"
          data-dismiss="modal" (click)="frame.hide()" aria-label="Cerrar modal">X</button>

        <div class="row">
          <div class='col-xs-12 col-sm-12'>
            <mat-checkbox id="validarDenuncia" name="validarDenuncia" [formControl]="validarDenuncia">{{ 'texto_113' |
              translate }}</mat-checkbox>
          </div>
        </div>

        <div *ngIf="validarDenuncia.value" class="row mt-3">
          <div class="col-12">
            <div class="form-group">
              <label for="complianceSelect" class="control-label">
                {{ 'texto_863' | translate }}
              </label>

              <!-- Display Random Compliance -->
              <div [hidden]="inicial" class="text-center mt-2">
                <div id="compliance" name="compliance" class="border p-2 rounded">
                  <p class="mb-0">
                    {{ 'texto_864' | translate }} <strong>{{ nameComplianceRandom }}</strong>
                  </p>
                </div>

                <div *ngIf="gestionar_conflicto == '1'" class="mt-2">
                  <small class="text-muted d-block font-italic font-weight-bold">
                    *{{ 'texto_865' | translate }}
                  </small>
                  <button style="color:#fff" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect
                    (click)="chooseComplianceBySelect()">
                    {{ 'texto_866' | translate }}
                  </button>
                </div>
              </div>

              <!-- Select Compliance User -->
              <div [hidden]="!inicial" class="text-center mt-2 mb-3">
                <form [formGroup]="validatingForm">
                  <div id="compliance" name="compliance" class="border p-2 rounded">
                    <mat-form-field style="min-width:300px;">
                      <mat-label>{{ 'texto_140' | translate }}</mat-label>
                      <mat-select formControlName="userCompliance" #complianceSelect id="complianceSelect"
                        (click)="chooseComplianceBySelect()" (selectionChange)="onChangeUserCompliance($event)"
                        required>
                        <mat-option *ngFor="let item of compliance" [value]="item.id_user">
                          {{ item.n_user }} {{ item.ape_user }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="validatingForm.get('userCompliance')?.hasError('required')">
                        {{ 'texto_288' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="">
                    <button style="color:#fff" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect
                      (click)="chooseComplianceRandom()">
                      {{ 'texto_867' | translate }}
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>

        <div class='col-xs-12 col-sm-12' *ngIf="validarDenuncia.value">
          <p>
            <small style="font-size: 0.62rem;">* {{ 'texto_868' | translate }}: {{nameComplianceFinal}}</small><br>
          </p>
        </div>
        <div class="text-center mt-3">
          <button (click)="validar()" [disabled]="!nameComplianceFinal" style="color:#fff" mdbBtn rounded="true"
            class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_114' | translate }}
          </button>
        </div>

      </div>
      <!--/.Content-->
    </div>
  </div>
</div>