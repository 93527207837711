import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router';
import { AppealsService } from '../../services/appeals.service';
import { DatosDenuncia } from '../../../shared/models/datosDenuncia';
import { DatosApelacion } from '../../../model/datosApelacion';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { DatePipe } from '@angular/common'
import { AuthService } from 'src/app/shared/services/auth.service';
import { PeertopeerService } from 'src/app/shared/services/peertopeer.service';

@Component({
  selector: 'app-reject-appeals',
  templateUrl: './reject-appeals.component.html',
  styleUrls: ['./reject-appeals.component.css'],
  providers: [DatePipe]
})
export class RejectAppealsComponent implements OnInit {
  @Input() denuncia!: DatosDenuncia;
  @Input() apelacionActual: DatosApelacion;

  noReabrir: boolean = false;
  $clau = false;
  isActiveIdPersonalizable: boolean = false;

  constructor(
    private appealsService: AppealsService,
    private toastr: ToastrService,
    private apiService: ApiService,
    private router: Router,
    private translate: TranslateService,
    private datepipe: DatePipe,
    private authService: AuthService,
    private cryptoService: PeertopeerService
  ) { }

  ngOnInit(): void {

    let clausulas_correo = localStorage.getItem("clausulas_correo");
    if(clausulas_correo == '1'){
      this.$clau = true;
    }

    if (this.authService.getIdPersonalizable() == '1') {
      this.isActiveIdPersonalizable = true;
    }
  }

  // Method for rejecting the appeal of the complaint
  confirmReject() {
    let message = '';

    this.appealsService.rejectAppeal(this.denuncia.id_denuncia, this.apelacionActual.id_apelacion, this.noReabrir)
      .subscribe({
        next: (resp) => {
          // Notify the user of successful appeal rejection
          this.toastr.success(this.translate.instant('texto_839'), this.translate.instant('texto_838'));

          // Generate and send messages based on whether the complainant's email exists
          if (!this.denuncia.denunciante_correo || this.denuncia.denunciante_correo.trim() === '') {
            // Case: Anonymous complainant (no email available)
            const date = new Date();
            const _date = this.datepipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
            message = this.translate.instant('texto_870');
            if (this.noReabrir) {
              message += ' ' + this.translate.instant('texto_871');
            }

            const encryptIdSociedad = this.cryptoService.encryptData(this.denuncia.id_sociedad);
            const encryptIdDenuncia = this.cryptoService.encryptData(this.denuncia.id_denuncia);
            const encryptNombre = this.cryptoService.encryptData('setUser');
            const encryptComentario = this.cryptoService.encryptData(message);
            const encryptDate = this.cryptoService.encryptData(_date);
            const encryptIdUsuarioIdentificador = this.cryptoService.encryptData('setUser');

            this.apiService.sendMessageChat(encryptIdSociedad, encryptIdDenuncia, encryptNombre, encryptComentario, encryptDate, encryptIdUsuarioIdentificador, false).subscribe( res => {
            });
          } else {
            // Case: Identified complainant (email available)
            let idComplaint;
            if (this.isActiveIdPersonalizable) {
              // Use `identificador_denuncia` if available, otherwise default to `id_denuncia`
              idComplaint = this.denuncia.identificador_denuncia
                ? this.denuncia.identificador_denuncia
                : this.denuncia.id_denuncia;
            } else {
              // Always use `id_denuncia` if personalized IDs are not active
              idComplaint = this.denuncia.id_denuncia;
            }

            // Build the email rejection message
            message = this.translate.instant('texto_872') + ' ' + idComplaint + ', ' + this.translate.instant('texto_873');
            if (this.noReabrir) {
              message += ' ' + this.translate.instant('texto_871');
            }

            // Send email to the complainant
            this.apiService.sendEmail(
              this.denuncia.denunciante_correo,
              this.translate.instant('texto_838'),
              message,
              '',
              this.$clau,
              this.translate.instant('texto_838')
            ).subscribe(res =>{
            });
          }

          // Navigate to the updated complaint management screen
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
          });
        },
        error: (err) => {
          // Notify the user of an error during appeal rejection
          this.toastr.error(this.translate.instant('texto_840'), this.translate.instant('texto_813'));
        }
      });
  }
}
