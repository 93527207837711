import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { environment } from "../../environments/environment"
import { Historico } from '../model/historico';
import { Usuarios } from '../dashboard/models/usuarios';
import { DatePipe } from '@angular/common';
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { Rol } from '../model/rol';
import { FinalizarDenunciaService } from '../enviar-denuncia/services/finalizar-denuncia.service';
import { Archivos } from '../data/formData.model';

@Component({
  selector: 'app-enviar-informe-denunciante',
  templateUrl: './enviar-informe-denunciante.component.html',
  styleUrls: ['./enviar-informe-denunciante.component.css'],
  providers: [DatePipe]
})
export class EnviarinformeDenuncianteComponent implements OnInit {

  myForm: FormGroup;
  @Input() denuncia: DatosDenuncia;
  textoInforme: string;
  historico: Historico[];
  compliance: Usuarios[];
  contador = 0;
  plazos: PlazosDenuncia[];
  roles: Rol[];
  // Properties for file upload
  PHP_API_SERVER = environment.baseUrl;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  archivos: Archivos;
  files: any = [];
  idrole: string;
  maxCharCount: number = 4000; // Máximo de caracteres permitidos
  charCount: number = 0; // Contador de caracteres actuales
  lastValidHTML: string = ''; // Última versión válida del contenido

  // List of allowed extensions
  allowedExtensions = ['.eml', '.msg', '.pptx', '.pptm', '.potx', '.potm', '.ppam', '.ppsx', '.ppsm', '.xlsx', '.xlsm', '.xltx', '.xltm', '.xlam', '.gif', '.jpeg', '.jpg', '.png', '.pdf', '.txt', '.doc', '.docx', '.zip', '.mp4', '.mp3', '.aac'];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private http: HttpClient,
    private datepipe: DatePipe,
    private finDenService: FinalizarDenunciaService
  ) { }


  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.myForm = new FormGroup({
      textoInforme: new FormControl('', [Validators.required, Validators.maxLength(4000)]),
      file: new FormControl('', []),
      fileSource: new FormControl('', []),
      contactFormModalMessage: new FormControl('', []),
    });
    this.textoInforme = this.denuncia?.[0].informe_denunciante;

    // Initialize roles
    this.apiService.searchRoleByUserSociety('setUser', this.auth.getSociedad(), '1').subscribe((roles: Rol[]) => {
      this.roles = roles;
      let role = "";
      for (let i = 0; i < this.roles.length; i++) {
        role = role + "," + this.roles[i].id_rol;
      }
      if (role != "") {
        this.idrole = '0';
        if (role.includes("11")) { // Includes RECEPTOR TRIAJE role
          this.idrole = '11';
        }
      }
    });

    // Subscribe to value changes to always keep the character count updated.
    this.myForm.get('textoInforme')?.valueChanges.subscribe((value: string) => {
      const plainText = value
        ? value.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
        : '';
      this.charCount = plainText.length;
    });
  }

  // Subscribe to value changes to always keep the character count updated.
  updateCharCount() {
    this.myForm.get('textoInforme')?.valueChanges.subscribe((value: string) => {
      this.charCount = value ? value.length : 0;
    });
  }

  onModelChange(): void {
    const plainText = this.textoInforme
      ? this.textoInforme.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
      : '';
    this.charCount = plainText.length;
  }

  onKeyDown(event: KeyboardEvent): void {
    const plainText = this.textoInforme
      ? this.textoInforme.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
      : '';

    this.charCount = plainText.length;

    // Bloquea la entrada si se excede el límite y no es una tecla para borrar
    if (plainText.length >= this.maxCharCount && event.key !== 'Backspace' && event.key !== 'Delete') {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    const plainText = this.textoInforme
      ? this.textoInforme.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
      : '';
    const newText = plainText + pastedText;

    if (newText.length > this.maxCharCount) {
      event.preventDefault(); // Evita que se pegue el texto
      // Puedes truncar el texto pegado para ajustarlo al límite si lo deseas:
      const remainingChars = this.maxCharCount - plainText.length;
      this.textoInforme += pastedText.substring(0, remainingChars);
    } else {
      this.textoInforme += pastedText;
    }
    this.onModelChange(); // Actualiza el contador de caracteres
  }


  get texto() {
    return this.myForm.get('textoInforme');
  }

  onKey(event) {
    var datos = document.getElementById("textoInformeDenunciante");
    for (var i = 0; i < 1; i++) {
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
  }

  /**
   * Method to send the report and files
   */
  enviarInforme() {
    if (this.textoInforme != "") {
      if (this.contador > 4000) {
        this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
      } else {
        // Prepare comment
        this.textoInforme = this.textoInforme.replace(/<[^>]*>/g, '').replace(/'/g, '');

        // Create FormData for files
        // const formData = new FormData();
        // if (this.myForm.get('fileSource').value) {
        //   formData.append('file', this.myForm.get('fileSource').value);
        // }
        // formData.append('id_sociedad', this.denuncia[0].id_sociedad);
        // formData.append('id_denuncia', this.denuncia[0].id_denuncia);
        // formData.append('comentario', this.textoInforme);
        // formData.append('id_usuario', "setUser");
        // formData.append('idrole', this.idrole);

        // Upload file if exists
        // if (this.myForm.get('fileSource').value) {
        //   this.finDenService.uploadFile(formData).subscribe(
        //     res => {
        //       if (res == 200) {
        //         this.toastr.success(this.translate.instant('texto_202'), this.translate.instant('texto_203'));
        //       } else {
        //         this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
        //       }

        //       this.finalizarEnvio();
        //     },
        //     error => {
        //       this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
        //     }
        //   );
        //   this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia, this.textoInforme, "COMPLIANCE_2", this.auth.getIdUser()).subscribe(
        //     res => {
        //       if (res == 200) {
        //         this.toastr.success(this.translate.instant('texto_267'), this.translate.instant('texto_268'));
        //         this.redireccionarPorRol();
        //       } else {
        //         this.toastr.error(this.translate.instant('texto_269'), this.translate.instant('texto_268'));
        //       }
        //     },
        //     error => {
        //       this.toastr.error(this.translate.instant('texto_269'), this.translate.instant('texto_268'));
        //     }
        //   );
        // } else {
        // If no file, just send the report
        this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia, this.textoInforme, "COMPLIANCE_2", this.auth.getIdUser()).subscribe(
          res => {
            if (res == 200) {
              this.toastr.success(this.translate.instant('texto_267'), this.translate.instant('texto_268'));
              this.redireccionarPorRol();
            } else {
              this.toastr.error(this.translate.instant('texto_269'), this.translate.instant('texto_268'));
            }
          },
          error => {
            this.toastr.error(this.translate.instant('texto_269'), this.translate.instant('texto_268'));
          }
        );
      }
      //}
    } else {
      this.toastr.error(this.translate.instant('texto_272'), this.translate.instant('texto_268'));
    }
  }

  /**
   * Method to redirect based on the user's role
   */
  redireccionarPorRol() {
    let role = "";
    this.apiService.searchRoleByUserSociety('setUser', this.auth.getSociedad(), '1').subscribe((roles: Rol[]) => {
      this.roles = roles;
      for (let i = 0; i < this.roles.length; i++) {
        role = role + "," + this.roles[i].id_rol;
      }
      // If the role includes COMPLIANCE
      if ((role.includes("4"))) {
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
        });
      } else {
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
        });
      }
    });
  }

  /**
   * Method to finalize the submission after uploading files
   */
  finalizarEnvio() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
    });
  }

  /**
   * Function to handle file change
   */
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  /**
   * Similar function for drag and drop
   */
  onFileChangeDrag(files: FileList) {
    if (files.length > 0) {
      const file = files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  /**
   * Function to upload files via input
   */
  uploadFile(event) {
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      const fileExtension = '.' + element.name.split('.').pop().toLowerCase();

      if (!this.allowedExtensions.includes(fileExtension)) {
        this.toastr.error(this.translate.instant('texto_181'));
        continue;
      }

      if (this.files.length > 0) {
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
        break;
      }

      if (element.size / 1000000 > 20) {
        this.toastr.error(this.translate.instant('texto_333'));
        continue;
      }

      this.onFileChange(event);
      this.files.push(element.name);
    }
  }

  /**
   * Function to upload files via drag and drop
   */
  dragFile(files: FileList) {
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      const fileExtension = '.' + element.name.split('.').pop().toLowerCase();

      if (!this.allowedExtensions.includes(fileExtension)) {
        this.toastr.error(this.translate.instant('texto_181'));
        continue;
      }

      if (this.files.length > 0) {
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
        break;
      }

      if (element.size / 1000000 > 20) {
        this.toastr.error(this.translate.instant('texto_333'));
        continue;
      }

      this.onFileChangeDrag(files);
      this.files.push(element.name);
    }
  }

  /**
   * Function to delete a selected file
   */
  deleteAttachment(index) {
    this.files.splice(index, 1);
    this.myForm.patchValue({
      fileSource: null
    });
  }
}
