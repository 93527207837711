import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { Fase } from '../models/fase';

@Injectable({
  providedIn: 'root'
})
export class AppealsService {
  API_SERVER = environment.baseUrl; // Base URL of the API

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  /**
   * Returns HTTP headers with the Authorization token.
   */
  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Authorization': `Bearer ${this.authService.getToken()}`
    });
  }

  /**
   * Rejects an appeal.
   * @param complaintId - The ID of the complaint.
   * @param appealId - The ID of the appeal.
   * @param noReopen - A boolean indicating whether reopening is disallowed (true) or allowed (false).
   * The backend requires a numeric value: 0 (allow reopen) or 1 (disallow reopen).
   * If `noReopen` is true, then `permitir_reapertura` = 0.
   */
  rejectAppeal(complaintId: number | string, appealId: number | string, noReopen: boolean): Observable<{ message: string }> {
    const payload = {
      id_denuncia: complaintId,
      id_apelacion: appealId,
      estado: false, // Marks the appeal as rejected
      permitir_reapertura: noReopen ? 0 : 1 // 0 = Disallow reopen, 1 = Allow reopen
    };
    return this.httpClient.post<{ message: string }>(
      `${this.API_SERVER}/api/manageAppeal.php`,
      payload,
      { headers: this.getHeaders() }
    );
  }

  /**
   * Accepts an appeal.
   * @param complaintId - The ID of the complaint.
   * @param appealId - The ID of the appeal.
   * @param newPhaseId - The ID of the new phase.
   * @param reopeningUserId - The ID of the user who will manage the new phase.
   */
  acceptAppeal(complaintId: number | string, appealId: number | string, newPhaseId: number | string, reopeningUserId: number | string): Observable<{ message: string }> {
    const payload = {
      id_denuncia: complaintId,
      id_apelacion: appealId,
      estado: true,
      id_fase_reapertura: newPhaseId,
      id_usuario_reapertura: reopeningUserId
    };
    return this.httpClient.post<{ message: string }>(
      `${this.API_SERVER}/api/manageAppeal.php`,
      payload,
      { headers: this.getHeaders() }
    );
  }

  /**
   * Retrieves phases and their associated users.
   * @param societyId - ID of the society.
   * @param language - Language preference.
   */
  getPhasesWithUsers(societyId: string, language: string): Observable<Fase[]> {
    const payload = {
      id_sociedad: societyId,
      idioma: language
    };
    return this.httpClient.post<Fase[]>(
      `${this.API_SERVER}/api/getUsersByPhase.php`,
      payload,
      { headers: this.getHeaders() }
    );
  }
}
