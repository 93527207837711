import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppealsRoutingModule } from './appeals-routing.module';
import { RejectAppealsComponent } from './components/reject-appeals/reject-appeals.component';
import { AcceptAppealsComponent } from './components/accept-appeals/accept-appeals.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RejectAppealsComponent,
    AcceptAppealsComponent
  ],
  imports: [
    CommonModule,
    AppealsRoutingModule,
    MDBBootstrapModule,
    MatCheckboxModule,
    FormsModule,
    SharedModule,
    TranslateModule
  ],
  exports: [
    RejectAppealsComponent,
    AcceptAppealsComponent
  ]
})
export class AppealsModule { }
