import { Component, OnInit, ElementRef, ViewChild, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { Testigo } from '../model/testigo';
import { Medida } from '../model/medida';
import { Documento } from '../model/documento';
import { DatePipe } from '@angular/common'
import { Comentario } from '../model/comentario';
import { FormControl, FormGroup } from "@angular/forms";
import { environment } from "../../environments/environment"
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas'
import { Acciones } from '../model/acciones';
import { Rol } from '../model/rol';
import { SpinnerService } from '../services/spinner.service';
import { PeertopeerService } from '../shared/services/peertopeer.service';
import { Parametros } from '../model/parametros';
import { ConfiguracionAvanzadaService } from '../dashboard/services/configuracion-avanzada.service';
import { Apelaciones } from '../model/datosApelaciones';

declare var require: any
const FileSaver = require('file-saver');
@Injectable()
@Component({
  selector: 'app-editar-todas-denuncias',
  templateUrl: './editar-todas-denuncias.component.html',
  styleUrls: ['./editar-todas-denuncias.component.css'],
  providers: [DatePipe]
})
export class EditarTodasDenunciasComponent implements OnInit {
  validatingForm: FormGroup;
  isFinished: boolean = false;
  pin_code: string;
  denuncia: DatosDenuncia[];
  apelaciones: Apelaciones;
  testigo: Testigo[];
  medida: Medida[];
  documento: Documento[];
  accion: Acciones[];
  bExisteAccion: boolean = false;
  bExisteTestigo = true;
  bExisteMedida = true;
  bExisteDocumento = true;
  id: string;
  comentarios: Comentario[];
  avatar: string;
  showChat: boolean = false;
  sendChat: boolean = false;
  isReceptor: boolean = false;
  isFaseExterno: boolean = false;
  isDecisor: boolean = false;
  isInvestigador: boolean = false;
  isCompliance: boolean = false;  // variable to identify when is compliance role.
  isFaseInicial: boolean = false;
  isFaseDecisor: boolean = false;
  isFaseCompliance: boolean = false;
  progressBar: number = 0;
  isFaseInvestigador = false;
  backgroundColor: string = 'red';
  showDocument: boolean = false;
  showButtonArchive: boolean = false; //show button archive complaint
  @ViewChild('content') content: ElementRef;
  showBotonera = false;
  idrole: string;
  showExport = false;
  archivo: Documento[];
  isActiveCenter: string; //Variable para guardar el valor del centro: TRUE OR FALSE
  showCenter: boolean = false; //variable para mostrar el campo centro en el HTML con valor TRUE OR FALSE
  roles: Rol[];
  showButtonChangeTypeComplaint: boolean = false; //button that show selected type complaint
  showButtonChangePhase: boolean = false; //button that show selected changed phase
  showCommentaryPhase: string; //show commentary from phase changed
  showDivFromCommentary: boolean = false; //show DIV where it show commentary
  consultaResultados: any; //Show Investigators from complaints
  isActiveIdPersonalizable: boolean = false; //Is Active Id Custom
  parametros: Parametros;
  linkDrive: string; //Cloud Storage Url
  showLinkDrive: boolean = false;
  isActiveDrive: boolean = false; //Is Active Cloud Storage
  // To know if the current user is intervening
  currentUserId: string;
  isIntervening: boolean = false;


  constructor(
    private apiService: ApiService,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private actRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private cryptoService: PeertopeerService,
    private spinnerSvc: SpinnerService,
    private configuracionAvanzadaService: ConfiguracionAvanzadaService
  ) { }

  ngOnInit() {

    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }

    this.id = this.actRoute.snapshot.params.id;
    this.validatingForm = new FormGroup({
      comentarioDenuncia: new FormControl('')
    });

    // Retrieve the current user's ID
    this.currentUserId = localStorage.getItem('id_user_session') || '';

    //Activa el indicador para consultar la tabla QSC_CENTROS
    let isActiveCenter = "0";
    if (this.auth.getCentros() == '1') {
      isActiveCenter = "1";
      this.showCenter = true;
    }

    //check if idPersonalizado is active
    if (this.auth.getIdPersonalizable() == '1') {
      this.isActiveIdPersonalizable = true;
    }

    //check  drive is active
    this.configuracionAvanzadaService
      .searchAdvancedParam(this.auth.getSociedad())
      .subscribe((parametros: Parametros) => {
        this.parametros = parametros;
        this.linkDrive = this.parametros[79].valor;
        if (this.parametros[80].valor == '1') {
          this.isActiveDrive = true;
        } else {
          this.isActiveDrive = false;
        }
      })

    this.avatar = environment.baseAvatar + "social.png";
    this.translate.setDefaultLang(language);
    this.apiService.searchComplaintByIdUser(this.auth.getSociedad(), this.id, this.auth.getIdUser(), language, isActiveCenter, this.isActiveIdPersonalizable).subscribe((denuncia: DatosDenuncia[]) => {
      this.denuncia = denuncia;
      //console.log('byiduser',this.denuncia);
      if (denuncia.length != 0) {
        this.showBotonera = true;
      }
    });
    this.apiService.searchComplaintById(this.id, language, this.auth.getIdUser(), isActiveCenter, this.isActiveIdPersonalizable).subscribe((denuncia: DatosDenuncia[]) => {
      this.denuncia = denuncia;
      if (denuncia.length == 0) {
        //this.toastr.error(this.translate.instant('No existe ninguna denuncia con ese identificador'), this.translate.instant('texto_190'));
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
        });
      } else {
        //let role = this.auth.getRole();;
        let role = "";
        this.apiService.searchRoleByUserSociety('setUser', this.auth.getSociedad(), '1').subscribe((roles: Rol[]) => {
          this.roles = roles;
          for (let i = 0; i < this.roles.length; i++) {
            role = role + "," + this.roles[i].id_rol;
          }
          this.idrole = '0';
          if (this.denuncia[0].id_fase == "0") {
            this.progressBar = 10;
            this.showChat = true;
            this.sendChat = true;
            if (role.includes("1")) { // Include role RECEPTOR
              this.isFaseInicial = true;
              this.idrole = '1';
              this.showButtonChangeTypeComplaint = true;
            }
          } else if (this.denuncia[0].id_fase == "11") {
            this.progressBar = 10;
            this.showChat = true;
            this.sendChat = true;
            if (role.includes("11")) { // Include role RECEPTOR TRIAJE
              this.isFaseExterno = true;
              this.idrole = '11';
              this.isFaseInicial = false;
              this.showButtonChangeTypeComplaint = true;
            }
          } else if (this.denuncia[0].id_fase == "1") {
            this.progressBar = 25;
            this.showChat = true;
            this.backgroundColor = 'red';
            this.sendChat = true;
            this.isFaseInvestigador = true;
            this.showButtonChangePhase = true;
            // When the phase is "Investigación" to do:
          } else if (this.denuncia[0].id_fase == "2") {
            this.showChat = true;
            this.progressBar = 50;
            this.isFaseInvestigador = false;
            this.showButtonChangePhase = true;
            // this.isInvestigador = true;
            if (role.includes("2")) { // Include role INVESTIGADOR
              this.isInvestigador = true;
              this.showDocument = true;
              if (this.isActiveDrive) {
                this.showLinkDrive = true;
              }
            }
            // Show button "Editar plazos" only when role is compliance
            if (role.includes("4")) { // Include role COMPLIANCE
              this.isCompliance = true;
            }
            this.backgroundColor = '#F1C453';
            /* if( role.includes("2")){ // Include role INVESTIGADOR
               this.showDocument = true;
             } else{
               this.showDocument = false;
             }   */

            this.sendChat = true;
          } else if (this.denuncia[0].id_fase == "3") {
            this.progressBar = 65;
            this.showButtonChangePhase = true;
            //   this.isReceptor = true;
            //  this.isInvestigador = false;
            if (role.includes("1")) { // Include role RECEPTOR
              this.isFaseDecisor = true;
            } else {
              this.isFaseDecisor = false;
            }
            this.isFaseInvestigador = false;
            this.isFaseInicial = false;
            this.backgroundColor = '#EFEA5A';
            this.sendChat = true;
            // When the phase is "Decisión" to do:
          } else if (this.denuncia[0].id_fase == "4") {
            //    this.isDecisor = true;
            if (role.includes("3")) { // Include role DECISOR
              this.isDecisor = true;
              this.isFaseDecisor = true;
              if (this.isActiveDrive) {
                this.showLinkDrive = true;
              }
            }
            // Show button "Editar plazos" only when role is compliance
            if (role.includes("4")) { // Include role COMPLIANCE
              this.isCompliance = true;
            }
            this.progressBar = 75;
            this.backgroundColor = '#B9E769';
            this.showDocument = true;
            this.sendChat = false;
            this.isFaseDecisor = false;
            this.showButtonChangePhase = true;
          } else if (this.denuncia[0].id_fase == "5") {
            this.isFaseDecisor = false;
            this.isFaseCompliance = true;
            this.progressBar = 85;
            this.backgroundColor = '#83E377';
            this.showButtonChangePhase = true;
            if (role.includes("4") && this.isActiveDrive) { // Include role COMPLIANCE
              this.showLinkDrive = true;
            }
          } else if (this.denuncia[0].id_fase == "6") {
            //console.log('entro 1');
            this.progressBar = 100;
            this.backgroundColor = '#16DB93';
            this.isFinished = true;
          }
          else if (this.denuncia[0].id_fase == "7") {
            this.isFinished = true;
          }


          if (this.showBotonera == false) {
            if (role.includes("4")) { // Include role COMPLIANCE
              this.showExport = true;
            }
          } else {
            this.showExport = true;
          }

          this.apiService.searchWitnessByComplaint(this.id, this.idrole).subscribe((testigo: Testigo[]) => {
            this.testigo = testigo;
            if (testigo.length == 0) {
              this.bExisteTestigo = false;
            }
          });

          this.apiService.searchMedidaPorIdDenuncia(this.id).subscribe((medidas: Medida[]) => {
            this.medida = medidas;
            if (medidas.length == 0) {
              this.bExisteMedida = false;
            }
          })

          this.apiService.FindUsersByReportID(this.id, this.auth.getSociedad()).subscribe((resultado: any) => {
            this.consultaResultados = resultado;
            // Check if user id is present
            this.isIntervening = this.consultaResultados.some(item => item.id_user === this.currentUserId);
          });

          this.apiService.searchDocumentsByComplaint(this.id, true, this.idrole).subscribe((documento: Documento[]) => {
            this.documento = documento;
            if (documento.length == 0) {
              this.bExisteDocumento = false;
            }
          });
          //console.log('isfinished',this.isFinished);
          if (this.isFaseCompliance || this.isFinished) {
            //console.log('paso');
            this.apiService.searchActionsByComplaint(this.id).subscribe((acciones: Acciones[]) => {
              this.accion = acciones;
              if (acciones.length >= 0) {
                this.bExisteAccion = true;
                if (this.isFinished) {
                  this.bExisteAccion = false;
                  this.showButtonArchive = true;
                }
              }
            });
          }
        }); //End

        this.apiService.getAppealsByComplaint(this.denuncia[0].id_denuncia).subscribe({
          next: (response: Apelaciones) => {
            this.apelaciones = response;
          },
          error: (err) => {
            this.toastr.error(
              this.translate.instant('texto_850'),
              this.translate.instant('texto_813')
            );
          }
        });
      }

      this.apiService.searchCommentsByComplaint(this.id, false).subscribe((comentario: Comentario[]) => {
        this.comentarios = comentario;
        if (this.comentarios.length > 0) {
          this.showChat = true;
        } else {
          this.showChat = false;
        }
      });

      //Show Commnetary From Phase
      this.apiService.showCommentaryPhaseChange(this.id, this.auth.getSociedad(), this.denuncia[0].id_fase).subscribe((res => {
        //console.log(res);
        this.showCommentaryPhase = res;
        //console.log(this.showCommentaryPhase);
        if (this.showCommentaryPhase != '' && this.showCommentaryPhase != null) {
          this.showDivFromCommentary = true;
        }
      }));
    });

  }


  get comentarioDenuncia() {
    return this.validatingForm.get('comentarioDenuncia');
  }

  enviarMensaje(id_denuncia) {
    let date = new Date();
    let _date = this.datepipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    let comentario = this.comentarioDenuncia.value.replace(/<[^>]*>/g, '');
    if (comentario != null && comentario != "") {
      comentario = comentario.replace(/'/g, '');
      //encrypt data
      const encryIdSociedad = this.cryptoService.encryptData(this.denuncia[0].id_denuncia);
      const encryIdDenuncia = this.cryptoService.encryptData(id_denuncia);
      const encrytNombre = this.cryptoService.encryptData('setUser');
      const encryComentario = this.cryptoService.encryptData(comentario);
      const encryDate = this.cryptoService.encryptData(_date);
      const encryIdUsuarioIdentificador = this.cryptoService.encryptData('setUser');


      this.apiService.sendMessageChat(encryIdSociedad, encryIdDenuncia, encrytNombre, encryComentario, encryDate, encryIdUsuarioIdentificador, false).subscribe(
        res => {
          if (res == 200) {
            this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_484'));
          } else {
            this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_484'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/', this.id]);
          });
        });
    } else {
      this.toastr.error(this.translate.instant('texto_481'), this.translate.instant('texto_484'));
    }
  }

  exportarDocumento() {
    let ok = false;
    if (this.documento.length > 0) {
      if (confirm("¿Desea imprimir también los ficheros adjuntos a la denuncia?:")) {
        ok = true;
      }
    }
    document.getElementById('botonera').style.display = 'none';

    var data = document.getElementById('contenedor');
    html2canvas(data).then(canvas => {
      // Few necessary setting options

      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      let d = new Date().toISOString();
      pdf.save('report-' + d + '.pdf');

      if (ok) {
        for (let j = 0; j < this.documento.length; j++) {
          let url = this.documento[j].ruta_archivo;
          let name = this.documento[j].n_archivo;
          this.downloadPdf(url, name);
        }
      }

      /* var imgWidth = 210;
       var pageHeight = 297;
       var imgHeight = canvas.height * imgWidth / canvas.width;
       var heightLeft = imgHeight;
       const contentDataURL = canvas.toDataURL('image/png')
       let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
       var position = 0;
       pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      heightLeft -= pageHeight;
       while (heightLeft >= 0) {
         position = heightLeft - imgHeight;

         pdf.addPage();
         pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
         heightLeft -= pageHeight;
       }
       if(ok){

         for(let j=0;j<this.documento.length;j++){
           let url = this.documento[j].ruta_archivo;
           let name = this.documento[j].n_archivo;
          // this.downloadPdf(url,name);
         }
       }
       let d = new Date().toISOString();;
       pdf.save('report-'+d+'.pdf');   */
      document.getElementById('botonera').style.display = 'block';
    });
    this.toastr.success('Exportando...', 'Exportando');
  }

  downloadPdf(pdfUrl: string, pdfName: string) {
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  finalizarAccion(id_action: string) {
    this.apiService.updateActionsById(this.denuncia[0].id_denuncia, id_action).subscribe(
      res => {
        if (res == 200) {
          this.toastr.success(this.translate.instant('texto_283'), this.translate.instant('texto_285'));
        } else {
          this.toastr.error(this.translate.instant('texto_284'), this.translate.instant('texto_285'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
        });
      });
  }

  /**
  * Function to download a file based on its ID, name, and type.
  * @param id_archivo The ID of the file.
  * @param n_archivo The name of the file.
  * @param tipo_archivo The type of the file.
  */
  verArchivo(id_archivo: string, n_archivo: string, tipo_archivo: string) {
    let linkSource = '';
    let mimeType = '';

    //spinner.
    //this.spinnerSvc.show();
    this.toastr.info(this.translate.instant('texto_723'));

    //determine the file type
    switch (tipo_archivo) {
      case 'pdf':
        mimeType = 'application/pdf';
        break;
      case 'jpe':
      case 'jpeg':
      case 'jpg':
        mimeType = 'image/jpeg';
        break;
      case 'doc':
      case 'docx':
        mimeType = 'application/msword';
        break;
      case 'txt':
      case 'bas':
      case 'c':
      case 'h':
        mimeType = 'text/plain';
        break;
      case 'xls':
      case 'xlsx':
        mimeType = 'application/vnd.ms-excel';
        break;
      case 'msg':
      case 'eml':
        mimeType = 'application/vnd.ms-outlook';
        break;
      case 'mp4':
        mimeType = 'video/mp4';
        break;
      case 'zip':
        mimeType = 'application/zip';
        break;
      case 'mp3':
      case 'aac':
        mimeType = 'audio/mpeg';
        break;
      case 'htm':
      case 'html':
      case 'stm':
        mimeType = 'text/html';
        break;
      case 'mp2':
      case 'mpa':
      case 'mpe':
      case 'mpeg':
      case 'mpg':
      case 'mpv2':
        mimeType = 'audio/mpeg';
        break;
      case 'gif':
        mimeType = 'image/gif';
        break;
      case 'png':
        mimeType = 'image/png';
        break;
      default:
        this.toastr.error(this.translate.instant('texto_181'));
        return;
    }


    this.apiService.verArchivo(id_archivo).subscribe(
      (data) => {

        // constructs a data URL for the file using the mimeType and the fetched file data.
        linkSource = 'data:' + mimeType + ';base64,' + data;
        const downloadLink = document.createElement('a');
        const fileName = n_archivo;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.target = '_blank';
        downloadLink.click();
        downloadLink.remove();
        //this.spinnerSvc.hide();
        this.toastr.clear();
        this.toastr.success(this.translate.instant('texto_724'));
      },
      (error) => {
        this.toastr.error(this.translate.instant('texto_204'));
        this.spinnerSvc.hide();
      }
    );
  }

  openLinkDrive(): void {
    window.open(this.linkDrive, '_blank');
  }
}
