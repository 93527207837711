<div class="card" *ngIf="id_personalizable_enabled && showAdvOpts">
  <div class="card-header card-header-primary">
    <h4 class="card-title">{{ 'texto_760' | translate }}</h4>
  </div>
  <div class="content">
    <form [formGroup]="validatingForm" style="margin-left: 25px; margin-right: 25px;">
      
      <!-- Reset numbering Section -->
      <div class="row" style="padding-top: 1rem">
        <div class="col-md-6">
          <div class="form-group">
            <mat-checkbox [(ngModel)]="reset_numberingF" formControlName="reset_numbering"class="example-margin"
            (change)="disableChecks()">
              {{'texto_766' |translate }}
            </mat-checkbox>
          </div>
        </div>
      </div>
      <!-- reset numbering Year Section -->
      
      <!-- Characters Section -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <mat-checkbox [(ngModel)]="include_charactersF" formControlName="include_characters" 
              (change)="toggleInput('include_characters', include_charactersF, 'identifier_characters')"
              class="example-margin">{{ 'texto_761' | translate }}</mat-checkbox>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input [(ngModel)]="identifier_charactersF" formControlName="identifier_characters" type="text"
              class="form-control" 
              (input)="updateIdentifier()" pattern="[A-za-z]*" maxlength="7">

            <!-- Error Message -->
            <mdb-error id="identifier-characters-error" role="alert" *ngIf="validatingForm.get('identifier_characters')?.invalid && 
                  (validatingForm.get('identifier_characters')?.dirty || 
                  validatingForm.get('identifier_characters')?.touched)">
              {{ 'texto_61' | translate }}
            </mdb-error>

            <!-- Success Message -->
            <mdb-success style="font-size: 0.7rem !important; margin-left: 0% !important;"
              *ngIf="validatingForm.get('identifier_characters')?.valid">
              {{ 'texto_62' | translate }}
            </mdb-success>
          </div>
        </div>
      </div>
      <!-- End Characters Section -->

      <!-- Year Section -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <mat-checkbox [(ngModel)]="include_yearF" formControlName="include_year" 
              (change)="toggleInput('include_year', include_yearF, 'identifier_year')" class="example-margin">{{
              'texto_762' |
              translate }}</mat-checkbox>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input [value]="include_yearF ? currentYear : ''" formControlName="identifier_year" type="text"
              class="form-control" readonly />
          </div>
        </div>
      </div>
      <!-- End Year Section -->

      <!-- Generated Identifier Section -->
      <div class="row" style="padding-bottom: 1rem;">
        <div class="col-md-12">
          <label class="bmd-label-floating">{{ 'texto_764' | translate }}{{ idPersonalizable.personalized_identifier }}</label>
        </div>
      </div>
      <!-- Submit Button -->
      <button [disabled]="validatingForm.invalid && checkResetDisabled" (click)="submitIdentifier()" type="submit"
        class="btn btn-primary pull-right">
        {{ 'texto_384' | translate }}
      </button>
      <div class="clearfix"></div>
    </form>
  </div>
</div>