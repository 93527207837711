import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { environment } from "../../environments/environment"
import { Historico } from '../model/historico';
import { Usuarios } from '../dashboard/models/usuarios';
import { DatePipe } from '@angular/common';
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { Rol } from '../model/rol';
import { UserCompliance } from '../dashboard/models/userCompliance';

@Component({
  selector: 'app-enviar-resolucion',
  templateUrl: './enviar-resolucion.component.html',
  styleUrls: ['./enviar-resolucion.component.css'],
  providers: [DatePipe]
})
export class EnviarResolucionComponent implements OnInit {

  myForm: FormGroup;
  @Input() denuncia: DatosDenuncia;
  textoInforme: string;
  historico: Historico[];
  compliance: Usuarios[];
  userCompliance: UserCompliance[];
  plazos: PlazosDenuncia[];
  roles: Rol[];
  isActiveIdPersonalizable: boolean = false; //Is Active Id Custom
  charCount: number = 0;
  maxCharCount: number = 4000;
  private lastValidHTML: string = '';

  constructor(private apiService: ApiService, private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private http: HttpClient, private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.myForm = new FormGroup({
      textoInforme: new FormControl('', [Validators.maxLength(4000)]),
    });
    this.textoInforme = this.denuncia?.[0].informe_decisor;

    //check if idPersonalizado is active
    if (this.auth.getIdPersonalizable() == '1') {
      this.isActiveIdPersonalizable = true;
    }

    // Subscribe to value changes to always keep the character count updated.
    this.updateCharCount();
  }

  get texto() {
    return this.myForm.get('textoInforme');
  }

  // Subscribe to value changes to always keep the character count updated.
  updateCharCount() {
    this.myForm.get('textoInforme')?.valueChanges.subscribe((value: string) => {
      this.charCount = value ? value.length : 0;
    });
  }

  // Validates HTML input, updates character count, and reverts to last valid value if limit is exceeded.
  onEditorChange(value: string) {
    const plainText = value
      ? value.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
      : '';

    if (plainText.length <= this.maxCharCount) {
      this.lastValidHTML = value;
      this.charCount = plainText.length;
    } else {
      this.myForm
        .get('textoInforme')
        ?.setValue(this.lastValidHTML, { emitEvent: false });

      const lastPlainText = this.lastValidHTML
        ? this.lastValidHTML.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
        : '';
      this.charCount = lastPlainText.length;
    }
  }

  enviarInforme() {
    if (this.textoInforme != "") {
      if (this.charCount > 4000) {
        this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
      } else {
        // Assign the COMPLIANCE role as status for the report
        let roleUsuario = '4';
        let idFase = '6';
        let idUser = '5';
        if (this.denuncia[0].id_fase == 4) {
          // Assign the DECISOR role as status for the report
          roleUsuario = '3';
          idFase = '5';
          idUser = '4';
        }
        this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia, this.textoInforme, roleUsuario, this.auth.getIdUser()).subscribe(
          res => {
            if (res == 200) {

              this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, idFase, this.auth.getSociedad(), "", "").subscribe(
                res => {
                  if (res == 200) {

                    //service to choose compliance from the new table
                    this.apiService.searchUserComplianceByIdSocietyRole(this.auth.getSociedad(), this.denuncia[0].id_denuncia).subscribe((usuario: [UserCompliance]) => {
                      this.userCompliance = usuario;

                      this.apiService.disableUserByIdComplaint(this.denuncia[0].id_denuncia).subscribe(
                        res => {
                          if (res == 200) {
                            this.apiService.createUserByIdComplaint(this.auth.getSociedad(), this.userCompliance[0].id_usuario_comp, this.denuncia[0].id_denuncia, '3', '1', '4', false).subscribe(
                              res => {
                                if (res == 200) {

                                  let url = environment.base + "/#/ver-denuncia/consultar-denuncia";
                                  let message = "";
                                  let idDenunciaMostrado; // Variable to store the complaint ID to be displayed

                                  // Check if isActiveIdPersonalizable is true
                                  if (this.isActiveIdPersonalizable) {
                                    // If true, use identificador_denuncia if it exists, otherwise use id_denuncia
                                    idDenunciaMostrado = this.denuncia[0].identificador_denuncia
                                      ? this.denuncia[0].identificador_denuncia
                                      : this.denuncia[0].id_denuncia;
                                  } else {
                                    // If isActiveIdPersonalizable is false, only use id_denuncia
                                    idDenunciaMostrado = this.denuncia[0].id_denuncia;
                                  }

                                  let $clau = false;
                                  let clausulas_correo = localStorage.getItem("clausulas_correo");
                                  if (clausulas_correo == '1') {
                                    $clau = true;
                                  }

                                  if (idFase == '5') {
                                    this.apiService.searchPlazosByIdDenuncia(this.denuncia[0].id_denuncia).subscribe((investigator: PlazosDenuncia[]) => {
                                      this.plazos = investigator;
                                      let fecha = new Date(); // ó new Date(valor);
                                      let plazo = this.plazos[0].plazo_compliance;
                                      fecha.setDate(fecha.getDate() + parseInt(plazo));
                                      let _date = this.datepipe.transform(fecha, 'dd-MM-yyyy');

                                      let messageC = this.translate.instant('texto_576') + " " + this.userCompliance[0].id_usuario_comp + this.translate.instant('texto_592') + " " + idDenunciaMostrado + " " + this.translate.instant('texto_620') + " " + _date;
                                      let urlC = environment.base + "/editar-denuncia/" + this.denuncia[0].id_denuncia;

                                      // add "user compliance" to table QSC_DENUNCIAS_PLAZOS with deadlines assingned from investigador and decisor.
                                      this.apiService.createDeadlinesByIdComplaint(this.auth.getSociedad(), this.denuncia[0].id_denuncia, this.plazos[0].plazo_investigacion, this.plazos[0].plazo_decisor, this.plazos[0].plazo_compliance, this.plazos[0].plazo_recepcion, this.compliance[0].id_user).subscribe(
                                        res => {
                                          if (res == 200) {
                                            this.toastr.success(this.translate.instant('texto_325'), this.translate.instant('texto_326'));
                                          } else {
                                            this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
                                          }
                                        },
                                        err => {
                                          this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
                                        }
                                      );

                                      console.log('llego aqui');
                                      this.apiService.sendEmail(this.compliance[0].mail_user, this.translate.instant('texto_621') + idDenunciaMostrado, messageC, urlC, $clau, this.translate.instant('texto_621')).subscribe(
                                        res => {
                                          if (res == 200) {
                                            //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                          } else {
                                            this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                          }
                                        });
                                    });
                                  }

                                  if (this.denuncia[0].denunciante_correo != "") {
                                    this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '1').subscribe((investigator: PlazosDenuncia[]) => {
                                      this.plazos = investigator;
                                      let fecha = new Date(); // ó new Date(valor);
                                      url = environment.base + "/consultar-denuncia";
                                      if (idFase == '5') {
                                        let plazo = this.plazos[0].plazo_compliance;
                                        fecha.setDate(fecha.getDate() + parseInt(plazo));
                                        let _date = this.datepipe.transform(fecha, 'dd-MM-yyyy');
                                        // message = this.translate.instant('texto_576') + " "  +  this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_604') + " "  + this.denuncia[0].id_denuncia + " "  + this.translate.instant('texto_622') + " " +_date;
                                        message = this.translate.instant('texto_576') + " " + this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_604') + " " + idDenunciaMostrado + " " + this.translate.instant('texto_622');
                                      } else {
                                        let plazo = this.plazos[0].plazo_decisor;
                                        fecha.setDate(fecha.getDate() + parseInt(plazo));
                                        let _date = this.datepipe.transform(fecha, 'dd-MM-yyyy');
                                        // message = this.translate.instant('texto_576') + " "  +  this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_604') + " "  + this.denuncia[0].id_denuncia + " " + this.translate.instant('texto_622') + " " +_date;
                                        message = this.translate.instant('texto_576') + " " + this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_604') + " " + idDenunciaMostrado + " " + this.translate.instant('texto_622');
                                      }

                                      this.apiService.sendEmail(this.denuncia[0].denunciante_correo, this.translate.instant('texto_598'), message, url, $clau, this.translate.instant('texto_598')).subscribe(
                                        res => {
                                          if (res == 200) {
                                            //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                          } else {
                                            this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                          }
                                        });
                                    });
                                  }

                                  this.toastr.success(this.translate.instant('texto_267'), this.translate.instant('texto_268'));
                                  //let role = this.auth.getRole();
                                  let role = "";
                                  this.apiService.searchRoleByUserSociety('setUser', this.auth.getSociedad(), '1').subscribe((roles: Rol[]) => {
                                    this.roles = roles;
                                    for (let i = 0; i < this.roles.length; i++) {
                                      role = role + "," + this.roles[i].id_rol;
                                    }
                                    // Role includes COMPLIANCE
                                    if ((role.includes("4") && idFase == '5') || (role.includes("4") && idFase == '6')) {
                                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                        this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
                                      });
                                    } else {
                                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                        this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
                                      });
                                    }
                                  });

                                } else {
                                  this.toastr.error(this.translate.instant('texto_269'), this.translate.instant('texto_268'));
                                }
                              });
                          } else {
                            this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                          }
                        });

                    });
                  } else {
                    this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
                  }
                });
            } else {
              this.toastr.error(this.translate.instant('texto_269'), this.translate.instant('texto_268'));
            }
          });
      }
    } else {
      this.toastr.error(this.translate.instant('texto_272'), this.translate.instant('texto_268'));
    }

  }

}
