<button style="background-image: url(/../../assets/img/botones/Boton_Regresar.png);" id="btn-reabrir-modal"
    class="btn btn-finish btn-wd btn-cabecera" type="button" matTooltip="Reabrir Denuncia"
    [attr.aria-label]="'Reabrir Denuncia'" mdbBtn rounded="true" data-toggle="modal" data-target="#reopenComplaintModal"
    (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="reopenComplaintModal" tabindex="-1" role="dialog"
    aria-labelledby="reopenComplaintModalLabel" aria-hidden="true">
    <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
        <div class="modal-content">
            <div class="modal-body">
                <div class="card-header card-header-info">
                    <h5 class="card-title" style="color:white; margin-bottom: 0px;">{{ 'texto_827' | translate }}</h5>
                </div>
                <button id="btn-close-modal" type="button" style="z-index: 100; float: right; margin-top: -2.8rem;"
                    class="btn btn-dark" data-dismiss="modal" (click)="frame.hide()" aria-label="Cerrar">
                    X
                </button>

                <form [formGroup]="reopenComplaintForm">
                    <div class="row">
                        <div class="md-form mb-0 col-md-12">
                            <label for="reason">{{ 'texto_828' | translate }}</label>
                            <textarea id="reason" class="md-textarea form-control" rows="4" maxlength="4000"
                                formControlName="reason" mdbInput
                                [attr.aria-invalid]="reopenComplaintForm.controls['reason'].invalid ? 'true' : null">
                            </textarea>
                            <span
                                style="position: absolute; bottom: -10px; left: 15px; font-size: 0.75rem; color: #6c757d;"
                                aria-live="polite">
                                <span>{{ charCount }} {{ 'texto_458' | translate }} 4000</span>
                            </span>
                        </div>
                    </div>
                </form>

                <div class="d-flex justify-content-center">
                    <button id="btn-reabrir" type="button" class="btn btn-primary waves-light" mdbBtn rounded="true"
                        [disabled]="reopenComplaintForm.invalid" (click)="reopenComplaint()">
                        {{ 'texto_41' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>