import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from "../../../environments/environment"

import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { UserData } from '../../model/users';
import { Apelaciones } from '../../model/datosApelaciones';
import { DatosApelacion } from '../../model/datosApelacion';

import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
  constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) { }

  /*login2(): Observable<Users[]>{
    return this.httpClient.get<Users[]>(`${this.PHP_API_SERVER}/api/login.php`);
  }*/

  login(username: string, password: string): Observable<void> {
    return this.httpClient.post<void>(`${this.PHP_API_SERVER}/api/authenticateWithToken.php`, {
      email: username,
      password: password,
    });
  }

  loginWithToken(token): Observable<{ message: string, accessToken: string, refreshToken: string }> {
    return this.httpClient.post<{ message: string, accessToken: string, refreshToken: string }>(`${this.PHP_API_SERVER}/api/login.php`, {
      userToken: token,
    });
  }

  verifyPasswordToken(token): Observable<{ accessToken: string }> {
    return this.httpClient.post<{ accessToken: string }>(`${this.PHP_API_SERVER}/api/users/verifyPasswordToken.php`, {
      userToken: token,
    });
  }

  verifyPasswordTokenComplaint(token): Observable<{ accessToken: string }> {
    return this.httpClient.post<{ accessToken: string }>(`${this.PHP_API_SERVER}/api/Denuncias/verifyPasswordTokenComplaint.php`, {
      userToken: token,
    });
  }

  refreshToken(token): Observable<{ message: string, accessToken: string, refreshToken: string }> {
    return this.httpClient.post<{ message: string, accessToken: string, refreshToken: string }>(`${this.PHP_API_SERVER}/api/refreshToken.php`, {
      refreshToken: token,
    });
  }

  loginSingleSignOn() {
    //return this.httpClient.post(`${this.PHP_API_SERVER}/api/saml_login.php`, {
    //return this.router.navigate([`${this.PHP_API_SERVER}/api/saml_login.php`],{
    //});
    window.location.href = `${this.PHP_API_SERVER}/api/saml_login.php`;
  }
  logoutSingleSignOn(sessioni: string, nameidv: string, nameidf: string) {
    //return this.httpClient.post(`${this.PHP_API_SERVER}/api/saml_logout.php`, {
    //});
    window.location.href = `${this.PHP_API_SERVER}/api/saml_logout.php?session_index=` + sessioni + `&nameid_value=` + nameidv + `&nameid_format=` + nameidf;
  }

  meInfo(): Observable<UserData> {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.get<UserData>(`${this.PHP_API_SERVER}/api/me.php`, options);
  }

  /**
 * Retrieves the email to the user who want reset password.
 * @returns An Observable of `UserData` containing the user  information.
 */
  meEmail(): Observable<UserData> {
    // Set up headers with the access token for authorization
    const headers = { 'Authorization': 'Bearer ' + this.auth.getAccessTokenPassword() };
    let options = { headers: headers };

    // Send GET request to the backend API to retrieve the user info
    return this.httpClient.get<UserData>(`${this.PHP_API_SERVER}/api/me.php`, options);
  }

  /**
 * Retrieves the email to the user who want reset password complaint.
 * @returns An Observable of `UserData` containing the user  information.
 */
  meEmailComplaint(): Observable<UserData> {
    // Set up headers with the access token for authorization
    const headers = { 'Authorization': 'Bearer ' + this.auth.getAccessTokenPasswordComplaint() };
    let options = { headers: headers };

    // Send GET request to the backend API to retrieve the user info
    return this.httpClient.get<UserData>(`${this.PHP_API_SERVER}/api/me.php`, options);
  }

  //Movido a dashboard.service
  // updateLastConnectionUser() {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };
  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/updateLastConnectionUser.php`, options);
  // }

  protected(token: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/protected.php`, {
      token: token,
    });
  }
  //Servicio movido a gestion-usuarios.service.ts
  updateDataUser(email: string, name: string, surname: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/updateUser.php`, {
      email: email,
      name: name,
      surname: surname,
    }, options);
  }

  //Servicio movido a gestion-usuarios.service.ts
  // updatePasswordUser(username:string, password:string, passwordOld:string, reenviar:boolean) {
  //   // TODO: Todas estas configuraciones de cabeceras se hacen en el interceptor. Es redundante hacerlo aquí.
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/updatePassword.php`, {
  //     username: username,
  //     password: password,
  //     passwordOld: passwordOld,
  //     reenviar: reenviar
  //   },  options);
  // }

  // addCompany(id_sociedad:string, id_empresa:string, n_empresa:string, comentario:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearEmpresa.php`, {
  //     id_sociedad: id_sociedad,
  //     id_empresa: id_empresa,
  //     n_empresa: n_empresa,
  //     comentario: comentario
  //   },  options);
  // }

  // /**
  //  * MÉTODO PARA AGREGAR CENTRO
  //  * @param id_sociedad
  //  * @param id_centro
  //  * @param n_centro
  //  * @param comentario
  //  * @param id_empresa
  //  * @param id_usuario
  //  * @param comentarios
  //  * @returns
  //  */
  // addCenter(id_sociedad:string, id_centro:string, n_centro:string, id_empresa:string, comentarios:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/crearCentro.php`, {
  //     id_sociedad: id_sociedad,
  //     id_centro: id_centro,
  //     n_centro: n_centro,
  //     id_empresa: id_empresa,
  //     comentarios: comentarios
  //   },  options);
  // }

  addSociedad(id_sociedad: string, n_sociedad: string, web: string, comentario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearSociedad.php`, {
      id_sociedad: id_sociedad,
      n_sociedad: n_sociedad,
      web: web,
      comentario: comentario
    }, options);
  }


  searchCompanyByUser(id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarEmpresaUsuario.php`, {
      id_sociedad: id_sociedad,
    }, options);
  }

  searchSociedades() {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarSociedades.php`, {
    }, options);
  }

  searchIdiomas() {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarIdiomas.php`, {
    }, options);
  }


  // searchTodosIdiomas() {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTodosIdiomas.php`, {
  //   }, options);
  // }

  searchCompanyUserById(id_sociedad: string, id_empresa: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarEmpresaUsuariobyId.php`, {
      id_sociedad: id_sociedad,
      id_empresa: id_empresa,
    }, options);
  }

  searchCompanyById(id: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarEmpresaById.php`, {
      id: id,
      id_sociedad: id_sociedad,
    }, options);
  }

  deleteCompanyByUser(id_sociedad: string, id_empresa: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarEmpresa.php`, {
      id_sociedad: id_sociedad,
      id_empresa: id_empresa,
    }, options);
  }

  // /**
  //  * MÉTODO ELIMINAR CENTRO POR USUARIO
  //  * @param id_usuario
  //  * @param id_sociedad
  //  * @param id_empresa
  //  * @returns
  //  */
  // deleteCenterByUser(id_sociedad:string, id_centro:string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/eliminarCentro.php`, {
  //     id_sociedad: id_sociedad,
  //     id_centro: id_centro,
  //   },  options);
  // }


  deleteSociedad(id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarSociedad.php`, {
      id_sociedad: id_sociedad
    }, options);
  }

  deleteInvesSelect(id_usuario: string, id_sociedad: string, id_denuncia: string, rol: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarInvestigador.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      rol: rol
    }, options);
  }

  // updateCompanyByUser(id_sociedad:string, id_empresa:string, n_empresa:string, comentario:string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarEmpresa.php`, {
  //     id_sociedad: id_sociedad,
  //     id_empresa: id_empresa,
  //     n_empresa: n_empresa,
  //     comentario: comentario
  //   },  options);
  // }


  updateSociedad(id_sociedad: string, n_sociedad: string, web: string, comentario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarSociedad.php`, {
      id_sociedad: id_sociedad,
      n_sociedad: n_sociedad,
      web: web,
      comentario: comentario
    }, options);
  }


  searchUserByIdSociety(id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuariosByIdSociedad.php`, {
      id_sociedad: id_sociedad,
    }, options);
  }
  //Servicio movido a gestion-usuarios.service.ts
  // searchAllUsers() {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTodosUsuarios.php`, {
  //   },  options);
  // }


  searchUserByIdSocietyRole(id_sociedad: string, id_role: string, ramdom: boolean, id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuariosByIdSociedadRole.php`, {
      id_sociedad: id_sociedad,
      id_role: id_role,
      ramdom: ramdom,
      id_denuncia: id_denuncia
    }, options);
  }

  /**
   * method to search for compliance users by complaint
   * @param id_sociedad
   * @param id_denuncia
   * @returns
   */
  searchUserComplianceByIdSocietyRole(id_sociedad: string, id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/Denuncias/searchUsersComplianceByIdComplaint.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia
    }, options);
  }


  searchInvesSelect(id_sociedad: string, id_denuncia: string, id_role: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarInvestigadorSeleccionado.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      id_role: id_role
    }, options);
  }

  searchInformes(id_sociedad: string, id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarInforme.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia
    }, options);
  }

  searchreceptorrechazadoByDenucia(id_sociedad: string, id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarreceptorRechazado.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia
    }, options);
  }

  //Servicio movido a gestion-usuarios.service.ts
  // deleteUser(id_usuario:string, id_sociedad:string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarUsuario.php`, {
  //     id_usuario: id_usuario,
  //     id_sociedad: id_sociedad,
  //   },  options);
  // }

  //Servicio movido a gestion-usuarios.service.ts
  // addUser(id_user:string, mail_user:string, n_user:string, ape_user:string, id_sociedad:string, key:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearUsuario.php`, {
  //     id_user: id_user,
  //     mail_user: mail_user,
  //     n_user: n_user,
  //     ape_user: ape_user,
  //     id_sociedad: id_sociedad,
  //     key: key
  //   },  options);
  // }

  //Servicio movido a gestion-usuarios.service.ts
  searchUserById(id_user: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuarioId.php`, {
      id_user: id_user
    }, options);
  }

  //Servicio movido a gestion-usuarios.service.ts
  // updateUser(id_user:string, mail_user:string, n_user:string, ape_user:string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarUsuario.php`, {
  //     id_user: id_user,
  //     mail_user: mail_user,
  //     n_user: n_user,
  //     ape_user: ape_user,
  //   },  options);
  // }

  //Servicio movido a gestion-usuarios.service.ts
  // blockUser(id_user:string, state:string, id_usuario: string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/bloquearUsuario.php`, {
  //     id_user: id_user,
  //     state: state,
  //     id_usuario: id_usuario
  //   },  options);
  // }

  //Servicio movido a gestion-usuarios.service.ts
  // servicio ovido a finalizar-denuncia.service
  sendEmail(id_user: string, subject: string, message: string, boton: string, clausulas: boolean, titulo: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/enviarEmail.php`, {
      id_user: id_user,
      subject: subject,
      message: message,
      boton: boton,
      clausulas: clausulas,
      titulo: titulo,
    }, options);
  }

  sendEmailSupport(firstName: string, lastName: string, subject: string, replyTo: string, message: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/enviarEmailSoporte.php`, {
      firstName: firstName,
      lastName: lastName,
      subject: subject,
      replyTo: replyTo,
      message: message
    });
  }

  getSocieties() {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getSociedades.php`, {
    }, options);
  }

  getSessionPropertiesUser() {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getSessionPropertiesUser.php`, {
    }, options);
  }

  // getSocietiesByIdUser() {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/getSociedadesByIdUser.php`, {
  //   }, options);
  // }

  // updateSessionPropertySociety(society:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/updateSessionPropertySociety.php`, {
  //     society: society,
  //   }, options);
  // }

  createSessionPropertiesByUser(id_usuario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearUserPropertiesByIdUser.php`, {
      id_usuario: id_usuario,
    }, options);
  }

  // updateSessionPropertyLanguage(idioma:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/updateSessionPropertyIdioma.php`, {
  //     idioma: idioma
  //   }, options);
  // }


  // addTypeComplaint(id_sociedad:string, id_tp_denuncia:string, nombre:string, descripcion:string, tipo_receptor:string,id:string,idioma: string, id_gestor_conflicto: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTipoDenuncia.php`, {
  //     id_sociedad: id_sociedad,
  //     id_tp_denuncia: id_tp_denuncia,
  //     nombre: nombre,
  //     descripcion: descripcion,
  //     tipo_receptor: tipo_receptor,
  //     id: id,
  //     idioma: idioma,
  //     id_gestor_conflicto
  //   }, options);
  // }

  //METODO UTILIZADO EN IDIOMAS
  updateComplaintByUser(id_sociedad: string, id_tp_denuncia: string, nombre: string, descripcion: string, id_usuario: string, tipo_receptor: string, id: string, idioma: string, id_gestor_conflicto: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarTipoDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      nombre: nombre,
      descripcion: descripcion,
      id_usuario: id_usuario,
      tipo_receptor: tipo_receptor,
      id: id,
      idioma: idioma,
      id_gestor_conflicto: id_gestor_conflicto
    }, options);
  }


  // updateIdioma(id_idioma: string, n_idioma: string, comentario: string,Habilitado: string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarIdioma.php`, {
  //     id_idioma: id_idioma,
  //     n_idioma: n_idioma,
  //     comentario: comentario,
  //     Habilitado: Habilitado
  //   }, options);
  // }



  //METODO COMPARTIDO EN OTROS COMPONENTES
  searchComplaintByUserSociety(id_sociedad: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoDenuncia.php`, {
      id_sociedad: id_sociedad,
      idioma: idioma,
    }, options);
  }

  //enviar-denuncia.service
  searchComplaintByUserSocietyID(id_sociedad: string, id_tp_denuncia: string, seguro: boolean, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoDenunciabyId.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      seguro: seguro,
      idioma: idioma,
    }, options);
  }

  deleteComplaintByUser(id_sociedad: string, id_tp_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarTipoDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia
    }, options);
  }


  //Movido a medida-cautelar.service
  // addCaution(id_sociedad:string, id_mcu:string, nombre:string, comentario:string,idioma: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearMedidaCautelar.php`, {
  //     id_sociedad: id_sociedad,
  //     id_mcu: id_mcu,
  //     nombre: nombre,
  //     comentario: comentario,
  //     idioma: idioma
  //   }, options);
  // }

  //Movido a medida-cautelar.service
  // searchCautionByUserSociety(id_sociedad:string,idioma: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarMedidaCautelar.php`, {
  //     id_sociedad: id_sociedad,
  //     idioma: idioma
  //   }, options);
  // }


  searchCautionBySociety(id_sociedad: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarMedidaCautelarBySociety.php`, {
      id_sociedad: id_sociedad,
      idioma: idioma
    }, options);
  }

  //Movido a medida-cautelear.service
  // deleteCautionByUser(id_sociedad:string, id_mcu:string, idioma: string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarMedidaCautelar.php`, {
  //     id_sociedad: id_sociedad,
  //     id_mcu: id_mcu,
  //     idioma: idioma
  //   }, options);
  // }

  //Movido a medida-cautelear.service
  // updateCautionByUser(id_sociedad:string, id_mcu:string, nombre:string, comentario:string,idioma: string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarMedidaCautelar.php`, {
  //     id_sociedad: id_sociedad,
  //     id_mcu: id_mcu,
  //     nombre: nombre,
  //     comentario: comentario,
  //     idioma: idioma
  //   }, options);
  // }

  //Servicio movido a tipo-relacion.service.ts
  // addRelationType(id_sociedad:string, id_tp_relacion:string, nombre:string, comentario:string,idioma: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTipoRelacion.php`, {
  //     id_sociedad: id_sociedad,
  //     id_tp_relacion: id_tp_relacion,
  //     nombre: nombre,
  //     comentario: comentario,
  //     idioma: idioma
  //   }, options);
  // }

  //Servicio movido a tipo-relacion.service.ts
  // searchRelationTypeByUserSociety(id_sociedad:string,idioma: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoRelacion.php`, {
  //     id_sociedad: id_sociedad,
  //     idioma: idioma
  //   }, options);
  // }

  searchRelationTypeById(id_tp_relacion: string, id_sociedad: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoRelacionById.php`, {
      id_tp_relacion: id_tp_relacion,
      id_sociedad: id_sociedad,
      idioma: idioma
    }, options);
  }

  //Servicio movido a tipo-relacion.service.ts
  // deleteRelationTypeByUser(id_sociedad:string, id_tp_relacion:string,idioma: string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarTipoRelacion.php`, {
  //     id_sociedad: id_sociedad,
  //     id_tp_relacion: id_tp_relacion,
  //     idioma: idioma
  //   }, options);
  // }

  //Servicio movido a tipo-relacion.service.ts
  // updateRelationByUser(id_sociedad:string, id_tp_relacion:string, nombre:string, comentario:string,idioma: string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarTipoRelacion.php`, {
  //     id_sociedad: id_sociedad,
  //     id_tp_relacion: id_tp_relacion,
  //     nombre: nombre,
  //     comentario: comentario,
  //     idioma: idioma
  //   }, options);
  // }

  // addLayer(id_sociedad:string, id_abogado:string, id_tp_denuncia:string, n_abogado:string, comentario:string, email:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearAbogado.php`, {
  //     id_sociedad: id_sociedad,
  //     id_abogado: id_abogado,
  //     id_tp_denuncia: id_tp_denuncia,
  //     n_abogado: n_abogado,
  //     comentario: comentario,
  //     email: email,
  //   }, options);
  // }

  //METODO UTILIZADO EN IDIOMAS
  searchLayerByUserSociety(id_sociedad: string,) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarAbogado.php`, {
      id_sociedad: id_sociedad,
    }, options);
  }

  //METODO UTILIZADO EN IDIOMAS
  searchReceptorObligatoryByUserSociety(id_usuario: string, id_sociedad: string,) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarReceptorObligatorio.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,
    }, options);
  }

  searchLayerByIdSociety(id_abogado: string, id_sociedad: string,) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarAbogadoById.php`, {
      id_abogado: id_abogado,
      id_sociedad: id_sociedad,
    }, options);
  }
  // deleteLayerByUser(id_sociedad:string, id_abogado:string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarAbogado.php`, {
  //     id_sociedad: id_sociedad,
  //     id_abogado: id_abogado
  //   }, options);
  // }

  // updateLayerByUser(id_sociedad:string, id_abogado:string, id_tp_denuncia:string, n_abogado:string, comentario:string, email:string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarAbogado.php`, {
  //     id_sociedad: id_sociedad,
  //     id_abogado: id_abogado,
  //     id_tp_denuncia: id_tp_denuncia,
  //     n_abogado: n_abogado,
  //     comentario: comentario,
  //     email:email
  //   }, options);
  // }

  searchRoleByUserSociety(id_user: string, id_sociedad: string, menu: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarRolbyUserSociety.php`, {
      id_user: id_user,
      id_sociedad: id_sociedad,
      menu: menu,
    }, options);
  }

  //Servicio movido a gestion-usuarios.service.ts
  // updateRolUser(id_sociedad:string, id_user:string, id_rol:string, fec_inicio:string, fec_fin:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/actualizarRolbyUserSociety.php`, {
  //     id_user: id_user,
  //     id_sociedad: id_sociedad,
  //     id_rol: id_rol,
  //     fec_inicio: fec_inicio,
  //     fec_fin: fec_fin,
  //   }, options);
  // }

  //Servicio movido a gestion-usuarios.service.ts
  // deleteRoleByUserSociety(id_user:string, id_sociedad:string, id_rol:string, fec_inicio:string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarRolbyUserSociety.php`, {
  //     id_user: id_user,
  //     id_sociedad: id_sociedad,
  //     id_rol: id_rol,
  //     fec_inicio: fec_inicio,
  //   }, options);
  // }

  //Servicio movido a gestion-usuarios.service.ts
  // addRoleByIdUser(id_user:string, id_sociedad:string, id_rol:string, fec_inicio:string, fec_fin:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearRolbyUserSociety.php`, {
  //     id_user: id_user,
  //     id_sociedad: id_sociedad,
  //     id_rol: id_rol,
  //     fec_inicio: fec_inicio,
  //     fec_fin: fec_fin,
  //   }, options);
  // }

  //Servicio movido a gestion-usuarios.service.ts
  // searchRole() {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/getRoles.php`, {
  //   }, options);
  // }

  //obtiene las empresas por sociedad
  //Movido a enviar-denuncia.service
  searchCompanies(idsociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getCompanies.php`, {
      idsociedad: idsociedad,
    }, options);
  }

  //obtiene los centros por sociedad
  //Enviar-denuncia.service
  searchCenters(idsociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/getCenters.php`, {
      idsociedad: idsociedad,
    }, options);
  }

  // Enviar-denuncia.service
  searchCentersbyidCompany(idempresa: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/getCentersbyidCompany.php`, {
      idempresa: idempresa,
      id_sociedad: id_sociedad
    }, options);
  }

  searchCompaniesbyid(idempresa: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getCompaniesbyid.php`, {
      idempresa: idempresa,
    }, options);
  }

  // enviar-denuncia.service
  searchTypeComplaint(id_sociedad: string, seguro: boolean, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getTypeComplaint.php`, {
      id_sociedad: id_sociedad,
      seguro: seguro,
      idioma: idioma,

    }, options);
  }

  // searchTextos2(id_texto:string, seguro:boolean, idioma:string,id_sociedad:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/getTextos.php`, {
  //     id_texto: id_texto,
  //     seguro: seguro,
  //     idioma: idioma,
  //     id_sociedad:id_sociedad,
  //   }, options);
  // }


  // searchClausulas2(id_clausula:string, seguro:boolean,id_sociedad:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/getClausulas.php`, {
  //     id_clausula: id_clausula,
  //     seguro: seguro,
  //     id_sociedad:id_sociedad,
  //   }, options);
  // }

  searchComplaintBySocietyFechaIniHastaEmpresaTipoDenunciaTipoRelacionPhase(id_sociedad: string, fec_inicio: string, fec_fin: string, id_empresa: string, id_tp_denuncia: string, id_tp_relacion: string, id_fase: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasByIdSociedadFechaTiposFase.php`, {
      id_sociedad: id_sociedad,
      fec_inicio: fec_inicio,
      fec_fin: fec_fin,
      id_empresa: id_empresa,
      id_tp_denuncia: id_tp_denuncia,
      id_tp_relacion: id_tp_relacion,
      id_fase: id_fase,
      idioma: idioma
    }, options);
  }

  searchComplaintBySocietyFechaIniHastaEmpresaTipoDenunciaTipoRelacionPhaseUser(id_sociedad: string, fec_inicio: string, fec_fin: string, id_empresa: string, id_tp_denuncia: string, id_tp_relacion: string, id_fase: string, receptor: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasByIdSociedadFechaTiposFaseUsuario.php`, {
      id_sociedad: id_sociedad,
      fec_inicio: fec_inicio,
      fec_fin: fec_fin,
      id_empresa: id_empresa,
      id_tp_denuncia: id_tp_denuncia,
      id_tp_relacion: id_tp_relacion,
      id_fase: id_fase,
      receptor: receptor,
      idioma: idioma
    }, options);
  }

  //Servicio movido a al archivo Consultas.service
  // ejecutarQuery(query:string, campo_1: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/ejecutarQuery.php`, {
  //     query: query,
  //     campo_1: campo_1
  //   }, options);
  // }

  //Servicio movido a al archivo Consultas.service
  // searchTablas(){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };
  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/gettablas.php`, {

  //   }, options);
  // }

  //Servicio movido a al archivo Consultas.service
  // searchAtributos(n_tabla:string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };
  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/getatributos.php`, {
  //   n_tabla: n_tabla
  //   }, options);
  // }
  //SERVICE COMPARTIDO
  searchUsersReceptors(id_sociedad: string, id_tp_denuncia: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getUserReceptorBySociety.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      idioma: idioma,
    }, options);
  }
  //SERVICE MOVIDO A RECEPTO.SERVICE
  // searchUsersSocietyReceptors(id_sociedad:string, id_user:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/getUserReceptorByUserSociety.php`, {
  //     id_sociedad: id_sociedad,
  //     id_user: id_user
  //   }, options);
  // }
  //SERVICE COMPARTIDO
  searchUsersReceptorsRamdom(id_sociedad: string, id_tp_denuncia: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getUserReceptorRamdonBySociety.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      idioma: idioma,
    }, options);
  }

  searchTypeRelation(id_sociedad: string, seguro: boolean, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getTypeRelation.php`, {
      id_sociedad: id_sociedad,
      seguro: seguro,
      idioma: idioma
    }, options);
  }

  createComplaint(id_sociedad: string, id_centro: string, id_tp_denuncia: string, denunciante_nombre: string, denunciante_apellidos: string,
    denunciante_dni: string, denunciante_correo: string, denunciante_telefono: string,
    fecha_incidente: string, id_tp_relacion: string, denuncia_desc: string, id_fase: string, id_empresa: string, comentario: string,
    nombre_testigo: string, apellido_testigo: string, email_testigo: string, phone_testigo: string, descripcion_testigo: string,
    file_name: string, receptor: string, motivo: string, receptorinicial: string, finalizada: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      denunciante_nombre: denunciante_nombre,
      denunciante_apellidos: denunciante_apellidos,
      denunciante_dni: denunciante_dni,
      denunciante_correo: denunciante_correo,
      denunciante_telefono: denunciante_telefono,
      fecha_incidente: fecha_incidente,
      id_tp_relacion: id_tp_relacion,
      denuncia_desc: denuncia_desc,
      id_fase: id_fase,
      id_empresa: id_empresa,
      comentario: comentario,
      nombre_testigo: nombre_testigo,
      apellido_testigo: apellido_testigo,
      email_testigo: email_testigo,
      phone_testigo: phone_testigo,
      descripcion_testigo: descripcion_testigo,
      file_name: file_name,
      receptor: receptor,
      motivo: motivo,
      id_centro: id_centro,
      receptorinicial: receptorinicial,
      finalizada: finalizada
    }, options);
  }

  searchComplaintByPin(pin_code: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciaByPin.php`, {
      pin_code: pin_code,
      idioma: idioma
    }, options);
  }

  //METODO CAMBIADO A CONSULTAR-DENUNCIA.SERVICE
  // searchComplaintByPinId(pin_code:string,id_complaint:string, idioma: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciaByPinId.php`, {
  //     pin_code: pin_code,
  //     id_complaint: id_complaint,
  //     idioma: idioma
  //   }, options);
  // }

  searchComplaintBySocietyPhase(id_sociedad: string, id_fase: string, receptor: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasByIdSociedadFase.php`, {
      id_sociedad: id_sociedad,
      id_fase: id_fase,
      receptor: receptor,
      idioma: idioma
    }, options);
  }

  searchComplaintAviso(id_sociedad: string, receptor: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasAviso.php`, {
      id_sociedad: id_sociedad,
      receptor: receptor,
      idioma: idioma
    }, options);
  }

  searchComplaintByIdUser(id_sociedad: string, id_denuncia: string, receptor: string, idioma: string, isActiveCenter: string, isActiveIsPersonalizable: any) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasByIdUser.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      receptor: receptor,
      idioma: idioma,
      isActiveCenter: isActiveCenter,
      isActiveIsPersonalizable: isActiveIsPersonalizable
    }, options);
  }

  searchReceptorByIdComplaint(id_denuncia: string, seguro: boolean) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarReceptorByIdDenuncia.php`, {
      id_denuncia: id_denuncia,
      seguro: seguro,
    }, options);
  }

  //Servicio movido a tipo-relacion.service.ts
  // searchComplaintBySocietyRelation(id_sociedad:string,id_tp_relacion:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorTipoRelacionSociedad.php`, {
  //     id_sociedad: id_sociedad,
  //     id_tp_relacion: id_tp_relacion,

  //   }, options);
  // }

  //METODO UTILIZADO EN IDIOMAS
  searchComplaintBySocietyType(id_sociedad: string, id_tp_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorTipoDenunciaSociedad.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia
    }, options);
  }

  countComplaintByIdSociedad(id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorSociedad.php`, {
      id_sociedad: id_sociedad
    }, options);
  }

  //Servicio movido a gestion-usuarios.service.ts
  // searchCountComplaintBySocietyUser(id_sociedad:string,id_user:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorUsuarioSociedad.php`, {
  //     id_sociedad: id_sociedad,
  //     id_user: id_user
  //   }, options);
  // }

  searchComplaintById(id_denuncia: string, idioma: string, id_user: string, isActiveCenter: any, isActiveIdPersonalizable: any) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasById.php`, {
      id_denuncia: id_denuncia,
      idioma: idioma,
      id_user: id_user,
      isActiveCenter: isActiveCenter,
      isActiveIdPersonalizable: isActiveIdPersonalizable
    }, options);
  }

  addWitness(id_sociedad: string, id_usuario: string, id_denuncia: string, n_testigo: string, tlf_testigo: string, correo_testigo: string, comentario: string, subido_por_denunciante: string, seguro: boolean) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTestigo.php`, {
      id_sociedad: id_sociedad,
      id_usuario: id_usuario,
      id_denuncia: id_denuncia,
      n_testigo: n_testigo,
      tlf_testigo: tlf_testigo,
      correo_testigo: correo_testigo,
      comentario: comentario,
      subido_por_denunciante: subido_por_denunciante,
      seguro: seguro
    }, options);
  }

  searchWitnessByComplaint(id_denuncia: string, rol: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTestigoByIdDenuncia.php`, {
      id_denuncia: id_denuncia,
      rol: rol
    }, options);
  }

  searchMedidaPorIdDenuncia(id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarMedidaCautelar2.php`, {
      id_denuncia: id_denuncia
    }, options);
  }

  searchWitnessByUserComplaint(id_denuncia: string, id_usuario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTestigoByIdDenunciaUsuario.php`, {
      id_denuncia: id_denuncia,
      id_usuario: id_usuario
    }, options);
  }

  searchDocumentsByComplaint(id_denuncia: string, seguro: boolean, rol: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDocumentosByIdDenuncia.php`, {
      id_denuncia: id_denuncia,
      seguro: seguro,
      rol: rol
    }, options);
  }

  searchDocumentsByUserComplaint(id_denuncia: string, id_usuario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDocumentosByIdDenunciaUsuario.php`, {
      id_denuncia: id_denuncia,
      id_usuario: id_usuario
    }, options);
  }

  // searchTextos(id_texto: string, idioma: string, id_sociedad: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTextos.php`, {
  //     id_texto: id_texto,
  //     idioma: idioma,
  //     id_sociedad: id_sociedad,
  //   }, options);
  // }

  // searchClausulas(id_clausula: string,id_sociedad: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarClausulas.php`, {
  //     id_clausula: id_clausula,
  //     id_sociedad:id_sociedad,
  //   }, options);
  // }

  // searchAdvancedParam(id_sociedad:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarConfiguracionParametros.php`, {
  //     id_sociedad: id_sociedad,
  //   }, options);
  // }

  searchAdvancedParam2(id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarConfiguracionParametros2.php`, {
      id_sociedad: id_sociedad,
    }, options);
  }

  // searchSSOdParam(id_sociedad:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarSSOParametros.php`, {
  //     id_sociedad: id_sociedad,
  //   }, options);
  // }

  searchParamCliente(web: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarParametrosCliente.php`, {
      web: web
    }, options);
  }

  // updateAdvancedParam(id_sociedad: string, anonimo: string, dominio: string, consultas: string, plazo: string, clausulas: string, conflicto: string, diasinves: string, diasdecisor: string, diascompliance: string, terminos: string, doc_terminos: string, ruta: string, diasverdenuncia: string, boton1: string, boton2: string, boton3: string, triaje: string, centros: string, inputAutocomplete: string/*, docboton1: string, docboton2: string, docboton3: string*/) {
  //   const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros.php`, {
  //     id_sociedad: id_sociedad,
  //     anonimo: anonimo,
  //     dominio: dominio,
  //     consultas: consultas,
  //     plazo: plazo,
  //     clausulas: clausulas,
  //     conflicto: conflicto,
  //     diasinves: diasinves,
  //     diasdecisor: diasdecisor,
  //     diascompliance: diascompliance,
  //     terminos: terminos,
  //     doc_terminos: doc_terminos,
  //     ruta: ruta,
  //     diasverdenuncia: diasverdenuncia,
  //     boton1: boton1,
  //     boton2: boton2,
  //     boton3: boton3,
  //     triaje: triaje,
  //     centros: centros,
  //     inputAutocomplete: inputAutocomplete
  //     /*,
  //     docboton1: boton1,
  //     docboton2: boton2,
  //     docboton3: boton3 */
  //   }, options);
  // }

  // updateAdvancedParam2(id_sociedad:string, nremitente:string, remitente:string, srv_mail:string, puerto:string, usr_mail:string, pass_mail:string, ssl:string, auth_mail:string) {
  //     const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //     let options = { headers: headers };

  //     return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros2.php`, {
  //       id_sociedad:id_sociedad,
  //       nremitente: nremitente,
  //       remitente: remitente,
  //       srv_mail: srv_mail,
  //       puerto: puerto,
  //       usr_mail: usr_mail,
  //       pass_mail: pass_mail,
  //       ssl: ssl,
  //       auth_mail: auth_mail,
  //     }, options);
  //   }

  // updateAdvancedParam4(id_sociedad:string,multi:string, tieneSSO: string, archivardenuncia: string, idPersonalizableEnabled: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros4.php`, {
  //     id_sociedad: id_sociedad,
  //     multi: multi,
  //     tieneSSO: tieneSSO ,
  //     archivardenuncia
  //      idPersonalizableEnabled
  //   }, options);
  // }

  // updateSSOParam(id_sociedad:string,saml_enabled:string,  saml_idp_entity_id:string,  saml_idp_sso_url:string,  saml_idp_slo:string,  saml_idp_cert:string,  saml_idp_cert2:string,  saml_mapping_cn:string,  saml_mapping_sn:string,  saml_mapping_email:string,  saml_mapping_phone:string,  saml_mapping_national_id:string,  saml_jit:string,  saml_slo:string,  saml_force_login:string,  saml_debug:string,  saml_sp_entity_id:string,  saml_sp_pk:string,  saml_sp_cert:string,  saml_sp_nameid_format:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionSSO.php`, {
  //     id_sociedad:id_sociedad,
  //     saml_enabled: saml_enabled,
  //     saml_idp_entity_id: saml_idp_entity_id,
  //     saml_idp_sso_url: saml_idp_sso_url,
  //     saml_idp_slo: saml_idp_slo,
  //     saml_idp_cert: saml_idp_cert,
  //     saml_idp_cert2: saml_idp_cert2,
  //     saml_mapping_cn: saml_mapping_cn,
  //     saml_mapping_sn: saml_mapping_sn,
  //     saml_mapping_email: saml_mapping_email,
  //     saml_mapping_phone: saml_mapping_phone,
  //     saml_mapping_national_id: saml_mapping_national_id,
  //     saml_jit: saml_jit,
  //     saml_slo: saml_slo,
  //     saml_force_login: saml_force_login,
  //     saml_debug: saml_debug,
  //     saml_sp_entity_id: saml_sp_entity_id,
  //     saml_sp_pk: saml_sp_pk,
  //     saml_sp_cert: saml_sp_cert,
  //     saml_sp_nameid_format: saml_sp_nameid_format,
  //   }, options);
  // }

  // updateTextos(id_texto:string, texto:string, idioma:string,id_sociedad:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTextos.php`, {
  //     id_texto: id_texto,
  //     texto: texto,
  //     idioma: idioma,
  //     id_sociedad: id_sociedad,
  //   }, options);
  // }


  // updateClausulas(id_clausula:string, clausula:string,id_sociedad:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearClausulas.php`, {
  //     id_clausula: id_clausula,
  //     clausula: clausula,
  //     id_sociedad:id_sociedad,
  //   }, options);
  // }



  // generarFicheroTextos(idioma:string,id_sociedad:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/generarFicheroTextos.php`, {
  //     idioma: idioma,
  //     id_sociedad:id_sociedad,
  //   }, options);
  // }

  // updateAdvancedParam3(id_sociedad:string, colorprincipal:string, colorsecundario:string, colorbgseccion2:string, colorbgseccion3:string, colortxtbtn:string, colortxtseccion1:string, colortxtseccion2:string, colortxtseccion3:string, colortxttitulo:string, colorbgseccion1:string, colormenulateral:string, colortxtcabeceras:string, colortxtbotones:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros3.php`, {
  //     id_sociedad:id_sociedad,
  //     colorprincipal: colorprincipal,
  //     colorsecundario: colorsecundario,
  //     colorbgseccion2: colorbgseccion2,
  //     colorbgseccion3: colorbgseccion3,
  //     colortxtbtn: colortxtbtn,
  //     colortxtseccion1: colortxtseccion1,
  //     colortxtseccion2: colortxtseccion2,
  //     colortxtseccion3: colortxtseccion3,
  //     colortxttitulo: colortxttitulo,
  //     colorbgseccion1: colorbgseccion1,
  //     colormenulateral: colormenulateral,
  //     colortxtcabeceras: colortxtcabeceras,
  //     colortxtbotones: colortxtbotones,
  //   }, options);
  // }

  // enviar-denuncia.service
  // searchCanal() {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarCanal.php`, {
  //   }, options);
  // }


  sendMessageChat(id_sociedad: string, id_denuncia: string, id_user: string, comentario: string, fecha_comentario: string, id_usuario: string, seguro: boolean) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/enviarMensajeChat.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      id_user: id_user,
      comentario: comentario,
      fecha_comentario: fecha_comentario,
      id_usuario: id_usuario,
      seguro: seguro,
    }, options);
  }

  searchCommentsByComplaint(id_denuncia: string, seguro: boolean) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarComentariosByDenuncia.php`, {
      id_denuncia: id_denuncia,
      seguro: seguro,
    }, options);
  }

  buscarUsuarioDenuncia(id_denuncia: string, seguro: boolean) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuarioByDenuncia.php`, {
      id_denuncia: id_denuncia,
      seguro: seguro,
    }, options);
  }

  searchHistoricoUsuariosBySociedadFase(id_sociedad: string, id_fase: string, id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuariosByIdSociedadFaseDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_fase: id_fase,
      id_denuncia: id_denuncia,
    }, options);
  }

  updateComplaintById(id_denuncia: string, id_fase: string, id_sociedad: string, informe_denunciante: string, modulo: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarDenunciaById.php`, {
      id_denuncia: id_denuncia,
      id_fase: id_fase,
      id_sociedad: id_sociedad,
      informe_denunciante: informe_denunciante,
      modulo: modulo
    }, options);
  }

  /**
   *INSERT THE USER COMPLIANCE BY COMPLAINT
   * @param id_denuncia
   * @param id_usuario_comp
   * @param id_usuario_comp_rechazado
   * @returns
   */
  insertUserComplianceByComplaint(id_denuncia: string, id_sociedad: string, id_usuario_comp: string, id_usuario_comp_rechazado: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() };
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/Denuncias/insertUserComplianceByComplaint.php`, {
      id_denuncia: id_denuncia,
      id_sociedad: id_sociedad,
      id_usuario_comp: id_usuario_comp,
      id_usuario_comp_rechazado: id_usuario_comp_rechazado
    }, options);
  }


  updateParamComplaintById(id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarCampoDenunciaByIdDenuncia.php`, {
      id_denuncia: id_denuncia,
    }, options);
  }

  createMCUByIdComplaint(id_sociedad: string, id_mcu: string, id_denuncia: string, nombre_mcu: string, descripcion: string, contacto_ejecutor: string, realizado: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearMedidaCautelarByIdDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_mcu: id_mcu,
      id_denuncia: id_denuncia,
      nombre_mcu: nombre_mcu,
      descripcion: descripcion,
      contacto_ejecutor: contacto_ejecutor,
      realizado: realizado
    }, options);
  }


  createDeadlinesByIdComplaint(id_sociedad: string, id_denuncia: string, plazo_investigacion: string, plazo_decisor: string, plazo_compliance: string, plazo_recepcion: string, id_usuario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearPlazosDenunciaById.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      plazo_investigacion: plazo_investigacion,
      plazo_decisor: plazo_decisor,
      plazo_compliance: plazo_compliance,
      plazo_recepcion: plazo_recepcion,
      id_usuario: id_usuario
    }, options);
  }

  updateDeadlinesByIdComplaint(id_sociedad: string, id_denuncia: string, plazo_investigacion: string, plazo_decisor: string, plazo_compliance: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/updatePlazosDenunciaById.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      plazo_investigacion: plazo_investigacion,
      plazo_decisor: plazo_decisor,
      plazo_compliance: plazo_compliance,
    }, options);
  }

  createHistoryComplaintById(id_denuncia: string, id_fase: string, id_sociedad: string, id_usuario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearHistoricoByIdDenuncia.php`, {
      id_denuncia: id_denuncia,
      id_fase: id_fase,
      id_sociedad: id_sociedad,
      id_usuario: id_usuario,
    }, options);
  }

  //Movido a dashboard.service
  // searchComplaintsByPhaseDashboard(id_sociedad:string, fec_inicio:string, fec_fin:string, id_empresa:string, id_tp_denuncia:string, id_tp_relacion:string, id_fase:string, receptor:string,idioma: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorFaseDashboard.php`, {
  //     id_sociedad: id_sociedad,
  //     fec_inicio: fec_inicio,
  //     fec_fin: fec_fin,
  //     id_empresa: id_empresa,
  //     id_tp_denuncia: id_tp_denuncia,
  //     id_tp_relacion: id_tp_relacion,
  //     id_fase: id_fase,
  //     receptor: receptor,
  //     idioma:idioma
  //   }, options);
  // }

  //Movido a dashboard.service
  // searchComplaintsByTypeDashboard(id_sociedad:string, fec_inicio:string, fec_fin:string, id_empresa:string, id_tp_denuncia:string, id_tp_relacion:string, id_fase:string, receptor:string,idioma: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorTipoDashboard.php`, {
  //     id_sociedad: id_sociedad,
  //     fec_inicio: fec_inicio,
  //     fec_fin: fec_fin,
  //     id_empresa: id_empresa,
  //     id_tp_denuncia: id_tp_denuncia,
  //     id_tp_relacion: id_tp_relacion,
  //     id_fase: id_fase,
  //     receptor: receptor,
  //     idioma: idioma
  //   }, options);
  // }

  disableUserByIdComplaint(id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/deshabilitarUsuarioByIdDenuncia.php`, {
      id_denuncia: id_denuncia
    }, options);
  }

  enableUserByIdComplaint(id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/habilitarUsuarioByIdDenuncia.php`, {
      id_denuncia: id_denuncia
    }, options);
  }

  createUserByIdComplaint(id_sociedad: string, id_user: string, id_denuncia: string, id_rol: string, habilitado: string, id_fase: string, modal: boolean) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearUsuarioByDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_user: id_user,
      id_denuncia: id_denuncia,
      id_rol: id_rol,
      habilitado: habilitado,
      id_fase: id_fase,
      modal: modal
    }, options);
  }

  createActionComplaint(id_sociedad: string, id_denuncia: string, nombre_accion: string, responsable: string, contacto: string, descripcion: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearAccionDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      nombre_accion: nombre_accion,
      responsable: responsable,
      contacto: contacto,
      descripcion: descripcion
    }, options);
  }

  searchInvestigatorByIdDenuncia(id_denuncia: string, id_fase: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuarioInvestigadorByDenuncia.php`, {
      id_denuncia: id_denuncia,
      id_fase: id_fase
    }, options);
  }
  searchPlazosByIdDenuncia(id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/Denuncias/searchPlazosByIdDenuncia.php`, {
      id_denuncia: id_denuncia
    }, options);
  }

  searchReceptorByIdDenuncia(id_denuncia: string, id_fase: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuarioReceptorByDenuncia.php`, {
      id_denuncia: id_denuncia,
      id_fase: id_fase
    }, options);
  }
  sendReportByIdDenuncia(id_denuncia: string, informe: string, estado: string, id_usuario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/enviarInformeByDenuncia.php`, {
      id_denuncia: id_denuncia,
      informe: informe,
      estado: estado,
      id_usuario: id_usuario
    }, options);
  }

  searchActionsByComplaint(id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarAccionesByDenuncia.php`, {
      id_denuncia: id_denuncia
    }, options);
  }

  updateActionsById(id_denuncia: string, id_accion: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/actualizarAccionById.php`, {
      id_denuncia: id_denuncia,
      id_accion: id_accion
    }, options);
  }

  verArchivo(id_archivo): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth.getToken()
    });
    let options = {
      headers: headers
    };
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/verArchivo.php`, { id_archivo }, options);
  }

  countActionsByIdComplaint(id_sociedad: string, id_denuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarAccionesSinFinalizarByIdDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia
    }, options);
  }

  // countComplaintByIdCompany(id_sociedad:string,id_empresa:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarDenunciasPorEmpresas.php`, {
  //     id_sociedad: id_sociedad,
  //     id_empresa: id_empresa
  //   }, options);
  // }

  // countComplaintByIdCenter(id_sociedad:string,id_centro:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/contarDenunciasPorCentros.php`, {
  //     id_sociedad: id_sociedad,
  //     id_centro: id_centro
  //   }, options);
  // }

  countInvesSelect(id_sociedad: string, id_denuncia: string, id_usuario: string, rol: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarInvestigadoresSeleccionados.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      id_usuario: id_usuario,
      rol: rol
    }, options);
  }

  // countComplaintByIdAbogados(id_sociedad:string,id_abogado:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarDenunciasPorAbogados.php`, {
  //     id_sociedad: id_sociedad,
  //     id_abogado: id_abogado
  //   }, options);
  // }
  //Movido a medida-cautelar.service
  // counMedidaCautelarByIdCompany(id_sociedad:string,id_mcu:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarMedidaCautelarById.php`, {
  //     id_sociedad: id_sociedad,
  //     id_mcu: id_mcu
  //   }, options);
  // }


  getPhases(idioma: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getPhases.php`, {
      idioma: idioma,
      id_sociedad: id_sociedad
    }, options);
  }

  //EDIT COMPLAINT TYPE COMPONENT
  updateComplainType(id_sociedad: string, id_tp_denuncia: string, id_denuncia: string, id_user: string, lenguaje: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/Denuncias/editComplaintType.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      id_tp_denuncia: id_tp_denuncia,
      lenguaje: lenguaje,
      id_user: id_user,
    }, options);
  }

  //EDIT COMPLAINT TYPE COMPONENT
  searchUserByComplaintType(id_role: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/Denuncias/getUserByComplaintType.php`, {
      id_role: id_role,
      id_sociedad: id_sociedad
    }, options);
  }

  //CHANGE PHASE COMPLAINT COMPONENT
  changeComplaintPhase(id_sociedad: string, id_role: string, id_usuario: string, id_denuncia: string, fase_actual: string, id_usuario_asignado: string, fase_retroceder: string, comentario: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/Denuncias/returnComplaintPhase.php`, {
      id_role: id_role,
      id_sociedad: id_sociedad,
      id_usuario: id_usuario,
      id_denuncia: id_denuncia,
      fase_actual: fase_actual,
      id_usuario_asignado: id_usuario_asignado,
      fase_retroceder: fase_retroceder,
      comentario: comentario
    }, options);
  }

  //CHAGE PHASE COMPLAINT COMPONENT
  showCommentaryPhaseChange(id_denuncia: string, id_sociedad: string, id_fase: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/api/Denuncias/searchCommentaryByIdPhase.php`, {
      id_denuncia: id_denuncia,
      id_sociedad: id_sociedad,
      id_fase: id_fase
    }, options);
  }

  //CHANGE PHASE COMPLAINT  COMPONENT
  FindUsersByReportID(id_denuncia: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/Denuncias/complaintDataByRole.php`, {
      id_denuncia: id_denuncia,
      id_sociedad: id_sociedad
    }, options);
  }

  /**
  * Retrieves all appeals associated with a specific complaint ID.
  * @param id_denuncia - The ID of the complaint to search appeals for.
  * @returns An Observable of `Apelaciones` containing the appeals data.
  */
  getAppealsByComplaint(id_denuncia: string): Observable<Apelaciones> {
    const headers = { Authorization: 'Bearer ' + this.auth.getToken() };
    let options = { headers };

    return this.httpClient.post<Apelaciones>(
      `${this.PHP_API_SERVER}/api/getAppealsByComplaint.php`,
      { id_denuncia },
      options
    );
  }

  /**
  * Retrieves the current (active) appeal for a specific complaint ID.
  * @param id_denuncia - The ID of the complaint to get the current appeal.
  * @returns An Observable of `DatosApelacion` containing the current appeal data.
  */
  getCurrentAppealByComplaint(id_denuncia: string): Observable<DatosApelacion> {
    const headers = { Authorization: 'Bearer ' + this.auth.getToken() };
    let options = { headers };

    return this.httpClient.post<DatosApelacion>(
      `${this.PHP_API_SERVER}/api/getCurrentAppeal.php`,
      { id_denuncia },
      options
    );
  }

  // //SEARCH CENTERS BY USER
  // searchCenterByUser(id_sociedad:string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/buscarCentroUsuario.php`, {
  //     id_sociedad: id_sociedad,
  //   },  options);
  // }

  // //UPDATE CENTER BY USER
  // updateCenterByUser(id_sociedad:string, id_centro:string, n_centro:string, id_empresa: string, comentarios:string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/modificarCentro.php`, {
  //     id_sociedad: id_sociedad,
  //     id_centro: id_centro,
  //     n_centro: n_centro,
  //     id_empresa: id_empresa,
  //     comentarios: comentarios
  //   },  options);
  // }

  //SEARCH PHASE BY USER

  //Movido a fase.service
  // searchPhaseByUser(id_sociedad:string, idioma: string) {
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/Fases/searchPhaseByUser.php`, {
  //     id_sociedad: id_sociedad,
  //     idioma: idioma
  //   },  options);
  // }

  //UPDATE PHASE BY USER

  //Movido a fase.service
  // updatePhaseByUser(id_sociedad:string, id_fase:string, n_fase:string, idioma: string, comentario:string){
  //   const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
  //   let options = { headers: headers };

  //   return this.httpClient.post(`${this.PHP_API_SERVER}/api/Fases/updatePhase.php`, {
  //     id_sociedad: id_sociedad,
  //     id_fase: id_fase,
  //     n_fase: n_fase,
  //     idioma: idioma,
  //     comentario: comentario
  //   },  options);
  // }
}
