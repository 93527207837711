import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { IdPersonalizable } from 'src/app/dashboard/models/idPersonalizable';
import { Parametros } from 'src/app/dashboard/models/parametros';
import { ConfiguracionAvanzadaService } from 'src/app/dashboard/services/configuracion-avanzada.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-id-personalizable',
  templateUrl: './id-personalizable.component.html',
  styleUrls: ['./id-personalizable.component.css']
})
export class IdPersonalizableComponent implements OnInit {

  idPersonalizable: IdPersonalizable = {
    identifier_characters: '',
    include_characters: '',
    identifier_year: '',
    include_year: '',
    personalized_identifier: '',
    reset_numbering: ''

  };

  parametros: Parametros;

  validatingForm: FormGroup;

  id_personalizable_enabled: boolean = false;
  include_charactersF: boolean = false;
  identifier_charactersF: string = '';
  include_yearF: boolean = false;
  identifier_yearF: string = '';
  reset_numberingF: boolean = false;
  currentYear: number = new Date().getFullYear();
  checkResetDisabled: boolean = false;
  showAdvOpts: boolean = false;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private configuracionAvanzadaService: ConfiguracionAvanzadaService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    // Initialize the form group with controls and their validators.
    this.validatingForm = this.fb.group({
      include_characters: [false],
      identifier_characters: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[A-Za-z]*$/), Validators.maxLength(7)]],
      include_year: [false],
      identifier_year: [{ value: '', disabled: true }],
      include_numbers: [true],
      reset_numbering: [false, this.resetNumberingValidator.bind(this)]

    });
  }

  /**
   * Fetches initial parameter values from the backend and populates the component data.
   */
  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if (language === '' || language === null) {
      language = 'es';
    }

    this.showAdvOpts = this.auth.getShowAdvOpts();

    this.validatingForm.get('include_numbers').disable();

    // Fetch advanced parameters and set the component's data.
    this.configuracionAvanzadaService
      .searchAdvancedParam(this.auth.getSociedad())
      .subscribe((parametros: Parametros) => {
        this.parametros = parametros;
        this.identifier_charactersF = this.parametros[70].valor;
        this.identifier_yearF = this.currentYear.toString();

        if (this.parametros[68].valor == '1') {
          this.id_personalizable_enabled = true;
        } else {
          this.id_personalizable_enabled = false;
        }

        if (this.parametros[69].valor == '1') {
          this.include_charactersF = true;
        } else {
          this.include_charactersF = false;
        }
        if (this.parametros[71].valor == '1') {
          this.include_yearF = true;

        } else {
          this.include_yearF = false;
        }

        if (this.parametros[73].valor == '1') {
          this.reset_numberingF = true;
        } else {
          this.reset_numberingF = false;
        }

        // Initialize the state of the inputs according to the initial state of the checkboxes.
        this.toggleInput('include_characters', this.include_charactersF, 'identifier_characters');
        this.toggleInput('include_year', this.include_yearF, 'identifier_year');

        // Disable checks
        this.disableChecks();

        this.disableResetNumbering();
      });
  }

  /**
   * Disable checks when check reset numbering is true, 
   * this way is required add characters and year.
   */
  disableChecks() {
    if (this.reset_numberingF === true) {
      this.include_yearF = true;
      this.validatingForm.get('include_year').disable();
      this.toggleInput('include_year', this.include_yearF, 'identifier_year');
      this.updateIdentifier();
    } else {
      this.reset_numberingF = false;
      this.validatingForm.get('include_year').enable();
    }

    if (this.checkResetDisabled == true && this.validatingForm.value.reset_numbering == false) {
      this.toastr.error(
        this.translate.instant('texto_291'),
        this.translate.instant('texto_763')
      );
    }
    // changes review
    this.cdRef.detectChanges();
  }

  resetNumberingValidator(control: AbstractControl): ValidationErrors | null {
    if (this.checkResetDisabled && control.value === false) {
      return { resetNotAllowed: false };
    }
    return null;
  }

  /**
   * Toggles the enabled/disabled state of a form control based on the state of a related checkbox.
   * Clears the input field and updates the identifier if the checkbox is unchecked.
   * @param includeControlName - The name of the checkbox form control.
   * @param identifierControlName - The name of the input form control to toggle.
   */
  toggleInput(includeControlName: string, includeModel: boolean, identifierControlName: string) {
    const includeControl = this.validatingForm.get(includeControlName);
    const identifierControl = this.validatingForm.get(identifierControlName);

    if (includeControl?.value || includeModel) {
      identifierControl?.enable();
      if (identifierControlName === 'identifier_year' && !identifierControl?.value) {
        identifierControl?.setValue(this.currentYear.toString()); // Set default value when enabled
        this.updateIdentifier();
      }
    } else {
      identifierControl?.disable();
      identifierControl?.setValue('');
      this.updateIdentifier();
    }
  }

  /**
   * Updates the personalized identifier based on the current form values.
   * Joins the input values with an underscore if they are included.
   */
  updateIdentifier() {
    const formValues = this.validatingForm.value;
    const parts: string[] = [];

    if (formValues.include_characters && formValues.identifier_characters) {
      parts.push(formValues.identifier_characters);
    } else if(this.include_charactersF){
      parts.push(this.identifier_charactersF);
    }
    if (formValues.include_year && formValues.identifier_year || this.include_yearF) {
      parts.push(formValues.identifier_year);
    }
    parts.push('0001');


    this.idPersonalizable.personalized_identifier = parts.join('-');
  }


  /**
   * Validate if disable reset numberig is available 
   * If there are complaints in the current year, that option won't be possible.
   */
  disableResetNumbering() {
    this.configuracionAvanzadaService.searchComplaintsByYear().subscribe(res => {
      if (res.areThereComplaints == 1 && this.reset_numberingF == true) {
        this.checkResetDisabled = true;
      } else {
        this.checkResetDisabled = false;
      }
    }, error => {
      console.error("Error al llamar al endpoint searchComplaintsByYear", error);
    });
  }

  /**
   * Submits the form data to the backend service to update the identifier parameters.
   * Shows a success or error message based on the response.
   */
  submitIdentifier() {

    if (this.validatingForm.value.include_characters == true) {
      this.idPersonalizable.include_characters = '1';
    } else {
      this.idPersonalizable.include_characters = '0';
    }

    if (this.validatingForm.value.include_year == true || this.include_yearF == true) {
      this.idPersonalizable.include_year = '1';
    } else {
      this.idPersonalizable.include_year = '0';
    }

    if (this.validatingForm.value.reset_numbering == true) {
      this.idPersonalizable.reset_numbering = '1';
    } else {
      this.idPersonalizable.reset_numbering = '0';
    }

    this.configuracionAvanzadaService.updateIdentifierParams(
      this.auth.getSociedad(),
      this.idPersonalizable.include_characters,
      this.validatingForm.value.identifier_characters,
      this.idPersonalizable.include_year,
      this.currentYear.toString(),
      this.idPersonalizable.reset_numbering
    ).subscribe(res => {
      if (res == 200) {
        this.toastr.success(
          this.translate.instant('texto_290'),
          this.translate.instant('texto_292')
        );
      } else {
        this.toastr.error(
          this.translate.instant('texto_291'),
          this.translate.instant('texto_292')
        );
      }
      this.router
        .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(['/dashboard/layout/advanced-setting']);
        });
    });
  }
}
