<section style="position: absolute;width: 100%;z-index: 1;" class="image-container line-spacing"
  style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
  <div class="container" style="padding: 0;">
    <div class="board">
      <button type="button" style="z-index:100;float: right;" class="btn btn-dark" (click)="hide();"
        [attr.aria-label]="'texto_735' | translate">X</button>

      <!--Breadcrumbs-->
      <div class="breadcrum-div">
        <nav aria-label="breadcrumb" class="nav">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/inicio" [innerHTML]="'texto_1' | translate | safe:'html'"></a></li>
            <li class="breadcrumb-item active" aria-current="page" [innerHTML]="'texto_717' | translate | safe:'html'">
            </li>
          </ol>
        </nav>
      </div>
      <!--End Breadcrumbs-->
      <!-- Navigation Area (Circular Tabs) -->
      <msw-navbar></msw-navbar>
      <!-- End Navigation Area (Circular Tabs) -->

      <!-- Content Area -->
      <div class="tab-content">
        <form #denuncianteForm="ngForm" class="editForm" novalidate>
          <div class="tab-pane fade in active">
            <h2 class="head text-center">{{title | translate }}</h2>
            <label class="control-label" [hidden]="IsAnonimo" style="text-align: center;"><strong>{{ 'texto_144' |
                translate }}.</strong></label>
            <!--Para poder acceder a los datos......-->
            <label class="control-label" style="text-align: center;"
              [innerHTML]="'texto_145' | translate | safe:'html'">
              <br>
            </label>
            <br>
            <!--Guárdelo en un lugar se......-->
            <label class="control-label" style="text-transform: uppercase; text-align: center"
              [innerHTML]="'texto_146' | translate | safe:'html'">
            </label>
            <div class="container">
              <div class="row new-box">
                <div class="col m-5">
                  <p class="text-description">
                    {{ 'texto_362' | translate }}: <strong>{{ IdComplaintShow }}</strong>&nbsp;&nbsp;&nbsp;
                    <button [cdkCopyToClipboard]="IdComplaintShow" type="button" rel="tooltip" title="Copy to Clipboard"
                      class="copy-button" style="background: none; color: black; border: none;"
                      (cdkCopyToClipboardCopied)="onCopied()">
                      <i class="material-icons" style="margin-top: 1px;">content_copy</i>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div class="container">
              <button routerLink="/ver-denuncia/consultar-denuncia" mdbBtn rounded="true" class="btn-next"
                mdbWavesEffect>{{ 'texto_147' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>