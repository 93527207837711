<!-- Botón para abrir el modal -->
<button style="background-image: url(/../../assets/img/botones/Boton_Subir\ Archivo.png);"
  class="btn btn-finish btn-wd btn-cabecera" matTooltip="{{'texto_519' | translate }}" type="button" mdbBtn
  rounded="true" data-toggle="modal" data-target="#basicExample" (click)="frame.show()" mdbWavesEffect>
</button>

<!-- Modal -->
<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Contenido-->
    <div class="modal-content">

      <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
        <div class="card-header card-header-info">
          <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_455' | translate }}</h5>
        </div>
        <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark"
          data-dismiss="modal" (click)="frame.hide()" aria-label="Cerrar modal">X</button>
        <p>{{ 'texto_456' | translate }}</p>

        <div class="md-form">
          <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
          <label class="control-label" for="textoInformeDenunciante">{{ 'texto_457' | translate }}</label><br>
          <ngx-wig id="textoInformeDenunciante" [(ngModel)]="textoInforme" [buttons]="'bold, italic, link, underline'"
            (keydown)="onKeyDown($event)" (paste)="onPaste($event)" (ngModelChange)="onModelChange()">
          </ngx-wig>
          <span style="float: left;font-weight: bold;font-size:12px">{{charCount}} {{ 'texto_458' | translate }}
            4000</span>
        </div>

        <!-- Sección de subida de archivos añadida -->
        <br>
        <!-- <div class="md-form mb-3">
          <form [formGroup]="myForm" style="width:100%; display: contents; color:white; margin-top: 20px;">
            <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="dragFile($event)">
              <input [(ngModel)]="archivos" formControlName="file"
                accept=".eml,.msg,.pptx,.pptm,.potx,.potm,.ppam,.ppsx,.ppsm,.xlsx,.xlsm,.xltx,.xltm,.xlam,.gif,.jpeg,.jpg,.png,video/*,audio/*,.pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                id="file" hidden type="file" #fileInput (change)="uploadFile($event)">
            </div>
            <div class="files-list" *ngFor="let file of files; let i = index">
              <p>{{ file }}</p>
              <button class="delete-file" (click)="deleteAttachment(i)" type="button">
                <img src="../../assets/img/borrar-fichero.png" alt="Eliminar archivo">
              </button>
            </div>
          </form>
        </div> -->
        <!-- Fin de la sección de subida de archivos -->

      </div>
      <div class="text-center" style="margin-top:-20px;">
        <button (click)="enviarInforme()" style="color:#fff" mdbBtn rounded="true" class="btn-primary mt-1 waves-light"
          mdbWavesEffect>
          {{ 'texto_459' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>