<!-- Saltar al contenido principal -->
<p>
  <a href="#mainContent" id="skip-link" class='admin-bar skip-link' href="javascript:void(0);"
    (click)="navigateToButton()">{{ 'texto_743'
    |translate }}</a>
</p>
<!-- End Saltar al contenido principal -->

<app-header></app-header>
<section id="mainContent" role="main" tabindex="-1">
  <div class="container">
    <div class="close">
      <a href="/inicio" class="close-btn" [attr.aria-label]="'texto_735' | translate"><i
          class="bi bi-x-square-fill"></i></a>
    </div>

    <!-- Breadcums consultar denuncia -->
    <div class="breadcrum-div" *ngIf="!showRecoveryFields">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/inicio" [innerHTML]="'texto_1' | translate | safe:'html'"></a></li>
          <li class="breadcrumb-item active" aria-current="page" [innerHTML]="'texto_147' | translate | safe:'html'"></li>
        </ol>
      </nav>
    </div>
    <!-- End Breadcums consultar denuncia -->

    <!-- Breadcums recuperar contraseña -->
    <div class="breadcrum-div" *ngIf="showRecoveryFields">
      <nav aria-label="breadcrumb" class="nav">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/inicio" [innerHTML]="'texto_1' | translate | safe:'html'"></a></li>
          <li class="breadcrumb-item"><a (click)="backConsultComplaint()" [innerHTML]="'texto_147' | translate | safe:'html'"></a></li>
          <li class="breadcrumb-item active" aria-current="page" [innerHTML]="'texto_767' | translate | safe:'html'"></li>
        </ol>
      </nav>
    </div>
    <!-- End Breadcums recuperar contraseña -->
    <div class="left-side">
      <div class="col-md-10 col-sm-12 px-0 mx-0">
        <!-- welcome message -->
        <div class="welcome-message">
          <h1>
            <div>
              <span style="color:var(--color-txt-titulo);font-weight: bold; font-size: 2rem;" [innerHTML]="'texto_5' | translate | safe:'html'"></span>
            </div>
            <div>
              <span style="color:var(--color-txt-titulo);font-weight: bold; font-size: 4rem" [innerHTML]="'texto_6' | translate | safe:'html'"></span>
            </div>
          </h1>
        </div>
        <!-- End welcome message -->
      </div>
    </div>
    <div class="right-side">
      <div class="form-container">
        <img class="logo" src="assets/img/logos/logo.png"
          alt="{{ 'texto_294' | translate }} {{ 'texto_6' | translate }}">

        <!-- Campo de Recuperación de Contraseña -->
        <div *ngIf="showRecoveryFields">
          <p class="description-form recordatorio" [innerHTML]="'texto_790' | translate | safe:'html'"></p>
          <div class="md-form mb-4">
            <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
            <label class="label-form" for="recovery-email" [innerHTML]="'texto_82' | translate | safe:'html'"></label>
            <input type="email" autocomplete="email" id="recovery-email" [formControl]="recoveryEmailFormControl"
              class="form-control" mdbInput mdbValidate aria-describedby="recovery-email-error"
              [attr.aria-invalid]="recoveryEmailFormControl.invalid && (recoveryEmailFormControl.dirty || recoveryEmailFormControl.touched) ? 'true' : 'false'">
            <div [hidden]="!loginNoUser" class="alert alert-danger" role="alert" style="text-align: center !important;">
              <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_774' | translate }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-center btn-padding">
            <button (click)="verifyEmailPasswordComplaint()" [disabled]="recoveryEmailFormControl.invalid" class="btn"
              mdbWavesEffect [innerHTML]="'texto_778' | translate | safe:'html'"></button>
          </div>
        </div>

        <div *ngIf="showConsultComplaint">
          <p class="description-form" [innerHTML]="'texto_361' | translate | safe:'html'"></p>
          <div class="md-form mb-4">
            <mdb-icon fas icon="id-badge" class="prefix grey-text"></mdb-icon>
            <label class="label-form" for="formId">* <span [innerHTML]="'texto_362' | translate | safe:'html'"></span>:</label>
            <input type="text" id="formId" class="form-control" [formControl]="formId" mdbInput mdbValidate
              aria-describedby="formId-error"
              [attr.aria-invalid]="formId.invalid && (formId.dirty || formId.touched) ? 'true' : 'false'">
            <mdb-error id="formId-error" role="alert" *ngIf="formId.invalid && (formId.dirty || formId.touched)">
              {{ 'texto_61' | translate }}
            </mdb-error>
            <mdb-success *ngIf="formId.valid && (formId.dirty || formId.touched)">
              {{ 'texto_62' | translate }}
            </mdb-success>
          </div>

          <div class="md-form mb-3">
            <mdb-icon fas icon="lock" class="prefix grey-text"></mdb-icon>
            <label class="label-form" for="formPin">* <span [innerHTML]="'texto_387' | translate | safe:'html'"></span>:</label>
            <input type="password" id="formPin" class="form-control input-form" [formControl]="formPin" mdbInput
              mdbValidate aria-describedby="formPin-error"
              [attr.aria-invalid]="formPin.invalid && (formPin.dirty || formPin.touched) ? 'true' : 'false'">
            <mdb-error id="formPin-error" role="alert" *ngIf="formPin.invalid && (formPin.dirty || formPin.touched)">
              {{ 'texto_61' | translate }}
            </mdb-error>
            <mdb-success *ngIf="formPin.valid && (formPin.dirty || formPin.touched)">
              {{ 'texto_62' | translate }}
            </mdb-success>
          </div>

          <div class="d-flex justify-content-center">
            <button (click)="enviar()" mdbBtn rounded="true" class="btn" mdbWavesEffect [innerHTML]="'texto_41' | translate | safe:'html'"></button>
          </div>
          <!-- Enlace de Recuperar Contraseña -->
          <div class="d-flex justify-content-center btn-padding" style="margin-top: 1rem;">
            <a (click)="showRecovery()" class="recuperar-contraseña" [innerHTML]="'texto_767' | translate | safe:'html'">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer [isFixed]="true"></app-footer>