import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Nota } from '../../models/nota';
import { NotaService } from '../../services/nota.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-managers-notes',
  templateUrl: './managers-notes.component.html',
  styleUrls: ['./managers-notes.component.css']
})
export class ManagersNotesComponent implements OnInit {
  @Input() denuncia: DatosDenuncia;
  noteForm: FormGroup;
  notes: Nota[] = [];
  idComplaint: string;
  editingIndex: number | null = null;
  currentUserId: string;
  charCount: number = 0;

  constructor(
    private noteService: NotaService,
    private toastr: ToastrService,
    private auth: AuthService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    // Set the default language based on user preferences.
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    // Initialize the form with validation rules.
    this.noteForm = new FormGroup({
      descripcion: new FormControl('', [
        Validators.required,
        Validators.maxLength(325)
      ]),
      visible: new FormControl(false)
    });

    // Subscribe to value changes to always keep the character count updated.
    this.updateCharCount();

    // Retrieve the current user's ID and load notes associated with the complaint.
    this.currentUserId = localStorage.getItem('id_user_session') || '';
    this.loadNotes();
    const tabs = window.location.href.split('/');
    this.idComplaint = tabs[tabs.length - 1];
  }

  // Subscribe to value changes to always keep the character count updated.
  updateCharCount() {
    this.noteForm.get('descripcion')?.valueChanges.subscribe((value: string) => {
      this.charCount = value ? value.length : 0;
    });
  }

  /**
   * Fetches notes for the current complaint from the server.
   */
  loadNotes() {
    if (!this.idComplaint) {
      const tabs = window.location.href.split('/');
      this.idComplaint = tabs[tabs.length - 1];
    }

    const id_denuncia = this.idComplaint.toString();
    const id_usuario = localStorage.getItem('id_user_session') || '';

    this.noteService.getNotes(id_denuncia, id_usuario).subscribe({
      next: (notes) => {
        this.notes = notes;
      },
      error: (err) => {
        this.toastr.error(this.translate.instant('texto_814'), this.translate.instant('texto_813'));
      }
    });
  }

  /**
   * Adds a new note or updates an existing one based on the form state.
   */
  addNote() {
    if (this.noteForm.invalid) {
      this.toastr.error(this.translate.instant('texto_815'), this.translate.instant('texto_813'));
      return;
    }

    const noteData: Nota = {
      id: this.editingIndex !== null ? this.notes[this.editingIndex].id : undefined, // Include ID if editing.
      id_denuncia: this.idComplaint,
      id_usuario: localStorage.getItem('id_user_session') || '',
      nota: this.noteForm.value.descripcion,
      visible: this.noteForm.value.visible ? 1 : 0
    };

    if (this.editingIndex !== null) {
      this.noteService.updateNote(noteData).subscribe({
        next: () => {
          this.toastr.success(this.translate.instant('texto_817'), this.translate.instant('texto_816'));
          this.notes[this.editingIndex!] = noteData;
          this.editingIndex = null;
          this.noteForm.reset({ visible: false });
          this.charCount = 0;
          this.loadNotes();
        },
        error: (err) => {
          this.toastr.error(this.translate.instant('texto_818'), this.translate.instant('texto_813'));
        }
      });
    } else {
      // Create a new note.
      this.noteService.addNote(noteData).subscribe({
        next: (response) => {
          this.loadNotes();
          this.toastr.success(this.translate.instant('texto_819'), this.translate.instant('texto_816'));
          this.notes.push(noteData);
          this.noteForm.reset({ visible: false });
          this.charCount = 0;
        },
        error: (err) => {
          this.toastr.error(this.translate.instant('texto_820'), this.translate.instant('texto_813'));
        }
      });
    }
  }

  /**
   * Prepares the form for editing a note by populating it with existing data.
   */
  editNote(index: number) {
    this.updateCharCount();
    const note = this.notes[index];
    this.noteForm.setValue({
      descripcion: note.nota,
      visible: String(note.visible) === '1'
    });
    this.editingIndex = index;
  }

  /**
  * Cancels the current edit operation and resets the form.
  */
  cancelEdit() {
    this.editingIndex = null;
    this.noteForm.reset({ visible: false });
    this.charCount = 0;
  }

  /**
   * Deletes a note after confirming with the user.
   * @param id_nota - ID of the note to delete.
   * @param index - Index of the note in the local array.
  **/
  deleteNote(id_nota: number, index: number) {
    this.showConfirmation().then((result) => {
      if (result.isConfirmed) {
        this.noteService.deleteNote(id_nota).subscribe({
          next: () => {
            this.toastr.success(this.translate.instant('texto_821'), this.translate.instant('texto_816'));
            this.notes.splice(index, 1);
          },
          error: (err) => {
            this.toastr.error(this.translate.instant('texto_822'), this.translate.instant('texto_813'));
          }
        });
      }
    });
  }

  /**
   * Displays a confirmation dialog before deleting a note.
   * @returns A promise resolved with the user's choice.
   */
  showConfirmation() {
    return Swal.fire({
      title: this.translate.instant('texto_810'),
      text: this.translate.instant('texto_811'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: this.translate.instant('texto_507').toUpperCase(),
      cancelButtonText: this.translate.instant('texto_823').toUpperCase(),
      reverseButtons: true,
    });
  }
}
