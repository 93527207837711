import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { Receptor } from '../model/receptor';
import { environment } from "../../environments/environment";
import { Rol } from '../model/rol';

@Component({
  selector: 'app-enviar-informe',
  templateUrl: './enviar-informe.component.html',
  styleUrls: ['./enviar-informe.component.css']
})
export class EnviarInformeComponent implements OnInit {
  myForm: FormGroup;
  @Input() denuncia: DatosDenuncia;
  textoInforme: string;
  receptor: Receptor[];
  roles: Rol[];
  isActiveIdPersonalizable: boolean = false; //Is Active Id Custom
  charCount: number = 0;
  maxCharCount: number = 4000;
  private lastValidHTML: string = '';

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private http: HttpClient) { }

  ngOnInit(): void {
    this.myForm = new FormGroup({
      textoInforme: new FormControl('', [Validators.maxLength(4000)]),
    });
    this.textoInforme = this.denuncia?.[0].informe_investigador;

    //check if idPersonalizado is active
    if (this.auth.getIdPersonalizable() == '1') {
      this.isActiveIdPersonalizable = true;
    }

    // Subscribe to value changes to always keep the character count updated.
    this.updateCharCount();
  }

  get texto() {
    return this.myForm.get('textoInforme');
  }

  // Subscribe to value changes to always keep the character count updated.
  updateCharCount() {
    this.myForm.get('textoInforme')?.valueChanges.subscribe((value: string) => {
      this.charCount = value ? value.length : 0;
    });
  }

  // Validates HTML input, updates character count, and reverts to last valid value if limit is exceeded.
  onEditorChange(value: string) {
    const plainText = value
      ? value.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
      : '';
      
    if (plainText.length <= this.maxCharCount) {
      this.lastValidHTML = value;
      this.charCount = plainText.length;
    } else {
      this.myForm
        .get('textoInforme')
        ?.setValue(this.lastValidHTML, { emitEvent: false });
  
      const lastPlainText = this.lastValidHTML
        ? this.lastValidHTML.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
        : '';
      this.charCount = lastPlainText.length;
    }
  }

  enviarInforme(){
    if(this.textoInforme != ""){
      if(this.charCount>4000){
        this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
      }else{
      // Send report wiht status INVESTIGADOR
      this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia,this.textoInforme,'2', this.auth.getIdUser()).subscribe(
        res => {
          if(res == 200){
            this.toastr.success('Informe enviado correctamente', 'Enviando informe ...');


            this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "3", this.auth.getSociedad(),"","").subscribe(
              res => {
                if(res == 200){
                  this.apiService.disableUserByIdComplaint(this.denuncia[0].id_denuncia).subscribe(
                    res => {
                      if(res == 200){
                        this.apiService.enableUserByIdComplaint(this.denuncia[0].id_denuncia).subscribe(
                          res => {
                            if(res == 200){
                              this.apiService.searchReceptorByIdComplaint(this.denuncia[0].id_denuncia,true).subscribe((receptor: Receptor[])=>{
                                this.receptor = receptor;

                                let $clau = false;
                                let clausulas_correo = localStorage.getItem("clausulas_correo");
                                if(clausulas_correo == '1'){
                                  $clau = true;
                                }

                                let url = environment.base + "/editar-denuncia/"+this.denuncia[0].id_denuncia;
                                let idDenunciaMostrado; // Variable to store the complaint ID to be displayed
                                // Check if isActiveIdPersonalizable is true
                                if (this.isActiveIdPersonalizable) {
                                  // If true, use identificador_denuncia if it exists, otherwise use id_denuncia
                                  idDenunciaMostrado = this.denuncia[0].identificador_denuncia
                                    ? this.denuncia[0].identificador_denuncia
                                    : this.denuncia[0].id_denuncia;
                                } else {
                                  // If isActiveIdPersonalizable is false, only use id_denuncia
                                  idDenunciaMostrado = this.denuncia[0].id_denuncia;
                                }
                                let message = this.translate.instant('texto_576') + " " +  this.receptor[0].id_user + this.translate.instant('texto_592') + " "  + idDenunciaMostrado + " "  + this.translate.instant('texto_617');
                                  this.apiService.sendEmail(this.receptor[0].mail_user,this.translate.instant('texto_594') + " " + idDenunciaMostrado, message,url,$clau, this.translate.instant('texto_618')).subscribe(
                                    res => {
                                      if(res == 200){
                                        //let role = this.auth.getRole();
                                        let role = "";
                                        this.apiService.searchRoleByUserSociety('setUser',this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
                                          this.roles = roles;
                                          for(let i=0;i<this.roles.length;i++){
                                            role = role + "," +this.roles[i].id_rol;
                                          }
                                        // Role include RECEPTOR
                                        if(role.includes("1") && this.receptor[0].id_usuario == this.auth.getIdUser()){
                                          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
                                          });
                                        }else{
                                          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                              this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
                                          });
                                        }
                                      });
                                      }else{
                                        this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                      }
                                  });
                              });


                            }else{
                              this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                            }
                          });
                        }else{
                          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                        }
                      });
                }else{
                  this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
                }
              });
          }else{
            this.toastr.error('Error al enviar informe', 'Enviando informe ...');
          }
        });


      }
    }else{
      this.toastr.error('Es necesario enviar el informe', 'Enviando informe ...');
    }

  }

  onKey(event){
    var datos = document.getElementById("textoInformeInv") ;
    for(var i=0; i< 1; i++){
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.charCount = div.textContent.length
    }
   }


}
