import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { Nota } from '../models/nota';

@Injectable({
  providedIn: 'root'
})
export class NotaService {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";

  constructor(
    private httpClient: HttpClient,
    private auth: AuthService
  ) { }

  /**
   * Helper method to get the Authorization headers.
   * @returns HttpHeaders with the authorization token.
   */
  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Authorization': `Bearer ${this.auth.getToken()}`
    });
  }

  /**
   * Add a new note to a complaint.
   * @param nota - The note object containing all necessary details.
   * @returns An Observable with the response message.
   */
  addNote(nota: Nota): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>(
      `${this.PHP_API_SERVER}/api/addComplaintNote.php`,
      nota,
      { headers: this.getHeaders() }
    );
  }

  /**
   * Delete a note by its ID.
   * @param id_nota - The ID of the note to delete.
   * @returns An Observable with the response message.
   */
  deleteNote(id_nota: number): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>(
      `${this.PHP_API_SERVER}/api/deleteNote.php`,
      { id: id_nota },
      { headers: this.getHeaders() }
    );
  }

  /**
   * Get all notes associated with a specific complaint.
   * @param id_denuncia - The complaint ID.
   * @param id_usuario - The user ID.
   * @returns An Observable with the list of notes.
   */
  getNotes(id_denuncia: string, id_usuario: string): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/api/getComplaintNotes.php`,
      { id_denuncia, id_usuario },
      { headers: this.getHeaders() }
    );
  }

  /**
   * Update the content of a specific note.
   * @param nota - The note object with updated content.
   * @returns An Observable with the response message.
   */
  updateNote(nota: Nota): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>(
      `${this.PHP_API_SERVER}/api/updateNote.php`,
      { id: nota.id, nota: nota.nota, visible: nota.visible },
      { headers: this.getHeaders() }
    );
  }
}
