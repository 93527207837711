import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
@Component({
  selector: 'app-add-witness-admin',
  templateUrl: './add-witness-admin.component.html',
  styleUrls: ['./add-witness-admin.component.css']
})
export class AddWitnessAdminComponent implements OnInit {

  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  @Input() denuncia: DatosDenuncia;
  isActiveIdPersonalizable: boolean = false; //Is Active Id Custom

  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }


  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.validatingForm = new FormGroup({
      signupFormModalTelefono: new FormControl('', []),
      signupFormModalEmail: new FormControl('', Validators.email),
      signupFormModalName: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', []),
    });

    //check if idPersonalizado is active
    if (this.auth.getIdPersonalizable() == '1') {
      this.isActiveIdPersonalizable = true;
    }
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalTelefono() {
    return this.validatingForm.get('signupFormModalTelefono');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  get signupFormModalEmail() {
    return this.validatingForm.get('signupFormModalEmail');
  }


  addWitness(){
    this.apiService.addWitness(this.denuncia[0].id_sociedad, this.auth.getIdUser(),this.denuncia[0].id_denuncia, this.signupFormModalName.value, this.signupFormModalTelefono.value, this.signupFormModalEmail.value, this.contactFormModalMessage.value, '0',true).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_304'), this.translate.instant('texto_306'));
          localStorage.setItem("verdenuncia", "");
          // Check if mail clauses are activated
          let $clau = false;
          let clausulas_correo = localStorage.getItem("clausulas_correo");
          if (clausulas_correo === '1') {
            $clau = true;
          }
          let idDenunciaMostrado; // Variable to store the complaint ID to be displayed
          // Check if isActiveIdPersonalizable is true
          if (this.isActiveIdPersonalizable) {
            // If true, use identificador_denuncia if it exists, otherwise use id_denuncia
            idDenunciaMostrado = this.denuncia[0].identificador_denuncia 
            ? this.denuncia[0].identificador_denuncia 
            : this.denuncia[0].id_denuncia;
          } else {
            // If isActiveIdPersonalizable is false, only use id_denuncia
            idDenunciaMostrado = this.denuncia[0].id_denuncia;
          }
          // Construct the notification message for the witness
          let message = `${this.translate.instant('texto_576')} ${this.signupFormModalName.value}, ${this.translate.instant('texto_805')}: ${idDenunciaMostrado}. ${this.translate.instant('texto_806')}. <br>${this.translate.instant('texto_95')}: ${this.contactFormModalMessage.value}`;
          // Send notification email
          /*this.apiService.sendEmail(
            this.signupFormModalEmail.value,
            this.translate.instant('texto_807'),
            message,
            '',
            $clau,
            this.translate.instant('texto_808')
          ).subscribe(res => {
            if (res === 200) {
              this.translate.instant('texto_195'), this.translate.instant('texto_194')
            } else {
              this.translate.instant('texto_809'), this.translate.instant('texto_193')
            }
          });*/
        }else{
          this.toastr.error(this.translate.instant('texto_305'), this.translate.instant('texto_813'));
        }

        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
        });
      },
      err =>{
        this.toastr.error(this.translate.instant('texto_305'), this.translate.instant('texto_813'));
      });
  }

  public options: Object = {
    charCounterCount: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize',  'html', 'backgroundColor', 'textColor','alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo' ],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize',  'html', 'backgroundColor', 'textColor','alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo' ],
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    imageMaxSize: 5 * 1024 * 1024,
  };
}
