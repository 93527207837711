<button style="background-image: url(/../../assets/img/botones/Boton_Asignar.png);" class="btn btn-rounded btn-cabecera"
  matTooltip="{{'texto_491' | translate }}" type="button" mdbBtn rounded="true" data-toggle="modal"
  data-target="#basicExample" (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Content-->
    <div class="modal-content">

      <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
        <div class="card-header card-header-info">
          <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_491' | translate }}</h5>
        </div>
        <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark"
          data-dismiss="modal" (click)="frame.hide()" aria-label="Cerrar modal">X</button>

        <div class="row">
          <div class="col-md-6" style="margin-top: 1.2rem">
            <mat-form-field>
              <mat-label>{{ 'texto_492' | translate}}</mat-label>
              <mat-select [formControl]="selectReceptor">
                <mat-option *ngFor="let item of receptores" [value]="item.id_user" style="color:red;">
                  {{item.n_user}} {{item.ape_user}}
                </mat-option>
                <mat-option>
                  <!-- _________________
                      <br><br>-->
                  <label>{{ 'texto_493' | translate }}</label>
                  <!--<br>-->
                </mat-option>
                <mat-option *ngFor="let item2 of receptores2" [value]="item2.id_user">
                  {{item2.n_user}} {{item2.ape_user}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col-md-6' style="margin-top: 0.8rem">
            <div class="form-group">
              <label class="control-label">{{ 'texto_494' | translate }}</label> <br>
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr *ngFor="let item2 of receptor_select">
                      <td>{{item2.n_user}} {{item2.ape_user}}</td>
                      <td class="td-actions text-right" style="width:90px;">
                        <button (click)="deletereceptor(item2.id_user)" type="button" rel="tooltip" title="Remove"
                          class="" style="background: none;color:red;border:none;">
                          <i class="material-icons">clear</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="md-form mb-10 col-md-12">
            <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon> <label class="control-label"
              for="textoInforme">{{ 'texto_63' | translate }}</label><br>
            <ngx-wig id="textoInformeReceptor" [(ngModel)]="textoInforme" [formControl]="myForm.get('textoInforme')"
              [buttons]="'bold, italic, link, underline'" (ngModelChange)="onEditorChange($event)"
              [attr.aria-invalid]="myForm.get('textoInforme').invalid ? 'true' : null"></ngx-wig>
            <span style="float: left;font-weight: bold;font-size: 0.75rem;">{{ charCount }} {{ 'texto_69' | translate }}
              {{ maxCharCount }}</span>
          </div>
        </div>
        <div class="text-center mt-3">
          <button (click)="asignarReceptor()" style="color:#fff" mdbBtn rounded="true"
            class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_384' | translate}}
          </button>
        </div>
      </div>
      <!--/.Content-->
    </div>
  </div>
