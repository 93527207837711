import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prueba-correo',
  templateUrl: './prueba-correo.component.html',
  styleUrls: ['./prueba-correo.component.css']
})
export class PruebaCorreoComponent implements OnInit {
  validatingFormMail: FormGroup;
  condition = true;

  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    this.condition = true;
    if (!language) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.validatingFormMail = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        this.emailDomainValidator // Validador personalizado
      ])
    });
  }

  emailDomainValidator(control: FormControl) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    if (!control.value || emailPattern.test(control.value)) {
      return null; // El correo es válido
    }
    return { invalidEmail: true }; // El correo es inválido
  }

  enviarEmailPrueba() {
    if (this.validatingFormMail.invalid) {
      this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
      return;
    }

    const email = this.validatingFormMail.get('email')?.value;
    const $clau = localStorage.getItem("clausulas_correo") == '1';
    const message = this.translate.instant('texto_854');

    this.apiService.sendEmail(email, this.translate.instant('texto_855'), message, '', $clau, this.translate.instant('texto_855')).subscribe(
      res => {
        if (res == 200) {
          this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
        } else {
          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/advanced-setting']);
        });
      });
  }
}
