<!-- Button to open the modal -->
<button
  style="background-image: url(/../../assets/img/botones/Boton_Validar\ Denuncia.png);"
  class="btn btn-rounded btn-cabecera"
  matTooltip="{{ 'texto_841' | translate }}"
  [attr.aria-label]="'texto_841' | translate"
  type="button"
  mdbBtn
  rounded="true"
  data-toggle="modal"
  data-target="#accept-appeals"
  (click)="frame.show()"
  mdbWavesEffect
>
</button>

<!-- Modal structure -->
<div
  mdbModal
  #frame="mdbModal"
  class="modal fade top"
  id="accept-appeals"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog cascading-modal modal-avatar modal-sm"
    role="document"
    style="max-width: 100% !important;"
  >
    <div class="modal-content">
      <!-- Modal content -->
      <div class="modal-body mx-3" style="padding: 1rem;">
        <!-- Modal header with title -->
        <div class="card-header card-header-info">
          <h5 id="modalLabel" class="card-title mb-0">
            {{ 'texto_841' | translate }}
          </h5>
        </div>
        <button
          type="button"
          style="z-index:100;float: right;margin-top: -2.8rem;"
          class="btn btn-dark"
          data-dismiss="modal"
          (click)="frame.hide()"
          [attr.aria-label]="'texto_735' | translate"
        >
          X
        </button>

        <!-- Reactive form -->
        <form [formGroup]="validatingForm" class="editForm" novalidate>
          <div class="row">
            <!-- Phase select dropdown -->
            <div class="col-xs-12 col-md-12 select">
              <div class="form-group">
                <label class="control-label">{{ 'texto_842' | translate }}</label>
                <mat-form-field>
                  <mat-label >{{ 'texto_132' | translate }}</mat-label>
                  <mat-select
                    formControlName="phase"
                    required
                    [attr.aria-label]="'texto_132' | translate"
                  >
                    <!-- Dropdown options -->
                    <mat-option *ngFor="let phase of phases" [value]="phase.id_fase">
                      {{ phase.nombre_fase }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- User select dropdown -->
            <div class="col-xs-12 col-md-12 select">
              <div class="form-group">
                <label class="control-label">{{ 'texto_843' | translate }}</label>
                <mat-form-field>
                  <mat-label>{{ 'texto_386' | translate }}</mat-label>
                  <mat-select
                    formControlName="user"
                    required
                    [attr.aria-label]="'texto_386' | translate"
                  >
                    <!-- Dropdown options -->
                    <mat-option *ngFor="let user of users" [value]="user.id_user">
                      {{ user.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- Additional information -->
            <div class="col-xs-12 col-sm-12">
              <p>
                <small style="font-size: 0.62rem;">* {{ 'texto_845' | translate }} {{ selectedPhase?.nombre_fase || '' }}</small>
                <br>
                <small style="font-size: 0.62rem;">* {{ 'texto_844' | translate }} {{ selectedUser?.id_user || '' }}</small>
              </p>
            </div>
          </div>

          <!-- Submit button -->
          <div class="text-center mt-3">
            <button
              [disabled]="state"
              (click)="confirmAccept()"
              mdbBtn
              rounded="true"
              class="btn btn-primary mt-1 waves-light"
              mdbWavesEffect
              [attr.aria-label]="'texto_41' | translate"
            >
              {{ 'texto_41' | translate }}
            </button>
          </div>
        </form>
      </div> <!-- End of modal-body -->
    </div>
  </div>
</div>
