import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReabrirDenunciaService {

  PHP_API_SERVER = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private auth: AuthService
  ) { }

  /**
   * Helper method to get the Authorization headers.
   * @returns HttpHeaders with the authorization token.
   */
  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Authorization': `Bearer ${this.auth.getToken()}`,
      'Content-Type': 'application/json'
    });
  }

  /**
   * Reabrir denuncia enviando id y motivo de apelación.
   * @param id_denuncia - ID de la denuncia a reabrir.
   * @param motivo_apelacion - Descripción del motivo de la reapertura.
   * @returns Observable con la respuesta del backend.
   */
  reopenComplaint(id_denuncia: number, motivo_apelacion: string): Observable<{ message: string; recipient?: string }> {
    return this.httpClient.post<{ message: string; recipient?: string }>(
      `${this.PHP_API_SERVER}/api/addAppeal.php`,
      { id_denuncia, motivo_apelacion },
      { headers: this.getHeaders() }
    );
  }  
}
