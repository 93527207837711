<section style="position: absolute;width: 100%;z-index: 1;" class="image-container line-spacing"
  style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
  <!-- Contenedor del Pop-Up -->
  <div class="popup-container" style="padding: 0;" *ngIf="showPopUp">
    <div class="board boardPopUp">
      <div class="info-box">
        <!-- Contenido del Pop-Up -->
        <div class="row justify-content-center">
          <div class=" text-center">
            <p [innerHTML]="'texto_734' | translate | safe:'html'"></p>
          </div>
        </div>
      </div>
      <!-- Botón "Aceptar" dentro del Pop-Up -->
      <div class="row justify-content-center">
        <div class="col-auto text-center">
          <button (click)="hidePopUp()"  mdbBtn rounded="true" class="btn-back" mdbWavesEffect>{{
            'texto_114' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container" style="padding: 0;" *ngIf="!showPopUp">
    <div class="board">
      <!-- Contenido después de cerrar el Pop-Up -->

      <!-- Botón cerrar -->
      <button type="button" style="z-index:100;float: right;" class="btn btn-dark" (click)="hide();"
        [attr.aria-label]="'texto_735' | translate">X</button>

      <!--Breadcrumbs-->
      <div class="breadcrum-div">
        <nav aria-label="breadcrumb" class="nav">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/inicio" [innerHTML]="'texto_1' | translate | safe:'html'"></a></li>
            <li class="breadcrumb-item active" aria-current="page" [innerHTML]="'texto_717' | translate | safe:'html'"></li>
          </ol>
        </nav>
      </div>
      <!--End Breadcrumbs-->

      <!-- Navigation Area (Circular Tabs) -->
      <msw-navbar></msw-navbar>
      <!-- End Navigation Area (Circular Tabs) -->

      <!-- Content Area -->
      <div class="tab-content">
        <form #tipoForm="ngForm" class="editForm" novalidate>
          <div class="tab-pane fade in active">
            <h2 class="head text-center"> {{ title | translate }}</h2>
            <div class='row'>
              <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                <div class="row">
                  <!--SELECTED EMPRESA WITH SEARCH INPUT-->
                  <div *ngIf="showInputAutocomplete" class='col-xs-12 col-sm-6'>
                    <div class="form-group">
                      <label class="control-label" for="tipoHecho">{{ 'texto_67'
                        | translate }}</label> <br>
                      <mat-form-field [color]="colorfieldAutocomplete">
                        <input matInput #miInput id="orangeForm-name" [formControl]="searchInput"
                          placeholder="Buscar..." [matAutocomplete]="auto" (input)="onInputChange(miInput.value)">

                        <mat-autocomplete #auto="matAutocomplete">
                          <ng-container *ngIf="suggestions && suggestions.length > 0; else noSuggestions">
                            <mat-option *ngFor="let suggestion of suggestions"
                              (click)="selectSuggestion(suggestion.n_empresa, suggestion.id_empresa, suggestion.id_sociedad)">{{
                              suggestion.n_empresa
                              }}
                            </mat-option>
                          </ng-container>
                          <!-- Mensaje de error cuando no hay sugerencias -->
                          <ng-template #noSuggestions>
                            <mat-option>
                              {{ 'texto_713' | translate }}
                            </mat-option>
                          </ng-template>
                        </mat-autocomplete>
                      </mat-form-field>
                      <!-- <div *ngIf="hideerrorentrycompanies" class="alert alert-danger" style="font-size:12px;">
                        No existe ninguna empresa con este nombre
                      </div> -->

                    </div>
                  </div>
                  <!--SELECTED EMPRESA-->
                  <div *ngIf="!showInputAutocomplete" class='col-xs-12 col-sm-6'>
                    <div class="form-group">
                      <label class="control-label" for="empresa" id="label-empresa" (click)="focusSelect(0)">{{
                        'texto_67'
                        | translate }}</label> <br>
                      <mat-form-field>
                        <mat-label>{{ 'texto_140' | translate }}</mat-label>
                        <mat-select #empresa id="empresa" name="empresa" (selectionChange)="onChange($event)"
                          [(ngModel)]="selectedCompany" (click)="onClick($event)" required
                          aria-labelledby="label-empresa">
                          <mat-option *ngFor="let emp of empresas" [value]="emp">
                            {{emp.n_empresa}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--SELECTED CENTER-->
                  <div class='col-xs-12 col-sm-6' *ngIf=showSelected;>
                    <div class="form-group">
                      <label class="control-label" for="centro" id="label-centro" (click)="focusSelect(1)">{{
                        'texto_672' | translate }}</label> <br>
                      <mat-form-field>
                        <mat-label>{{ 'texto_140' | translate }}</mat-label>
                        <mat-select #centroSelect id="centro" name="centro" (selectionChange)="onChangeCenter($event)"
                          [(ngModel)]="selectedCenter" required aria-labelledby="label-centro">
                          <mat-option *ngFor="let cent of centros" [value]="cent">
                            {{cent.n_centro}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--SELECTED TIPO DENUNCIA-->
                  <div class='col-xs-12 col-sm-6'>
                    <div class="form-group">
                      <label class="control-label" for="tipoDenuncia" id="label-tipodenuncia"
                        (click)="focusSelect(2)">{{ 'texto_71'
                        | translate }}</label> <br>
                      <mat-form-field>
                        <mat-label>{{ 'texto_140' | translate }}</mat-label>
                        <mat-select #tipoDenuncia id="tipoDenuncia" name="tipoDenuncia"
                          [(ngModel)]="tipoHecho.tipoDenuncia" required (click)="onClickComplaint($event)" required
                          aria-labelledby="label-tipodenuncia" (selectionChange)="onChangeComplaint($event)">
                          <mat-option matTooltip="{{denuncia.nombre}}" *ngFor="let denuncia of denuncias"
                            [value]="denuncia.id_tp_denuncia">
                            {{denuncia.nombre}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!--DESCRIPCIÓN TIPO DE DENUNCIA-->
                      <div style="font-size: 0.93rem; line-height: 1.5; text-align: justify;"
                        [innerHtml]="descripcion | safe: 'html'"></div>
                    </div>
                  </div>

                  <!--RECEPTOR-->
                  <div class="col-xs-12 col-sm-12">
                    <div class='col-xs-12 col-sm-6' style="padding-left: 0px; padding-right: 0px;"
                      *ngIf="ShowSetReceptor">
                      <div class="form-group">
                        <label class="control-label" for>{{ 'texto_148' |
                          translate }}</label> <br>
                        <p id="receptor" value name="receptor" style="font-size: 1.5rem">{{Receptoraleatorio}}</p>
                      </div>
                    </div>

                    <div *ngIf="HiddeIsNotConflict">
                      <div class='col-xs-12 col-sm-6' style="padding-left: 0px; padding-right: 0px;" *ngIf="Showselect">
                        <div class="form-group">
                          <label class="control-label" for="receptor" id="label-receptor" (click)="focusSelect(3)">{{
                            'texto_491' | translate }}</label> <br>
                          <mat-form-field>
                            <mat-label>{{ 'texto_35' | translate }}</mat-label>
                            <mat-select #receptorSelect id="receptor" name="receptor" [(ngModel)]="receptor.receptor"
                              (selectionChange)="onChangeReceptor($event)" (click)="onClickReceptor($event)" required
                              aria-labelledby="label-receptor">
                              <mat-option *ngFor="let recept of receptores" [value]="recept.id_user">
                                {{recept.n_user}} {{recept.ape_user}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class='col-xs-12 col-sm-6' *ngIf="ShowSetReceptor">
                        <div class="form-group">
                          <label class="control-label" for="otroReceptor">{{ 'texto_155' |
                            translate }}</label> <br>
                          <button  mdbBtn rounded="true" class="btn-receptor"
                            (click)="searchReceptors()" id="otroReceptor">{{ 'texto_150' |
                            translate }}
                          </button>
                        </div>
                      </div>
                      <div class='col-xs-12 col-sm-6' *ngIf="typeButton">
                        <div class="form-group">
                          <label class="control-label" for="automaticReceptor">{{ 'texto_155' |
                            translate }}</label> <br>
                          <button (click)="automaticReceptors()"  mdbBtn rounded="true"
                            class="btn-receptor" id="automaticReceptor" mdbWavesEffect>{{ 'texto_694' |
                            translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--END RECEPTOR-->
                </div>
                <!--TEXTAREA MOTIVO DE RECHAZO Y TEXTO 659-->
                <div class="row">
                  <div class="col-xs-12" *ngIf="typeButton">
                    <div class="form-group">
                      <label class="control-label" for="motivo">{{ 'texto_727' | translate }}</label> <br>
                      <textarea (keyup)="onKey($event)" maxlength="255" class="form-control input-md" #motivo
                        id="motivo" name="motivo" type="text" placeholder="{{ 'texto_727' | translate }}"
                        [(ngModel)]="receptor.motivo" rows="4" aria-describedby="motivo-counter"></textarea>
                      <span style="float: right;font-weight: bold;font-size:12px" id="motivo-counter">{{contador}} {{
                        'texto_69' | translate
                        }} 255</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="ShowSetReceptor || typeButton" class="comentario" style="text-align: justify;">
                  <div>
                    <span style="font-size: 0.75rem; display:block;line-height: 1.5;" [innerHTML]="'texto_659' | translate | safe:'html'"></span>
                  </div>
                </div>
                <div
                  style="font-size: 0.75rem;line-height: 1.5;border: 0px solid black;padding-top: 10px; padding-top: 10px; text-align: justify;">
                  <p [innerHTML]="'texto_756' | translate | safe:'html'"></p>
                </div>
                <!--END TEXTAREA MOTIVO DE RECHAZO Y TEXTO 659-->
                <div class="form-group text-center" style="margin-top: 30px;">
                  <button (click)="goToNext(tipoForm)"  mdbBtn rounded="true" class="btn-next"
                    mdbWavesEffect>{{ 'texto_141' | translate }} <span style="margin-left:10px;"
                      class="glyphicon glyphicon-arrow-right"> </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div #recapthca></div>
        </form>
      </div>
    </div>
  </div>
</section>