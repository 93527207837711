<!-- Button to open the modal for managing notes -->
<button style="background-image: url(/../../assets/img/botones/Boton_Notas.png); float: right;"
  id="btn-notas-modal" class="btn btn-finish btn-wd btn-cabecera" type="button"
  matTooltip="{{ 'texto_812' | translate }}" [attr.aria-label]="'texto_812' | translate" mdbBtn rounded="true"
  data-toggle="modal" data-target="#notasModal" (click)="frame.show()" mdbWavesEffect>
</button>

<!-- Modal for managing notes -->
<div mdbModal #frame="mdbModal" class="modal fade top" id="notasModal" tabindex="-1" role="dialog"
  aria-labelledby="notasModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <div class="modal-content">
      <div class="modal-body">

        <!-- Modal header -->
        <div class="card-header card-header-info">
          <h5 style="margin-bottom: 0px;" class="card-title" style="color:white">{{ 'texto_812' | translate }}</h5>
        </div>

        <!-- Button to close the modal -->
        <button id="btn-close-modal" type="button" style="z-index: 100; float: right; margin-top: -2.8rem;"
          class="btn btn-dark" data-dismiss="modal" (click)="frame.hide()" [attr.aria-label]="'texto_735' | translate">
          X
        </button>

        <!-- Form for adding or editing notes -->
        <form [formGroup]="noteForm">
          <div class="row">

            <!-- Input field for the note description -->
            <div class="md-form mb-0 col-md-12">
              <label for="form-description">{{ 'texto_457' | translate }}</label>
              <textarea type="text" maxlength="325" id="form-description" class="md-textarea form-control" rows="3"
                formControlName="descripcion" mdbInput
                [attr.aria-invalid]="noteForm.controls['descripcion'].invalid ? 'true' : null">
              </textarea>
              <!-- Character count display -->
              <span style="position: absolute; bottom: -10px; left: 15px; font-size: 0.75rem; color: #6c757d;" aria-live="polite">
                <span>{{ charCount }} {{ 'texto_458' | translate }} 325</span>
              </span>
            </div>

            <!-- Checkbox and action buttons -->
            <div class="md-form mb-2 col-md-12" style="position: relative;">
              <!-- Checkbox to mark the note as visible -->
              <div class="checkbox-container">
                <label for="visible">{{ 'texto_824' | translate }}</label>
                <input type="checkbox" id="visible" formControlName="visible" />
              </div>

              <!-- Save button -->
              <button id="btn-save-note" type="button" (click)="addNote()" mdbBtn rounded="true" class="btn-primary waves-light"
                mdbWavesEffect [disabled]="noteForm.invalid" style="position: absolute; right: 8px; top: -7px;"
                [attr.aria-label]="editingIndex !== null ? ('texto_59' | translate) : ('texto_459' | translate)">
                {{ editingIndex !== null ? ('texto_59' | translate) : ('texto_459' | translate) }}
              </button>

              <!-- Cancel button (only visible in edit mode) -->
              <button id="btn-cancel-edit" *ngIf="editingIndex !== null" type="button" (click)="cancelEdit()" mdbBtn
                rounded="true" class="btn-danger waves-light" mdbWavesEffect
                style="position: absolute; right: 125px; top: -7px;" [attr.aria-label]="'texto_823' | translate">
                {{ 'texto_823' | translate }}
              </button>
            </div>
          </div>
        </form>

        <!-- Notes list -->
        <div class="notes-container mb-2" *ngIf="notes && notes.length > 0">
          <!-- Loop through the notes array and display each note -->
          <div *ngFor="let note of notes; let i = index" class="note-item">
            <!-- Note header (includes the user name and creation date) -->
            <div class="note-header">
              <strong>{{ note.user_name }}</strong>
              <span>{{ note.fec_crea | date: 'dd/MM/yyyy HH:mm' }}</span>
            </div>
            <!-- Note body (contains the note text) -->
            <div class="note-body">
              <p>{{ note.nota }}</p>
            </div>
            <!-- Actions for editing or deleting a note -->
            <div class="note-actions">
              <!-- Visibility badge -->
              <span *ngIf="note.visible === '1'" class="badge bg-success">{{ 'texto_824' | translate }}</span>
              <span *ngIf="note.visible === '0'" class="badge bg-danger">{{ 'texto_825' | translate }}</span>
              <!-- Edit and delete buttons -->
              <div class="action-buttons">
                <!-- Edit button -->
                <button
                  id="btn-edit-note"
                  type="button"
                  (click)="editNote(i)"
                  mdbBtn
                  rounded="true"
                  class="btn-primary waves-light"
                  mdbWavesEffect
                  [disabled]="note.id_usuario !== currentUserId"
                  style="position: relative;"
                  [attr.aria-label]="'texto_826' | translate"
                >
                  {{ 'texto_826' | translate }}
                </button>
                <!-- Delete button -->
                <button
                  id="btn-delete-note"
                  type="button"
                  (click)="deleteNote(note.id, i)"
                  mdbBtn
                  rounded="true"
                  class="btn-danger waves-light"
                  mdbWavesEffect
                  [disabled]="note.id_usuario !== currentUserId"
                  style="position: relative;"
                  [attr.aria-label]="'texto_507' | translate"
                >
                  {{ 'texto_507' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
