import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/shared/models/datosDenuncia';
import { Cautelar } from '../dashboard/models/cautelar';

@Component({
  selector: 'app-asignar-medida-cautelar',
  templateUrl: './asignar-medida-cautelar.component.html',
  styleUrls: ['./asignar-medida-cautelar.component.css']
})
export class AsignarMedidaCautelarComponent implements OnInit {
  cautelares: Cautelar[];
  validatingForm: FormGroup;
  IsHiddenForm: boolean = false;
  @Input() denuncia: DatosDenuncia;
  contador = 0;
  isActiveIdPersonalizable: boolean = false; //Is Active Id Custom
  charCount: number = 0;
  maxCharCount: number = 4000;
  private lastValidHTML: string = '';

  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      medidaCautelar: new FormControl('', [Validators.required]),
      responsable: new FormControl('', Validators.required),
      textoMCU: new FormControl('', [Validators.required, Validators.maxLength(4000)]),
    });

    this.apiService.searchCautionBySociety(this.auth.getSociedad(), language).subscribe((cautelar: Cautelar[]) => {
      this.cautelares = cautelar;
    });

    //check if idPersonalizado is active
    if (this.auth.getIdPersonalizable() == '1') {
      this.isActiveIdPersonalizable = true;
    }

      
    // Subscribe to value changes to always keep the character count updated.
    this.updateCharCount();
  }
  // Subscribe to value changes to always keep the character count updated.
  updateCharCount() {
    this.validatingForm.get('textoMCU')?.valueChanges.subscribe((value: string) => {
      this.charCount = value ? value.length : 0;
    });
  }

   // Validates HTML input, updates character count, and reverts to last valid value if limit is exceeded.
   onEditorChange(value: string) {
    const plainText = value
      ? value.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
      : '';
      
    if (plainText.length <= this.maxCharCount) {
      this.lastValidHTML = value;
      this.charCount = plainText.length;
    } else {
      this.validatingForm
        .get('textoMCU')
        ?.setValue(this.lastValidHTML, { emitEvent: false });
  
      const lastPlainText = this.lastValidHTML
        ? this.lastValidHTML.replace(/<[^>]*>/g, '').replace(/&nbsp;/gi, ' ').trim()
        : '';
      this.charCount = lastPlainText.length;
    }
  }

  get medidaCautelar() {
    return this.validatingForm.get('medidaCautelar');
  }

  get responsable() {
    return this.validatingForm.get('responsable');
  }

  get textoMCU() {
    return this.validatingForm.get('textoMCU');
  }

  /**
 * Function to add a new "medida cautelar" to a complaint.
 * 1. Finds the name of the "medida cautelar" based on the selected value.
 * 2. Checks if the "contador" exceeds 4000 or if the "responsable" field is empty ornull.
 * 3. If the checks pass, the function calls the API to create a new "medida cautelar" for the complaint.
 * 4. After processing, navigates to refresh the component and then to the "editar-denuncia/id_denuncia" page.
 */
  asignarMCU() {
    let nombreMCU = "";

    // Loop through the "cautelares" array to find the matching "id_mcu" and get its name.
    for (var i = 0; i < this.cautelares.length; i++) {
      if (this.cautelares[i].id_mcu === this.medidaCautelar.value) {
        nombreMCU = this.cautelares[i].nombre;
        break;
      }
    }

    // Check if the "contador" is greater than 4000 or if the "responsable" value is empty or null.
    if (this.contador > 4000 || this.responsable.value === "" || this.responsable.value === null) {
      if (this.contador > 4000) {
        this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
      } else {
        this.toastr.error(this.translate.instant('texto_480'), this.translate.instant('texto_243'));
      }
    } else {
      this.apiService.createMCUByIdComplaint(
        this.auth.getSociedad(),
        this.medidaCautelar.value,
        this.denuncia[0].id_denuncia,
        nombreMCU,
        this.textoMCU.value,
        this.responsable.value,
        "0"
      ).subscribe(res => {
        //console.log(res)
        switch (res) {
          // If the response is successful.
          case 200:
            let $clau = false;
            let clausulas_correo = localStorage.getItem("clausulas_correo");
            if (clausulas_correo === '1') {
              $clau = true;
            }
            this.toastr.success(this.translate.instant('texto_293'));
            let idDenunciaMostrado; // Variable to store the complaint ID to be displayed
            // Check if isActiveIdPersonalizable is true
            if (this.isActiveIdPersonalizable) {
              // If true, use identificador_denuncia if it exists, otherwise use id_denuncia
              idDenunciaMostrado = this.denuncia[0].identificador_denuncia 
                ? this.denuncia[0].identificador_denuncia 
                : this.denuncia[0].id_denuncia;
            } else {
              // If isActiveIdPersonalizable is false, only use id_denuncia
              idDenunciaMostrado = this.denuncia[0].id_denuncia;
            }
            let message = this.translate.instant('texto_599') + " " + idDenunciaMostrado + " " +
              this.translate.instant('texto_600') + " " + nombreMCU + "<br>Comentarios: " + this.textoMCU.value;
            this.apiService.sendEmail(
              this.responsable.value,
              this.translate.instant('texto_601') + idDenunciaMostrado,
              message,
              '',
              $clau,
              this.translate.instant('texto_602')
            ).subscribe(res => {
              if (res === 200) {
                this.toastr.success(this.translate.instant('texto_195'), this.translate.instant('texto_194'));
              } else {
                this.toastr.error(this.translate.instant('texto_809'), this.translate.instant('texto_193'));
              }
            });
            break;

          // If more than 5 "medidas cautelares" exist in the complaint.
          case 204:
            this.toastr.error(this.translate.instant('texto_603'), this.translate.instant('texto_243'));
            break;

          // If parameters are wrong.
          default:
            this.toastr.error(this.translate.instant('texto_295'), this.translate.instant('texto_243'));
            break;
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
        });
      });
    }
  }

  onKey(event) {
    var datos = document.getElementById("textoMCU");
    for (var i = 0; i < 1; i++) {
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
  }
}
